import React from 'react';
import { useSelector } from 'react-redux';

import { faEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons';

import SCProfileEmail from './ProfileEmail.style';
import { useTranslation } from '../../../i18n';
import { TrackButton } from '../../../track';
import { firestore } from '../../../firebase';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import ProfileEmailItem from '../../molecules/ProfileEmailItem/ProfileEmailItem';
import SectionLayout from '../../layouts/SectionLayout/SectionLayout';
import SectionHeader from '../../molecules/SectionHeader/SectionHeader';

const ProfileEmail = () => {
  const user = useSelector((state) => state.user);
  const client = useSelector((state) => state.client);
  const teamMember = useSelector((state) => state.teamMembers)?.find(
    (item) => item.email === user.email
  );

  const i18n = useTranslation();

  const handleSwitcher = (type) => {
    const data = teamMember.emailPreferences || {};

    data[type] = !teamMember?.emailPreferences?.[type];

    firestore
      .collection('clients')
      .doc(client.id)
      .collection('team_members')
      .doc(user.hashedEmail)
      .update({ emailPreferences: data });
  };

  return (
    <SectionLayout>
      <SCProfileEmail>
        <div className="section-organism">
          <SectionHeader />
        </div>

        <div className="section-organism">
          {/* Comunicaciones */}
          <SectionCard
            headerTitle={i18n.t('profile.emailPreferences')}
            headerIcon={faEnvelopeOpen}>
            <ProfileEmailItem
              checked={teamMember?.emailPreferences?.emailWebsiteSecurity}
              tittle={i18n.t('notifications.settings.websiteScores')}
              text={i18n.t('notifications.settings.alertWebsite')}
              textToggle={
                teamMember?.emailPreferences?.emailWebsiteSecurity
                  ? i18n.t('notifications.settings.on')
                  : i18n.t('notifications.settings.off')
              }
              onClick={() => {
                handleSwitcher('emailWebsiteSecurity');
                TrackButton('notifications.settings.alertWebsiteSecurity');
              }}
            />
            <ProfileEmailItem
              checked={teamMember?.emailPreferences?.emailImpersonations}
              tittle={i18n.t('notifications.settings.domainImpersonation')}
              text={i18n.t('notifications.settings.alertImpersonations')}
              textToggle={
                teamMember?.emailPreferences?.emailImpersonations
                  ? i18n.t('notifications.settings.on')
                  : i18n.t('notifications.settings.off')
              }
              onClick={() => {
                handleSwitcher('emailImpersonations');
                TrackButton('notifications.settings.alertImpersonations');
              }}
            />
            <ProfileEmailItem
              checked={teamMember?.emailPreferences?.emailDeviceSecurity}
              tittle={i18n.t('notifications.settings.deviceSecurity')}
              text={i18n.t('notifications.settings.alertDeviceSecurity')}
              textToggle={
                teamMember?.emailPreferences?.emailDeviceSecurity
                  ? i18n.t('notifications.settings.on')
                  : i18n.t('notifications.settings.off')
              }
              onClick={() => {
                handleSwitcher('emailDeviceSecurity');
                TrackButton('notifications.settings.alertDeviceSecurity');
              }}
            />
            <ProfileEmailItem
              checked={teamMember?.emailPreferences?.emailBreaches}
              tittle={i18n.t('notifications.settings.dataBreaches')}
              text={i18n.t('notifications.settings.alertBreaches')}
              textToggle={
                teamMember?.emailPreferences?.emailBreaches
                  ? i18n.t('notifications.settings.on')
                  : i18n.t('notifications.settings.off')
              }
              onClick={() => {
                handleSwitcher('emailBreaches');
                TrackButton('notifications.settings.alertBreaches');
              }}
            />
            <ProfileEmailItem
              checked={teamMember?.emailPreferences?.emailEmailSecurity}
              tittle={i18n.t('notifications.settings.emailSecurity')}
              text={i18n.t('notifications.settings.alertEmailSecurity')}
              textToggle={
                teamMember?.emailPreferences?.emailEmailSecurity
                  ? i18n.t('notifications.settings.on')
                  : i18n.t('notifications.settings.off')
              }
              onClick={() => {
                handleSwitcher('emailEmailSecurity');
                TrackButton('notifications.settings.alertEmailSecurity');
              }}
            />
            <ProfileEmailItem
              checked={teamMember?.emailPreferences?.emailCampaigns}
              tittle={i18n.t('notifications.settings.phishingTraining')}
              text={i18n.t('notifications.settings.alertCampaign')}
              textToggle={
                teamMember?.emailPreferences?.emailCampaigns
                  ? i18n.t('notifications.settings.on')
                  : i18n.t('notifications.settings.off')
              }
              onClick={() => {
                handleSwitcher('emailCampaigns');
                TrackButton('notifications.settings.alertCampaign');
              }}
            />
            <ProfileEmailItem
              checked={teamMember?.emailPreferences?.emailProviderSecurity}
              tittle={i18n.t('notifications.settings.providerSecurity')}
              text={i18n.t('notifications.settings.alertProviderSecurity')}
              textToggle={
                teamMember?.emailPreferences?.emailProviderSecurity
                  ? i18n.t('notifications.settings.on')
                  : i18n.t('notifications.settings.off')
              }
              onClick={() => {
                handleSwitcher('emailProviderSecurity');
                TrackButton('notifications.settings.alertProviderSecurity');
              }}
            />
            <ProfileEmailItem
              checked={teamMember?.emailPreferences?.emailChecklist}
              tittle={i18n.t('notifications.settings.checklist')}
              text={i18n.t('notifications.settings.alertChecklist')}
              textToggle={
                teamMember?.emailPreferences?.emailChecklist
                  ? i18n.t('notifications.settings.on')
                  : i18n.t('notifications.settings.off')
              }
              onClick={() => {
                handleSwitcher('emailChecklist');
                TrackButton('notifications.settings.emailChecklist');
              }}
            />
          </SectionCard>
        </div>
      </SCProfileEmail>
    </SectionLayout>
  );
};

export default ProfileEmail;
