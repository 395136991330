import React from 'react';
import { Select } from 'antd';
import { useTranslation } from '../../../i18n';
import useGetAllPartnerCollaborators from '../../../hooks/useGetAllPartnerCollaborators/useGetAllPartnerCollaborators';

const SelectPartnerCollaborator = ({ value, onChange }) => {
  const i18n = useTranslation();

  const { allPartnerCollaborators, loading: loadingAllPartnerCollaborators } =
    useGetAllPartnerCollaborators();
  return (
    <Select
      value={!loadingAllPartnerCollaborators ? value : undefined}
      size="large"
      allowClear
      style={{ minWidth: '250px' }}
      placeholder={i18n.t('partner.collaborators.filterByCollaborator')}
      onChange={onChange}
      optionFilterProp="label"
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '')
          .toLowerCase()
          .localeCompare((optionB?.label ?? '').toLowerCase())
      }
      options={allPartnerCollaborators.map(({ name, id }) => ({
        value: id,
        label: name,
      }))}
      showSearch
      disabled={loadingAllPartnerCollaborators}
    />
  );
};

export default SelectPartnerCollaborator;
