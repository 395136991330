import styled from '@emotion/styled';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Button from '../../atoms/Button/Button';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const SCCheckPhisingReceptionEmail = styled.div`
  h1 {
    text-align: center;
    font-family: var(--font2);
    font-size: 25px;
    font-weight: 600;
  }

  p {
    font-family: var(--font2);
    font-size: 14px;
  }

  p:first-of-type {
    font-family: var(--font2);
    font-weight: 500;

    margin-bottom: 20px;
  }
`;

const CheckPhisingReceptionEmail = () => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <ModalLayout>
      <SCCheckPhisingReceptionEmail>
        <h1>
          {i18n.t(
            'phishingSimulator.campaigns.create.checkPhisingReceptionEmail.header'
          )}
        </h1>
        <div className="modal-card-content">
          <p>
            {i18n.t(
              'phishingSimulator.campaigns.create.checkPhisingReceptionEmail.first_text'
            )}
          </p>
          <p>
            {i18n.t(
              'phishingSimulator.campaigns.create.checkPhisingReceptionEmail.second_text'
            )}
          </p>
        </div>

        <div className="modal-card-buttons">
          <Button
            text={i18n.t(
              'phishingSimulator.campaigns.create.checkPhisingReceptionEmail.actions.IHaveReceivedIt'
            )}
            onClick={() => {
              dispatch(showPopUp(null));
            }}
            size="full"
            color="bluishGrey"
          />
          <Button
            text={i18n.t(
              'phishingSimulator.campaigns.create.checkPhisingReceptionEmail.actions.GoToWhitelisting'
            )}
            onClick={() => {
              navigate('/client/new-phising-simulator/whitelisting');
              dispatch(showPopUp(null));
            }}
            size="full"
            color="white"
          />
          <Button
            text={i18n.t(
              'phishingSimulator.campaigns.create.checkPhisingReceptionEmail.actions.GoBack'
            )}
            onClick={() => dispatch(showPopUp(null))}
            size="full"
            color="white"
          />
        </div>
      </SCCheckPhisingReceptionEmail>
    </ModalLayout>
  );
};

export default CheckPhisingReceptionEmail;
