import { useSelector } from 'react-redux';

const usePartnerConfig = () => {
  const partnerConfig = useSelector((redux) => redux.partnerConfig);

  const isPartnerType = (type) => {
    if (Array.isArray(type)) {
      return type.includes(partnerConfig?.type);
    }

    return partnerConfig?.type === type;
  };

  return {
    isPartnerType,
    isParentPartner: Boolean(partnerConfig?.isParent),
  };
};

export default usePartnerConfig;
