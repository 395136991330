import styled from '@emotion/styled';

const SCSignIn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 40px;

  background-color: var(--white);
  border-radius: var(--section-radius);
  padding: 40px;
  height: 100%;
  width: 100%;
  max-width: 420px;
  position: relative;

  box-shadow: 2px 2px 20px #79889714;

  .sessionTimeoutBanner {
    text-align: left;
  }

  .links-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    gap: 20px;

    a {
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .SignIn_Buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .SignIn_GoogleButton {
    width: 100%;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-color: var(--white);
    }
  }
  .SignIn_SantanderButton {
    width: 100%;

    svg {
      height: 17.5px;
      width: 100px;
      margin-bottom: 4px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-color: var(--white);
      color: var(--red);
    }
  }

  .seu-text {
    display: flex;
    gap: 6px;
    p {
      color: var(--bluish-grey);
      font-weight: 500;
      margin-right: 5px;
      text-align: left;

      > b {
        font-weight: 600;
      }
    }
  }
`;

export default SCSignIn;
