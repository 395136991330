import React, { useState, useEffect } from 'react';
import { faPhoneVolume } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Input } from 'antd';
import SCQualificationFormPage from './QualificationFormPage.style';

import QualificationFormQuestion from '../../components/molecules/QualificationFormQuestion/QualificationFormQuestion';
import ProgressBar from '../../components/atoms/ProgressBar/ProgressBar';
import Button from '../../components/atoms/Button/Button';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import useSantanderInitForm from '../../hooks/santanderInitForm/useSantanderInitForm';
import { ReactComponent as SantanderLogo } from '../../img/cyberguardian/SantanderFullLogo.svg';
import StripeSaveCard from '../../components/molecules/StripeSaveCard/StripeSaveCard';
import { PAYMENT_METHODS } from '../../utils/constants/payments';
import useQualificationForm from '../../hooks/qualificationForm/useQualificationForm';
import useSignOut from '../../hooks/useSignOut/useSignOut';
import { EUROPEAN_COUNTRIES } from '../../utils/internationalizationModules/countries/europe';

const ALTERNATIVE_STEPS = {
  exit: 'exit',
  tester: 'tester',
};

const emailRegExp = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const QualificationFormPage = () => {
  const client = useSelector((redux) => redux.client);

  const { qualificationForm } = useQualificationForm();
  const { saveAnswer, sendRemind, saveOtherTesterData } =
    useSantanderInitForm();

  const i18n = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signOutFromApp } = useSignOut();

  const { phonePrefix, phoneLength, phoneExample, startWith } =
    EUROPEAN_COUNTRIES.ES;

  const [currentStep, setCurrentStep] = useState(undefined);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alternativeStep, setAlternativeStep] = useState('');

  const [form] = Form.useForm();
  const name = Form.useWatch('name', form);
  const lastName = Form.useWatch('lastName', form);
  const email = Form.useWatch('email', form);
  const phoneNumber = Form.useWatch('phoneNumber', form);

  const numberOfQuestions = qualificationForm?.questions?.length;

  const isFinalForm = client?.status.paymentMethod === PAYMENT_METHODS.ACTIVE;
  const isResume = currentStep === numberOfQuestions && !isFinalForm;
  const isCardForm = currentStep === numberOfQuestions + 1 && !isFinalForm;

  const hasFinishedQuestions = currentStep >= numberOfQuestions;

  // fill current step answers
  useEffect(() => {
    if (currentStep !== undefined && !hasFinishedQuestions) {
      const currentQuestionAnswers = getQuestionAnswerIfExists();
      setResponses(currentQuestionAnswers);
    }
  }, [currentStep]);

  // define current step based on already answered questions
  useEffect(() => {
    if (qualificationForm && currentStep === undefined) {
      if (qualificationForm.answers.length !== 0) {
        setCurrentStep(qualificationForm.answers.length);
      }
    }
  }, [qualificationForm]);

  const getQuestionByOrder = (order) => {
    return qualificationForm.questions.find((question) => {
      return question.order === order + 1;
    });
  };

  const getQuestionAnswerIfExists = () => {
    const questionId = getQuestionByOrder(currentStep).id;

    const answerFound = qualificationForm.answers.find((answer) => {
      return answer.questionId === questionId;
    });

    return answerFound ? answerFound.optionsId : [];
  };

  const showErrorPopUp = () => {
    dispatch(
      showPopUp('notification', {
        notificationType: 'error',
        title: i18n.t('common.error'),
        text: i18n.t('common.errorTryLater'),
      })
    );
  };

  const handleQAnswer = ({ question, answer }) => {
    const qStep = currentStep;

    const prevAnsweredQuestion = qualificationForm.answers.find((ans) => {
      return ans.questionId === question;
    });

    if (prevAnsweredQuestion?.optionsId !== answer) {
      setLoading(true);
      saveAnswer(question, answer)
        .then(() => {})
        .catch(() => {
          showErrorPopUp();
          setCurrentStep(qStep);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (currentStep < numberOfQuestions) {
      setCurrentStep(currentStep + 1);
    }
  };

  const isNextButtonDisabled = () => {
    if (!hasFinishedQuestions) {
      if (getQuestionByOrder(currentStep).type === 'matrix') {
        return !(responses.length === 3);
      }
      return responses.length === 0;
    }
    return false;
  };

  const handleSendRemind = () => {
    sendRemind()
      .then(() => {
        dispatch(
          showPopUp('notification', {
            notificationType: 'success',
            text: 'De acuerdo, te enviaremos un recordatorio por email para que continúes más tarde.',
          })
        );
      })
      .catch(showErrorPopUp);
  };

  const handleTesterInfoSubmit = () => {
    saveOtherTesterData(name, lastName, phoneNumber, email)
      .then(() => {
        dispatch(
          showPopUp(
            'notification',
            {
              notificationType: 'success',
              title: i18n.t('common.success'),
              text: 'De acuerdo. Tan pronto como tu prueba gratuita esté activa, este usuario será incorporado como administrador de tu cuenta de Cyber Guardian y le enviaremos un email con las instrucciones de acceso',
              onButtonClick: () => {
                form.resetFields();
                setAlternativeStep('');
                setCurrentStep(currentStep + 1);
              },
              closeButtonText: 'Probar Cyber Guardian',
              closeButtonColor: 'red',
            },
            true
          )
        );
      })
      .catch(showErrorPopUp);
  };

  const isButtonDisabled = () => {
    const inputsAreEmpty = !name || !lastName || !email || !phoneNumber;
    const hasError =
      !emailRegExp.test(email) ||
      !phoneLength.includes(phoneNumber?.length) ||
      !startWith.test(phoneNumber);

    return inputsAreEmpty || hasError || loading;
  };

  return (
    <SCQualificationFormPage className="wings-section-background">
      <SantanderLogo className="santander-logo" />
      <div className="Poc_MainContainer">
        {!alternativeStep && (
          <>
            <div className="Poc_Header">
              {currentStep !== undefined && (
                <ProgressBar
                  completed={isFinalForm ? numberOfQuestions + 2 : currentStep}
                  total={numberOfQuestions + 2}
                  hideXAxis
                  backgroundColor="#F8F9FA"
                  barColor="var(--red-gradient)"
                />
              )}
            </div>
            <div className="Poc_Content">
              {qualificationForm && (
                <>
                  {currentStep !== undefined && !hasFinishedQuestions && (
                    <QualificationFormQuestion
                      question={getQuestionByOrder(currentStep)}
                      currentQAnswers={responses}
                      setCurrentQAnnswers={setResponses}
                    />
                  )}

                  {/* ToDo - Se podría componentizar para reutilizarlo en la finalPage */}
                  {currentStep === undefined && (
                    <div className="Poc_InitialPage">
                      {qualificationForm?.initialPage?.title && (
                        <h1>{qualificationForm.initialPage.title}</h1>
                      )}

                      {qualificationForm?.initialPage?.subtitle && (
                        <h2>{qualificationForm.initialPage.subtitle}</h2>
                      )}

                      <div className="Poc_InitialContent_Paragraphs">
                        {qualificationForm?.initialPage?.paragraphs.map(
                          (paragraph) => (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: paragraph,
                              }}
                            />
                          )
                        )}
                      </div>
                    </div>
                  )}

                  {isResume && (
                    <div className="Poc_InitialPage">
                      {qualificationForm?.finalPage?.title && (
                        <h1>{qualificationForm.finalPage.title}</h1>
                      )}

                      {qualificationForm?.finalPage?.subtitle && (
                        <h2>{qualificationForm.finalPage.subtitle}</h2>
                      )}

                      <div className="Poc_InitialContent_Paragraphs">
                        {qualificationForm?.finalPage?.paragraphs.map(
                          (paragraph) => (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: paragraph,
                              }}
                            />
                          )
                        )}
                      </div>
                    </div>
                  )}
                  {isCardForm && (
                    <div className="Poc_StripeSaveCard">
                      <h2>
                        Para probar Cyber Guardian en 1 dispositivo por favor
                        introduce tus datos de pago.
                      </h2>
                      <p>
                        Al finalizar el periodo de prueba de 7 días, se activará
                        tu suscripción anual de Cyber Guardian con 1 licencia y
                        se cargará automáticamente en tu tarjeta de crédito el
                        precio de 1 licencia, 125€/año (IVA no incluido).
                      </p>
                      <p>
                        Podrás solicitar la cancelación o ampliación de
                        licencias a través de nuestro{' '}
                        <a onClick={() => Tawk_API.toggle()}>chat online</a>.
                      </p>
                      <StripeSaveCard
                        onConfirm={() => {
                          setCurrentStep((prevStep) => prevStep + 1);
                        }}
                      />
                    </div>
                  )}
                  {isFinalForm && (
                    <div className="Poc_InitialPage">
                      <h2>¡Listo! ¡Tu prueba gratuita ya está activa!</h2>

                      <div className="Poc_InitialContent_Paragraphs">
                        <p>
                          <strong>
                            Recibirás un email confirmando la activación de tu
                            prueba gratuita de 7 días con 1 licencia para 1
                            dispositivo.
                          </strong>
                        </p>
                      </div>

                      <div>
                        <Button
                          color="red"
                          size="landing"
                          text="Empezar a usar Cyber Guardian"
                          onClick={() => {
                            // ToDo - Navigate /client
                            navigate('/');
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="Poc_Footer">
              <div className="Poc_Footer_buttons">
                {currentStep !== undefined && !isFinalForm && (
                  <>
                    <Button
                      color="bluishGrey"
                      size="landing"
                      text={i18n.t('tutorials.previous')}
                      disabled={loading}
                      onClick={() => {
                        if (currentStep === 0) {
                          setCurrentStep(undefined);
                        } else {
                          setCurrentStep(currentStep - 1);
                        }
                        setResponses([]);
                      }}
                    />
                    {!hasFinishedQuestions && (
                      <Button
                        color="red"
                        size="landing"
                        text={i18n.t('common.next')}
                        disabled={isNextButtonDisabled()}
                        onClick={() => {
                          handleQAnswer({
                            question: getQuestionByOrder(currentStep).id,
                            answer: responses,
                          });
                          setResponses([]);
                        }}
                      />
                    )}
                    {isResume && (
                      <Button
                        color="red"
                        size="landing"
                        text={
                          qualificationForm.finalPage.buttonText ||
                          i18n.t('tutorials.next')
                        }
                        disabled={isNextButtonDisabled()}
                        onClick={() => {
                          // to-do
                          setCurrentStep(currentStep + 1);
                        }}
                      />
                    )}
                  </>
                )}
                {(isCardForm || isFinalForm) && (
                  <Button
                    className="callMeButton"
                    type="button"
                    color="white"
                    size="landing"
                    icon={faPhoneVolume}
                    text={i18n.t('callMe.weCallYou')}
                    onClick={() => dispatch(showPopUp('callUs'))}
                  />
                )}
                {currentStep === undefined && (
                  <Button
                    color="red"
                    size="landing"
                    text={i18n.t('common.start')}
                    onClick={() => {
                      setCurrentStep(0);
                    }}
                  />
                )}
                {!isFinalForm && (
                  <Button
                    color="bluishGrey"
                    size="landing"
                    text={i18n.t('common.exit')}
                    onClick={() => {
                      setAlternativeStep(ALTERNATIVE_STEPS.exit);
                    }}
                  />
                )}
              </div>
              {isResume && (
                <button
                  className="call-us-button"
                  type="button"
                  onClick={() => {
                    setAlternativeStep(ALTERNATIVE_STEPS.tester);
                  }}>
                  ¿No eres tú quien lo va a probar?
                </button>
              )}
            </div>
          </>
        )}
        {alternativeStep === ALTERNATIVE_STEPS.exit && (
          <>
            <div className="Poc_Content">
              <div className="Poc_InitialPage">
                <h2>¡Sentimos que tengas que irte ahora!</h2>

                <div className="Poc_InitialContent_Paragraphs">
                  <p>
                    <strong>
                      No hay problema. Más tarde podrás volver al punto en que
                      lo dejaste.
                    </strong>
                  </p>
                  <p>
                    <strong>
                      ¿Quieres que te enviemos un recordatorio por email para
                      volver más tarde?
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="Poc_Footer">
              <div className="Poc_Footer_buttons">
                <Button
                  color="bluishGrey"
                  size="landing"
                  text={i18n.t('common.back')}
                  onClick={() => {
                    setAlternativeStep('');
                  }}
                />
                <Button
                  color="red"
                  size="landing"
                  text="Recibir recordatorio"
                  onClick={handleSendRemind}
                />
                <Button
                  color="bluishGrey"
                  size="landing"
                  text={i18n.t('common.exit')}
                  onClick={signOutFromApp}
                />
              </div>
              <button
                className="call-us-button"
                type="button"
                onClick={() => dispatch(showPopUp('callUs'))}>
                Te llamamos
              </button>
            </div>
          </>
        )}
        {alternativeStep === ALTERNATIVE_STEPS.tester && (
          <>
            <div className="Poc_Content">
              <div className="Poc_InitialPage">
                <h2>Por favor indica quién probará Cyber Guardian</h2>

                <div className="Poc_InitialContent_Paragraphs">
                  <p>
                    <strong>
                      La persona que indiques aquí obtendrá acceso de
                      administrador a la cuenta de Cyber Guardian de tu empresa.
                    </strong>
                  </p>
                  <p>
                    <strong>
                      Los nombres y las direcciones de correo electrónico son
                      datos personales. Por favor, añade esta información sólo
                      si tienes derecho a hacerlo.
                    </strong>
                  </p>
                </div>

                <Form
                  id="tester-form"
                  form={form}
                  onFinish={handleTesterInfoSubmit}
                  layout="vertical"
                  onFinishFailed={() => {
                    console.log('Failed form');
                  }}>
                  <div className="name-surname-container">
                    <Form.Item
                      label={i18n.t('profile.name')}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: i18n.t('welcome.formErrors.common.required'),
                        },
                      ]}>
                      <Input
                        size="large"
                        type="text"
                        placeholder={i18n.t('profile.name')}
                      />
                    </Form.Item>

                    <Form.Item
                      label={i18n.t('profile.lastName')}
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: i18n.t('welcome.formErrors.common.required'),
                        },
                      ]}>
                      <Input
                        size="large"
                        type="text"
                        placeholder={i18n.t('profile.lastName')}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    label="Correo electrónico"
                    name="email"
                    normalize={(value) => value.trim().toLowerCase()}
                    rules={[
                      {
                        required: true,
                        message: i18n.t('welcome.formErrors.common.required'),
                      },
                      {
                        message: i18n.t(
                          'welcome.formErrors.companyEmailDomain.reDoesntMatch'
                        ),
                        pattern: emailRegExp,
                      },
                    ]}>
                    <Input
                      size="large"
                      type="text"
                      placeholder="ejemplo@ejemplo.com"
                    />
                  </Form.Item>

                  <Form.Item
                    label={i18n.t('callMe.telephone')}
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: i18n.t('welcome.formErrors.common.required'),
                      },
                      {
                        validator: (_, value) =>
                          phoneLength.includes(value?.length)
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(i18n.t('callMe.telephoneWrongLength'))
                              ),
                      },
                      {
                        pattern: startWith,
                        message: i18n.t('callMe.telephoneWrongStartWith'),
                      },
                    ]}
                    normalize={(value) => {
                      let auxValue = value;

                      auxValue = value.replace(/[^0-9]/g, '');
                      auxValue.trim();

                      return auxValue;
                    }}>
                    <Input
                      addonBefore={`+${phonePrefix}`}
                      size="large"
                      type="tel"
                      placeholder={phoneExample}
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
            <div className="Poc_Footer">
              <div className="Poc_Footer_buttons">
                <Button
                  color="bluishGrey"
                  size="landing"
                  text={i18n.t('common.back')}
                  onClick={() => {
                    setAlternativeStep('');
                  }}
                />
                <Button
                  form="tester-form"
                  type="submit"
                  color="red"
                  size="landing"
                  text={
                    qualificationForm.finalPage.buttonText ||
                    i18n.t('tutorials.next')
                  }
                  disabled={isButtonDisabled()}
                />
                <Button
                  color="bluishGrey"
                  size="landing"
                  text={i18n.t('common.exit')}
                  onClick={() => {
                    setAlternativeStep(ALTERNATIVE_STEPS.exit);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </SCQualificationFormPage>
  );
};

export default QualificationFormPage;
