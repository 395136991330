import { useParams } from 'react-router-dom';
import { auth } from '../../firebase';
import { useTranslation } from '../../i18n';
import { signInWithGoogle } from '../../redux/actions/auth.actions';
import useRecaptcha from '../recaptcha/useRecaptcha';
import authService from '../../api/services/auth.service';
import { handleErrorPopUp } from '../../utils/constants/errors';

const useSignUpWithGoogle = () => {
  const { lang } = useTranslation();
  const { code } = useParams();
  const { getRecaptchaToken } = useRecaptcha();

  const handleSignUpWithGoogle = async () => {
    try {
      const { additionalUserInfo } = await signInWithGoogle();

      if (additionalUserInfo.isNewUser) {
        const idToken = await auth.currentUser.getIdToken(true);
        const captcha = await getRecaptchaToken();
        const user = {
          idToken,
          language: lang,
          promoCode: code,
        };

        await authService.signUpWithGoogle(user, captcha);
      }

      window.location.href = '/client';
    } catch (error) {
      const errorCode = error.response?.data?.error;
      handleErrorPopUp(errorCode);
    }
  };

  return { handleSignUpWithGoogle };
};

export default useSignUpWithGoogle;
