import axios from 'axios';
import { auth } from '../firebase';
import { SESSION_STORAGE_KEYS } from '../utils/constants/browserStorage';

const { REACT_APP_API_BASE_URL } = process.env;

const getAccessToken = async () => {
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdToken();
    return token;
  }
  return null;
};

const api = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

api.interceptors.request.use(async (config) => {
  const auxConfig = { ...config };

  const token = await getAccessToken();
  const clientIdFromSessionStorage = sessionStorage.getItem(
    SESSION_STORAGE_KEYS.CLIENT_ID
  );

  if (token) {
    auxConfig.headers.Authorization = `Bearer ${token}`;
  }

  if (clientIdFromSessionStorage) {
    auxConfig.headers['Overwrite-clientId'] = clientIdFromSessionStorage;
  }

  return auxConfig;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isCancel(error)) {
      return null;
    }

    return Promise.reject(error);
  }
);

export default api;
