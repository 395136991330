import api from '../config';
import { DEVICE_PROTECTION_API } from '../paths';

const getDownloadURLs = () => {
  return api.get(`${DEVICE_PROTECTION_API}/download-urls`);
};

const getPartnerClientLicensesCounter = (clientId) => {
  return api.get(
    `${DEVICE_PROTECTION_API}/partners/licenses-counter/${clientId}`
  );
};

const sendLicenses = ({ recipients, deferred }) => {
  return api.post(`${DEVICE_PROTECTION_API}/send`, {
    recipients,
    deferred,
  });
};

export default {
  getDownloadURLs,
  getPartnerClientLicensesCounter,
  sendLicenses,
};
