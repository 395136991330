import styled from '@emotion/styled';

const SCOnboardLayout = styled.div`
  min-height: 100vh;
  height: 100%;
  background-color: var(--application-background-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 60px;

  .onboard-elements {
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 0 10px;
  }

  @media (width < 850px) {
    gap: 40px;
  }
`;

export default SCOnboardLayout;
