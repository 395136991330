import axios from 'axios';
import { firestore } from '../../firebase';

export const addMultipleTeamMembers = (teamMembersArray, deferred) => {
  return axios
    .post('/clients/members/bulk', {
      membersToCreate: teamMembersArray,
      deferred,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteTeamMember = (teamMemberId) => {
  return axios.delete(`/clients/teamMember/${teamMemberId}`);
};

export const setTeamMemberLanguage = (language, clientId, userHashedEmail) => {
  firestore
    .collection('clients')
    .doc(clientId)
    .collection('team_members')
    .doc(userHashedEmail)
    .update({
      language,
    });
};
