import React from 'react';
import SCNewPhisingCampaignDate from './NewPhisingCampaignDateCard.style';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import DatePickerApp from '../../atoms/DatePicker/DatePicker';

const NewPhisingCampaignDateCard = ({ value, onChange, headerIcon }) => {
  const i18n = useTranslation();

  return (
    <SCNewPhisingCampaignDate>
      <SectionCard
        headerIcon={headerIcon}
        hasOverflow
        headerTitle={i18n.t('phishingSimulator.campaigns.create.selectDate')}>
        <DatePickerApp defaultValue={value} onChange={onChange} />
      </SectionCard>
    </SCNewPhisingCampaignDate>
  );
};

export default NewPhisingCampaignDateCard;
