import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import clientsService from '../../api/services/clients.service';
import { showPopUp } from '../../redux/actions/popUp.actions';
import { useTranslation } from '../../i18n';

const useGetAllPartnerCollaborators = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();

  const [allPartnerCollaborators, setAllPartnerCollaborators] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();

    const fetchAllPartnerCollaborators = async () => {
      setLoading(true);

      try {
        const resp = await clientsService.getAllPartnerCollaborators({
          signal: controller.signal,
        });

        setAllPartnerCollaborators(resp?.data?.data);
      } catch (e) {
        dispatch(
          showPopUp('notification', {
            notificationType: 'error',
            title: i18n.t('common.error'),
            text: i18n.t('errors.not_found'),
            closeButtonText: i18n.t('common.close'),
          })
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAllPartnerCollaborators();

    return () => {
      controller.abort();
    };
  }, []);

  return {
    allPartnerCollaborators,
    loading,
  };
};

export default useGetAllPartnerCollaborators;
