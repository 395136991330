import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  faCircle2,
  faCircle3,
  faCircle4,
} from '@fortawesome/pro-solid-svg-icons';
import {} from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import Button from '../../components/atoms/Button/Button';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import BackButton from '../../components/molecules/BackButton/BackButton';
import NewPhisingCampaignDateCard from '../../components/organisms/NewPhisingCampaignDateCard/NewPhisingCampaignDateCard';
import NewPhisingCampaignEmployeeSelectorCard from '../../components/organisms/NewPhisingCampaignEmployeeSelectorCard/NewPhisingCampaignEmployeeSelectorCard';
import NewPhisingCampaignTypeCard from '../../components/organisms/NewPhisingCampaignTypeCard/NewPhisingCampaignTypeCard';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import SCNewPhisingSimulator from './NewPhisingSimulator.style';
import {
  getPhishingCampaigns,
  PHISHING_CAMPAINGS_KEYS,
} from '../../utils/constants/phishingCampaigns';
import PhishingUploadLogo from '../../components/organisms/PhishingUploadLogo/PhishingUploadLogo';
import SpinnerText from '../../components/atoms/SpinnerText/SpinnerText';
import campaignsService from '../../api/services/campaigns.service';

const NewPhisingSimulator = () => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [campaignType, setCampaignType] = useState(undefined);
  const [destinations, setDestinations] = useState([]);
  const [launchDate, setLaunchDate] = useState(moment(new Date()));
  const [loading, setLoading] = useState(false);

  const startCampaign = async () => {
    const MAX_LIMIT = 10;
    const deferred = destinations.length > MAX_LIMIT;
    const PHISHING_CAMPAINGS = getPhishingCampaigns(i18n.lang);

    setLoading(true);

    try {
      const campaignData = {
        campaign_type: campaignType,
        campaign_name: i18n.t(
          `phishingSimulator.campaigns.campaingTypes.${PHISHING_CAMPAINGS[campaignType].name}`
        ),
        deferred,
        destinations,
        start_date: launchDate?.format('DD/MM/YYYY'),
      };

      await campaignsService.createCampaign(campaignData);

      dispatch(
        showPopUp('notification', {
          notificationType: 'success',
          title: deferred
            ? i18n.t('common.processingRequest')
            : i18n.t('common.processedRequest'),
          text: deferred
            ? i18n.t('phishingSimulator.campaigns.create.processingRequestText')
            : i18n.t('phishingSimulator.campaigns.create.processedRequestText'),
          onButtonClick: () => {
            navigate('/client/phishingsimulator');
          },
        })
      );
    } catch (e) {
      dispatch(
        showPopUp('notification', {
          notificationType: 'error',
          title: i18n.t('common.error'),
          text: i18n.t('errors.not_found'),
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const isPayrollCampaign = campaignType?.startsWith(
    PHISHING_CAMPAINGS_KEYS.payroll_campaign
  );

  return (
    <SectionLayout>
      <SCNewPhisingSimulator>
        <div className="section-back-button">
          <BackButton />
        </div>

        <div className="section-organism">
          <NewPhisingCampaignTypeCard
            selectedCampaignType={campaignType}
            onChange={setCampaignType}
          />
        </div>

        {isPayrollCampaign && (
          <div className="section-organism">
            <PhishingUploadLogo />
          </div>
        )}

        <div className="section-organism">
          <NewPhisingCampaignEmployeeSelectorCard
            onChange={setDestinations}
            nSelectedEmails={destinations.length}
            headerIcon={isPayrollCampaign ? faCircle3 : faCircle2}
          />
        </div>

        <div className="section-organism">
          <NewPhisingCampaignDateCard
            value={launchDate}
            onChange={setLaunchDate}
            headerIcon={isPayrollCampaign ? faCircle4 : faCircle3}
          />
        </div>

        <div className="section-organism">
          <div className="NewPhisingSimulator_Action">
            <Button
              color="bluishGrey"
              size="full"
              text={
                loading ? (
                  <SpinnerText
                    text={i18n.t('phishingSimulator.campaigns.create.release')}
                  />
                ) : (
                  i18n.t('phishingSimulator.campaigns.create.release')
                )
              }
              onClick={startCampaign}
              disabled={
                loading ||
                !campaignType ||
                destinations.length === 0 ||
                !launchDate
              }
            />
          </div>
        </div>
      </SCNewPhisingSimulator>
    </SectionLayout>
  );
};

export default NewPhisingSimulator;
