import React from 'react';
import { useTranslation } from '../../../i18n';
import { getInternationalizedImgLang } from '../../locale';

export const getInternationalizedPNGImage = (
  imgLocalesFolderName,
  imgFileName
) => {
  const { lang } = useTranslation();
  const internationalizedImgLang = getInternationalizedImgLang(lang);

  // eslint-disable-next-line
    return require(`../../../img/${imgLocalesFolderName}/${internationalizedImgLang}/${imgFileName}.png`)
    .default;
};

export const getInternationalizedSVGImage = (
  imgLocalesFolderName,
  imgFileName
) => {
  const { lang } = useTranslation();
  const internationalizedImgLang = getInternationalizedImgLang(lang);

  /* eslint-disable */
    const ImgComponent =
      require(`../../../img/${imgLocalesFolderName}/${internationalizedImgLang}/${imgFileName}.js`).default;
    /* eslint-enable */

  return <ImgComponent />;
};
