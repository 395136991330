import api from '../config';
import { CLIENTS_API } from '../paths';

const updateWelcomePopup = (data) => {
  return api.put(`${CLIENTS_API}/welcome-popup`, data);
};

const addSupplier = (data) => {
  return api.post(`${CLIENTS_API}/suppliers`, data);
};

const editSupplier = (domain, data) => {
  return api.put(`${CLIENTS_API}/suppliers`, data, {
    params: { id: domain },
  });
};

const getPartnerClient = (clientId) => {
  return api.get(`${CLIENTS_API}/partners/clients/${clientId}`);
};

const getPartnerData = (licensee) => {
  return api.get(`${CLIENTS_API}/partners/data`, {
    params: {
      licensee,
    },
  });
};

const addPartnerClient = (data) => {
  return api.post(`${CLIENTS_API}/partners/clients`, data);
};

const addPartnerCollaborator = (data) => {
  return api.post(`${CLIENTS_API}/partners/children`, data);
};

const getPartnerStatistics = (data) => {
  return api.get(`${CLIENTS_API}/partners/statistics`, {
    params: data,
  });
};

const getAllPartnerCollaborators = ({ signal }) => {
  return api.get(`${CLIENTS_API}/partners/all-children`, { signal });
};

const getPartnerCollaborators = () => {
  return api.get(`${CLIENTS_API}/partners/children`);
};

const getPartnerClients = ({ partnerId }) => {
  return api.get(`${CLIENTS_API}/partners/clients`, {
    params: {
      partnerId,
    },
  });
};

const getPartnerInfo = ({ clientId }) => {
  return api.get(`${CLIENTS_API}/partners/info/${clientId}`);
};

const updateBillingAddress = (data) => {
  return api.put(`${CLIENTS_API}/billing-address`, data);
};

const requestContact = (phone) => {
  return api.post(`${CLIENTS_API}/contact-request`, phone);
};

const editTeamMember = (teamMemberId, data) => {
  return api.put(`${CLIENTS_API}/members/${teamMemberId}`, data);
};

const addTeamMember = (data) => {
  return api.post(`${CLIENTS_API}/members`, data);
};

const addCompanyLogo = (base64Logo) => {
  return api.put(`${CLIENTS_API}/company-logo`, { logo: base64Logo });
};

const deleteCompanyLogo = () => {
  return api.delete(`${CLIENTS_API}/company-logo`);
};

export default {
  updateWelcomePopup,
  addSupplier,
  editSupplier,
  getPartnerClient,
  getPartnerData,
  addPartnerClient,
  addPartnerCollaborator,
  getPartnerStatistics,
  getAllPartnerCollaborators,
  getPartnerCollaborators,
  getPartnerClients,
  getPartnerInfo,
  updateBillingAddress,
  requestContact,
  editTeamMember,
  addTeamMember,
  addCompanyLogo,
  deleteCompanyLogo,
};
