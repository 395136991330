import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import clientsService from '../../api/services/clients.service';
import { showPopUp } from '../../redux/actions/popUp.actions';
import { useTranslation } from '../../i18n';

const useGetPartnerCollaborators = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();

  const [partnerCollaborators, setPartnerCollaborators] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPartnerCollaborators();
  }, []);

  const fetchPartnerCollaborators = async () => {
    setLoading(true);

    try {
      const resp = await clientsService.getPartnerCollaborators();

      setPartnerCollaborators(resp?.data?.data);
    } catch (e) {
      dispatch(
        showPopUp('notification', {
          notificationType: 'error',
          title: i18n.t('common.error'),
          text: i18n.t('errors.not_found'),
          closeButtonText: i18n.t('common.close'),
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    partnerCollaborators,
    loading,
    fetchPartnerCollaborators,
  };
};

export default useGetPartnerCollaborators;
