import styled from '@emotion/styled/macro';
import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../i18n';

import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { auth } from '../../../firebase';
import getLegalDocumentsUrls from '../../../utils/internationalizationModules/legalDocuments';
import PhonePrefixSelect from '../../molecules/PhonePrefixSelect/PhonePrefixSelect';
import {
  DEFAULT_COUNTRY,
  LOCALE_COUNTRIES,
} from '../../../utils/internationalizationModules/countries/countries';
import clientsService from '../../../api/services/clients.service';
import useRecaptcha from '../../../hooks/recaptcha/useRecaptcha';
import leadsService from '../../../api/services/leads.service';
import { handleErrorPopUp } from '../../../utils/constants/errors';

const SCCallMePopup = styled.div`
  .FormItem_button {
    margin-bottom: 0px;
  }

  .info-text {
    margin-bottom: 40px;
  }

  .terms-and-conditions-container {
    margin-top: 15px;

    display: flex;
    flex-direction: column;
    gap: 5px;

    > p {
      font-size: 12px;
      font-family: var(--font1);
      color: var(--bluish-grey);
      line-height: 18px;
      text-align: left;
    }
  }

  .ant-select-selector {
    border: none !important;
  }
`;

const CallMePopup = () => {
  const i18n = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { getRecaptchaToken } = useRecaptcha();

  const [loading, setLoading] = useState(false);

  const [selectedPhoneCountryCode, setSelectedPhoneCountryCode] = useState(
    DEFAULT_COUNTRY.code
  );

  const isLoggedIn = auth?.currentUser;
  const { phonePrefix, phoneLength, phoneExample, startWith } =
    LOCALE_COUNTRIES[selectedPhoneCountryCode];
  const { privacyPolicy } = getLegalDocumentsUrls(i18n.getLanguage());

  const onChangePhonePrefix = (value) => {
    setSelectedPhoneCountryCode(value);
    form.validateFields();
  };

  const selectBefore = (
    <PhonePrefixSelect
      defaultValue={selectedPhoneCountryCode}
      onChange={onChangePhonePrefix}
    />
  );

  const handleCallButton = async ({ phone, name }) => {
    setLoading(true);

    try {
      const phoneData = {
        phone: {
          countryCode: phonePrefix,
          country: selectedPhoneCountryCode,
          number: phone,
        },
      };

      if (auth?.currentUser) {
        await clientsService.requestContact(phoneData);
      } else {
        const token = await getRecaptchaToken();
        const requestData = {
          language: i18n.getLanguage(),
          name,
          ...phoneData,
        };

        await leadsService.requestContact(requestData, token);
      }

      dispatch(
        showPopUp('notification', {
          notificationType: 'success',
          title: i18n.t('callMe.successPopUp.title'),
          text: i18n.t('callMe.successPopUp.text'),
          closeButtonText: i18n.t('callMe.successPopUp.actions.close'),
        })
      );
    } catch (error) {
      const errorCode = error.response?.data?.error;
      handleErrorPopUp(errorCode);
    } finally {
      setLoading(false);
    }
  };

  const isButtonDisabled = () => {
    const inputsAreEmpty = Object.values(form.getFieldsValue()).some(
      (data) => !data
    );

    const hasError = form.getFieldsError().some(({ errors }) => errors.length);

    return inputsAreEmpty || hasError || loading;
  };

  const callMeButtonText = i18n.t('callMe.weCallYou') + (isLoggedIn ? '' : '*');

  return (
    <ModalLayout>
      <SCCallMePopup>
        <h1>{i18n.t('callMe.weCallYouQuestion')}</h1>

        <div className="modal-card-content">
          <Form
            form={form}
            onFinish={handleCallButton}
            layout="vertical"
            onFinishFailed={() => {
              console.log('Failed form');
            }}>
            {!isLoggedIn && (
              <Form.Item
                label={i18n.t('profile.name')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: i18n.t('welcome.formErrors.common.required'),
                  },
                ]}>
                <Input
                  size="large"
                  type="text"
                  placeholder={i18n.t('callMe.namePlaceholder')}
                />
              </Form.Item>
            )}

            <Form.Item
              label={i18n.t('callMe.telephone')}
              name="phone"
              rules={[
                {
                  required: true,
                  message: i18n.t('welcome.formErrors.common.required'),
                },
                {
                  validator: (_, value) =>
                    !phoneLength || phoneLength.includes(value?.length)
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(i18n.t('callMe.telephoneWrongLength'))
                        ),
                },
                {
                  pattern: startWith,
                  message: i18n.t('callMe.telephoneWrongStartWith'),
                },
              ]}
              normalize={(value) => {
                let auxValue = value;

                auxValue = value.replace(/[^0-9]/g, '');
                auxValue.trim();

                return auxValue;
              }}>
              <Input
                addonBefore={selectBefore}
                size="large"
                type="tel"
                placeholder={phoneExample}
              />
            </Form.Item>

            <p className="info-text">{i18n.t('callMe.weCallYouSchedule')}</p>

            <div className="modal-card-buttons">
              <Form.Item shouldUpdate className="FormItem_button">
                {() => (
                  <Button
                    type="submit"
                    size="full"
                    color="bluishGrey"
                    text={
                      loading ? (
                        <SpinnerText text={i18n.t('common.loading')} />
                      ) : (
                        callMeButtonText
                      )
                    }
                    disabled={isButtonDisabled()}
                  />
                )}
              </Form.Item>

              <Button
                text={i18n.t('websiteSecurity.add.cancel')}
                onClick={() => dispatch(showPopUp(null))}
                size="full"
                color="white"
              />
            </div>

            {!isLoggedIn && (
              <div className="terms-and-conditions-container">
                <p
                  dangerouslySetInnerHTML={{
                    __html: i18n.t(
                      'landing.acceptTermsAndConditionsCallMePopUp',
                      {
                        privacyPolicy: `<a href=${privacyPolicy} target="_blank" rel="noopener noreferrer">${i18n.t(
                          'landing.privacyPolicy'
                        )}</a>`,
                      }
                    ),
                  }}
                />

                <p>{i18n.t('landing.cyberGuardianWillUseData')}</p>

                <p
                  dangerouslySetInnerHTML={{
                    __html: i18n.t('landing.acceptTermsAndConditions2', {
                      privacyPolicy: `<a href=${privacyPolicy} target="_blank" rel="noopener noreferrer">${i18n.t(
                        'landing.privacyPolicy'
                      )}</a>`,
                    }),
                  }}
                />
              </div>
            )}
          </Form>
        </div>
      </SCCallMePopup>
    </ModalLayout>
  );
};

export default CallMePopup;
