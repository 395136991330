import styled from '@emotion/styled';

const SCEmailVerification = styled.div`
  text-align: center;
  background-color: var(--white);
  border-radius: var(--section-radius);
  padding: 40px;
  height: 100%;
  width: 100%;
  max-width: 420px;
  position: relative;
  box-shadow: 2px 2px 20px #79889714;

  display: flex;
  flex-direction: column;
  gap: 40px;

  .svg-container {
    margin-bottom: 40px;
  }

  p {
    font-family: var(--font2);
    font-weight: 600;
  }

  a {
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
  }

  .email-link-sent {
    color: var(--red);
  }
`;

export default SCEmailVerification;
