import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxTaped,
  faScanner,
  faFileInvoiceDollar,
  faFileImport,
  faMessageSmile,
  faMoneyCheck,
} from '@fortawesome/pro-regular-svg-icons';

export const PHISHING_CAMPAINGS_KEYS = {
  generic_delivery_campaign: 'generic_delivery_campaign',
  printer_document_campaign: 'printer_document_campaign',
  electricity_campaign: 'electricity_campaign',
  fileshare_campaign: 'fileshare_campaign',
  payroll_campaign: 'payroll_campaign',
  feedback_campaign: 'feedback_campaign',
};

export const getPhishingCampaigns = (lang) => {
  const getCampaignsType = (key) => {
    return lang === 'en' ? key : `${key}-${lang}`;
  };

  const CAMPAINGS_TYPE = {
    generic_delivery_campaign: getCampaignsType(
      PHISHING_CAMPAINGS_KEYS.generic_delivery_campaign
    ),
    printer_document_campaign: getCampaignsType(
      PHISHING_CAMPAINGS_KEYS.printer_document_campaign
    ),
    electricity_campaign: getCampaignsType(
      PHISHING_CAMPAINGS_KEYS.electricity_campaign
    ),
    fileshare_campaign: getCampaignsType(
      PHISHING_CAMPAINGS_KEYS.fileshare_campaign
    ),
    payroll_campaign: getCampaignsType(
      PHISHING_CAMPAINGS_KEYS.payroll_campaign
    ),
    feedback_campaign: getCampaignsType(
      PHISHING_CAMPAINGS_KEYS.feedback_campaign
    ),
  };

  const PHISHING_CAMPAINGS = {
    [CAMPAINGS_TYPE.generic_delivery_campaign]: {
      type: CAMPAINGS_TYPE.generic_delivery_campaign,
      name: PHISHING_CAMPAINGS_KEYS.generic_delivery_campaign,
      img: <FontAwesomeIcon icon={faBoxTaped} />,
    },
    [CAMPAINGS_TYPE.printer_document_campaign]: {
      type: CAMPAINGS_TYPE.printer_document_campaign,
      name: PHISHING_CAMPAINGS_KEYS.printer_document_campaign,
      img: <FontAwesomeIcon icon={faScanner} />,
    },
    [CAMPAINGS_TYPE.electricity_campaign]: {
      type: CAMPAINGS_TYPE.electricity_campaign,
      name: PHISHING_CAMPAINGS_KEYS.electricity_campaign,
      img: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
    },
    [CAMPAINGS_TYPE.fileshare_campaign]: {
      type: CAMPAINGS_TYPE.fileshare_campaign,
      name: PHISHING_CAMPAINGS_KEYS.fileshare_campaign,
      img: <FontAwesomeIcon icon={faFileImport} />,
    },
    [CAMPAINGS_TYPE.payroll_campaign]: {
      type: CAMPAINGS_TYPE.payroll_campaign,
      name: PHISHING_CAMPAINGS_KEYS.payroll_campaign,
      img: <FontAwesomeIcon icon={faMoneyCheck} />,
    },
    [CAMPAINGS_TYPE.feedback_campaign]: {
      type: CAMPAINGS_TYPE.feedback_campaign,
      name: PHISHING_CAMPAINGS_KEYS.feedback_campaign,
      img: <FontAwesomeIcon icon={faMessageSmile} />,
    },
  };

  return PHISHING_CAMPAINGS;
};
