import React from 'react';
import { faShieldPlus } from '@fortawesome/pro-regular-svg-icons';
import InfoBanner from '../InfoBanner/InfoBanner';
import { useTranslation } from '../../../i18n';
import usePaymentAccess from '../../../hooks/paymentAccess/usePaymentAccess';
import { CURRENT_LOCALE, LOCALES } from '../../../utils/locale';

const YourSubscriptionCardInfoBanner = () => {
  const i18n = useTranslation();
  const { handlePaymentAccess } = usePaymentAccess();

  return (
    <>
      {CURRENT_LOCALE !== LOCALES.eu ? (
        <InfoBanner
          text={[
            i18n.t('controlPanel.widgetSubscription.modifyPlanTextNotSpain'),
          ]}
          width="fit-content"
          minified
        />
      ) : (
        <InfoBanner
          text={[i18n.t('controlPanel.widgetSubscription.modifyPlanText')]}
          onClickButton={() => handlePaymentAccess()}
          buttonIcon={faShieldPlus}
          buttonText={i18n.t('controlPanel.widgetSubscription.planButton')}
          width="fit-content"
          minified
        />
      )}
    </>
  );
};

export default YourSubscriptionCardInfoBanner;
