import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector } from 'react-redux';
import FullPageModal from '../FullPageModal/FullPageModal';
import { useTranslation } from '../../../i18n';
import useSignOut from '../../../hooks/useSignOut/useSignOut';

const DEFAULT_TIMEOUT_MIN = 15;
const TIMEOUT_MIN =
  Number(process.env.REACT_APP_SESSION_TIMEOUT_MINUTES) ?? DEFAULT_TIMEOUT_MIN;
const TIMEOUT_SEC = TIMEOUT_MIN * 60;
const TIMEOUT_MS = TIMEOUT_SEC * 1000;

const PROMPT_BEFORE_IDLE_SEC = 30;

const SessionTimeout = () => {
  const user = useSelector((redux) => redux.user);
  const i18n = useTranslation();
  const { signOutFromApp } = useSignOut();

  const [remaining, setRemaining] = useState(TIMEOUT_SEC);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const onIdle = () => {
    localStorage.setItem('sessionTimeoutLogout', true);
    signOutFromApp();
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: TIMEOUT_MS,
    crossTab: true,
    syncTimers: 500,
    disabled: Boolean(!user),
  });

  const showWarningPopUp = remaining <= PROMPT_BEFORE_IDLE_SEC;

  return (
    <>
      {showWarningPopUp && (
        <FullPageModal
          mainText={i18n.t('errors.sessionTimeoutWarning', {
            seconds: remaining,
          })}
        />
      )}
    </>
  );
};

export default SessionTimeout;
