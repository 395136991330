import axios from 'axios';
import { firestore } from '../../firebase';
import { SUBSCRIPTIONS_PRODUCTS } from '../../utils/constants/subscription';

export const getPaidSubscription = (clientId) => {
  return axios.get(`/api/subscriptions?client=${clientId}&product=paying`);
};

export const getClientSecret = () => {
  return axios.get('/api/subscriptions/client-secret');
};

export const startTrialSubscription = () => {
  return axios.post('/api/subscriptions/trial');
};

export const getClientCards = () => {
  return axios.get('/api/subscriptions/cards');
};

export const getSubscriptionPrices = () => {
  return axios.get('/api/subscriptions/prices');
};

export const getAvailablePaymentFrequencies = async () => {
  const doc = await firestore.collection('config').doc('payments').get();

  return doc.data();
};

export const getTrialSubscription = async (clientId) => {
  const subscription = await firestore
    .collection('clients')
    .doc(clientId)
    .collection('subscriptions')
    .where('product', '==', SUBSCRIPTIONS_PRODUCTS.TRIAL)
    .orderBy('startTime', 'desc')
    .get();

  return subscription.docs?.[0]?.data();
};
