import { CURRENT_LOCALE } from '../../locale';
import { AMERICAN_COUNTRIES } from './america';
import { EUROPEAN_COUNTRIES } from './europe';

const COUNTRIES_BY_LOCALE = {
  eu: EUROPEAN_COUNTRIES,
  seu: EUROPEAN_COUNTRIES,
  sam: AMERICAN_COUNTRIES,
  am: AMERICAN_COUNTRIES,
};

const DEFAULT_COUNTRIES = {
  eu: EUROPEAN_COUNTRIES.ES,
  seu: EUROPEAN_COUNTRIES.ES,
  sam: AMERICAN_COUNTRIES.US,
  am: AMERICAN_COUNTRIES.US,
};

export const LOCALE_COUNTRIES = COUNTRIES_BY_LOCALE[CURRENT_LOCALE];
export const DEFAULT_COUNTRY = DEFAULT_COUNTRIES[CURRENT_LOCALE];
