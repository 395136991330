import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, Input } from 'antd';
import { useTranslation } from '../../../i18n';
import { InfoKey } from '../../../track';
import Button from '../../atoms/Button/Button';
import SCWelcomePopup from './WelcomePopup.style';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { toBase64 } from '../../../utils/functions/base64';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import clientsService from '../../../api/services/clients.service';
import { handleErrorPopUp } from '../../../utils/constants/errors';

const regDomain =
  /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{1,11}?$/im;

const isBlacklistedDomain = async (domain) => {
  const encodedDomain = toBase64(domain);
  let resultado = false;

  await axios
    .get(`/blacklisted-domain/${encodedDomain}`)
    .then((res) => {
      if (res.data.code === 'auth/domain-blacklisted') {
        resultado = true;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error.response);
      console.log(error.data);
    });

  return resultado;
};

const isWarnDomain = async (domain) => {
  const encodedAtEmail = toBase64(domain);

  return axios
    .get(`/warn-email-providers/${encodedAtEmail}`)
    .then((res) => res.data.code === 'auth/domain-warnlisted')
    .catch((error) => {
      console.log(error);
      console.log(error.response);
      console.log(error.data);
    });
};

const WelcomePopup = () => {
  const user = useSelector((redux) => redux.user);
  const client = useSelector((redux) => redux.client);
  const [loading, setLoading] = useState(false);
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onValuesChange = (changedValues) => {
    if (typeof changedValues?.website === 'string') {
      form.setFieldValue('atEmail', changedValues?.website);
      form.validateFields(['atEmail']);
    }
  };

  /**
   * Checks if, emailDomain and website are correct. If not, shows the corresponding modal.
   * @returns {boolean} Wether emailDomain and website are correct or not.
   */
  const isValid = async (atEmail, website) => {
    /**
     * Comprobar email.
     * Si no tiene formato correcto, saca error y devuelve falso.
     * Si está dentro de la colección /email_restrictions/warn_email_providers de firebase, salta un aviso.
     * Si no está dentro de la colección /email_restrictions/warn_email_providers de firebase, si está en la blacklist saca error y devuelve falso.
     */
    const isInEmailProvidersToWarn = await isWarnDomain(atEmail);

    if (!isInEmailProvidersToWarn && (await isBlacklistedDomain(atEmail))) {
      form.setFields([
        {
          name: 'companyEmailDomain',
          errors: ['emailDomain inside blacklist'],
        },
      ]);
      return false;
    }

    // Comprobar dominio.
    if (await isBlacklistedDomain(website)) {
      form.setFields([
        {
          name: 'companyWebsite',
          errors: ['website inside blacklist'],
        },
      ]);
      return false;
    }

    if (isInEmailProvidersToWarn) {
      InfoKey('setup.company.website.warnEmailProvider');
    }

    return true;
  };

  const saveCompany = async ({ atEmail, name, website }) => {
    setLoading(true);

    if (await isValid(atEmail, website)) {
      try {
        await clientsService.updateWelcomePopup({
          companyName: name.trim(),
          website,
          atEmail,
        });

        dispatch(
          showPopUp('notification', {
            notificationType: 'success',
            title: i18n.t('common.youAreReady'),
            additionalButtonText: i18n.t(
              'tutorials.initialTutorial.checkInitialTutorial'
            ),
            onButtonClick: () => {
              navigate('/initial-tutorial');
            },
            closeButtonText: i18n.t('common.start'),
          })
        );
      } catch (error) {
        const errorCode = error.response?.data?.error;
        handleErrorPopUp(errorCode);
      } finally {
        setLoading(false);
      }
    }
  };

  const isButtonDisabled = () => {
    const inputsAreEmpty = Object.values(form.getFieldsValue()).some(
      (data) => !data
    );

    const hasError = form.getFieldsError().some(({ errors }) => errors.length);

    return inputsAreEmpty || hasError || loading;
  };

  return (
    <ModalLayout>
      <SCWelcomePopup>
        <>
          <h1>
            {i18n.t('welcome.title')}
            {user?.firstName ? ` ${user.firstName}!` : '!'}
          </h1>
          <div className="modal-card-content">
            <div className="inputs-container">
              <p>{i18n.t('welcome.provideCompanyInfo')}</p>
              <Form
                id="welcomePopUp_FirstForm"
                name="welcomePopUp_FirstForm"
                labelCol={{ span: 20 }}
                form={form}
                onFinish={saveCompany}
                layout="vertical"
                onValuesChange={onValuesChange}
                onFinishFailed={() => {
                  console.log('Failed form');
                }}
                initialValues={{
                  name: client.company.name,
                  website: client.company.website,
                  atEmail: client.atEmail,
                }}>
                <Form.Item
                  label={i18n.t('welcome.companyName')}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: i18n.t('welcome.formErrors.common.required'),
                    },
                  ]}>
                  <Input
                    size="large"
                    type="text"
                    placeholder={i18n.t('welcome.companyName')}
                  />
                </Form.Item>

                <Form.Item
                  label={i18n.t('welcome.website')}
                  name="website"
                  normalize={(value) => value.trim().toLowerCase()}
                  rules={[
                    {
                      required: true,
                      message: i18n.t('welcome.formErrors.common.required'),
                    },
                    {
                      message: i18n.t('setup.company.website.errorWebFormat'),
                      pattern: regDomain,
                    },
                    {
                      validator: (_, value) =>
                        !value.startsWith('www.')
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                i18n.t('setup.company.website.errorStartWWW')
                              )
                            ),
                    },
                  ]}>
                  <Input
                    size="large"
                    type="text"
                    addonBefore="www."
                    placeholder={i18n.t('welcome.websiteExample')}
                  />
                </Form.Item>

                <Form.Item
                  label={i18n.t('welcome.emailDomain')}
                  name="atEmail"
                  normalize={(value) => value.trim().toLowerCase()}
                  rules={[
                    {
                      required: true,
                      message: i18n.t('welcome.formErrors.common.required'),
                    },
                    {
                      message: i18n.t(
                        'welcome.formErrors.companyEmailDomain.reDoesntMatch'
                      ),
                      pattern: regDomain,
                    },
                    {
                      validator: (_, value) =>
                        !value.startsWith('www.')
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                i18n.t(
                                  'setup.company.website.errorStartWWWAtEmail'
                                )
                              )
                            ),
                    },
                  ]}>
                  <Input
                    size="large"
                    type="text"
                    addonBefore="@"
                    placeholder={i18n.t('welcome.websiteExample')}
                  />
                </Form.Item>

                <Form.Item shouldUpdate className="FormItem_button">
                  {() => (
                    <Button
                      type="submit"
                      size="full"
                      color="bluishGrey"
                      text={
                        loading ? (
                          <SpinnerText text={i18n.t('common.loading')} />
                        ) : (
                          i18n.t('common.startNow')
                        )
                      }
                      onClick={async () => {
                        await form.validateFields();
                      }}
                      disabled={isButtonDisabled()}
                    />
                  )}
                </Form.Item>
              </Form>
            </div>
          </div>
        </>
      </SCWelcomePopup>
    </ModalLayout>
  );
};

export default WelcomePopup;
