import { useState, useEffect } from 'react';
import promoCodeService from '../../services/promoCode/promoCode.service';

const usePromoCode = (setupCode) => {
  const [promoCode, setPromoCode] = useState();

  useEffect(() => {
    if (setupCode) {
      promoCodeService.getPromoCode(setupCode).then(setPromoCode);
    }
  }, []);

  return {
    promoCode,
  };
};

export default usePromoCode;
