import React, { useEffect, useState } from 'react';
import { faCheck, faShieldPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faApple,
  faLinux,
  faWindows,
} from '@fortawesome/free-brands-svg-icons';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import SCInstallers, { SCDivider, SCOSButton } from './Installers.style';
import { useTranslation } from '../../i18n';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import SectionHeader from '../../components/molecules/SectionHeader/SectionHeader';
import SectionCard from '../../components/molecules/SectionCard/SectionCard';
import getInstallerFAQLink from '../../utils/internationalizationModules/installerFAQs';
import deviceProtectionService from '../../api/services/deviceProtection.service';
import { showPopUp } from '../../redux/actions/popUp.actions';
import BackButton from '../../components/molecules/BackButton/BackButton';

const DOWNLOAD_OS = {
  WINDOWS: 'WINDOWS',
  MACOS: 'MACOS',
  LINUX: 'LINUX',
};

const Installers = () => {
  const [selectedOS, setSelectedOS] = useState(DOWNLOAD_OS.WINDOWS);
  const [isLoadingCopy, setIsLoadingCopy] = useState({});
  const [downloadURLs, setDownloadURLs] = useState({});

  const i18n = useTranslation();
  const dispatch = useDispatch();

  const { generic, windows, macOS, linux } = getInstallerFAQLink(i18n.lang);

  useEffect(() => {
    deviceProtectionService
      .getDownloadURLs()
      .then(({ data }) => setDownloadURLs(data?.data))
      .catch(() => {
        dispatch(
          showPopUp('notification', {
            notificationType: 'error',
            title: i18n.t('common.error'),
            text: i18n.t('errors.not_found'),
          })
        );
      });
  }, []);

  const {
    windowsGUIx64,
    windowsCLIx64,
    macGUI,
    macCLI,
    linuxCLIx64,
    linuxCLIARM,
  } = downloadURLs || {};

  const OS_CONFIG = {
    WINDOWS: [
      {
        icon: faWindows,
        title: 'Windows GUI x64',
        text: i18n.t('deviceSecurity.installers.windowsGUI'),
        moreInfoLink: windows,
        downloadLink: windowsGUIx64,
      },
      {
        icon: faWindows,
        title: 'Windows CLI x64',
        text: i18n.t('deviceSecurity.installers.windowsCLI'),
        moreInfoLink: windows,
        downloadLink: windowsCLIx64,
        token: 'cyberguardian_cli_x64 <token>',
      },
    ],
    MACOS: [
      {
        icon: faApple,
        title: 'MacOS GUI',
        text: i18n.t('deviceSecurity.installers.macOSGUI'),
        moreInfoLink: macOS,
        downloadLink: macGUI,
      },
      {
        icon: faApple,
        title: 'MacOS CLI',
        text: i18n.t('deviceSecurity.installers.macOSCLI'),
        moreInfoLink: macOS,
        downloadLink: macCLI,
        token: 'cyberguardian_cli <token>',
      },
    ],
    LINUX: [
      {
        icon: faLinux,
        title: 'Linux x64',
        text: i18n.t('deviceSecurity.installers.linuxGUI'),
        moreInfoLink: linux,
        downloadLink: linuxCLIx64,
        token:
          'chmod +x ./cyberguardian_x64.run;./cyberguardian_x64.run <token>',
      },
      {
        icon: faLinux,
        title: 'Linux arm64',
        text: i18n.t('deviceSecurity.installers.linuxCLI'),
        moreInfoLink: linux,
        downloadLink: linuxCLIARM,
        token:
          'chmod +x ./cyberguardian_arm64.run;./cyberguardian_arm64.run <token>',
      },
    ],
  };

  const handleCopy = (value) => {
    setIsLoadingCopy(() => ({
      [value]: true,
    }));

    navigator.clipboard.writeText(value);

    setTimeout(() => {
      setIsLoadingCopy(() => ({
        [value]: false,
      }));
    }, 2000);
  };

  return (
    <SectionLayout>
      <SCInstallers>
        <div className="section-back-button">
          <BackButton />
        </div>

        <div className="section-organism">
          <SectionHeader
            icon={faShieldPlus}
            title={i18n.t('sections.protection.name')}
            subtitle={i18n.t('deviceSecurity.installers.title')}
          />
        </div>

        <div className="section-oranism">
          <SectionCard>
            <div className="section-content">
              <div className="header">
                <h2
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: i18n.t('deviceSecurity.installers.cardTitle'),
                  }}
                />
                <SCDivider type="vertical" />
                <div className="os-icons-wrapper">
                  <p>{i18n.t('deviceSecurity.installers.selectOS')}:</p>
                  <div className="os-icons">
                    <SCOSButton
                      selected={selectedOS === DOWNLOAD_OS.WINDOWS}
                      onClick={() => setSelectedOS(DOWNLOAD_OS.WINDOWS)}>
                      <FontAwesomeIcon icon={faWindows} size="3x" />
                    </SCOSButton>
                    <SCOSButton
                      selected={selectedOS === DOWNLOAD_OS.MACOS}
                      onClick={() => setSelectedOS(DOWNLOAD_OS.MACOS)}>
                      <FontAwesomeIcon icon={faApple} size="3x" />
                    </SCOSButton>
                    <SCOSButton
                      selected={selectedOS === DOWNLOAD_OS.LINUX}
                      onClick={() => setSelectedOS(DOWNLOAD_OS.LINUX)}>
                      <FontAwesomeIcon icon={faLinux} size="3x" />
                    </SCOSButton>
                  </div>
                </div>
              </div>
              <div>
                <p>
                  {i18n.t('deviceSecurity.installers.disclaimer')}{' '}
                  <a href={generic} target="_blank" rel="noreferrer">
                    {i18n.t('common.here').toLowerCase()}
                  </a>
                  .
                </p>
              </div>
              <div className="download-content-wrapper">
                {OS_CONFIG[selectedOS].map(
                  ({
                    icon,
                    title,
                    text,
                    moreInfoLink,
                    downloadLink,
                    token,
                  }) => (
                    <div className="download-content">
                      <div className="download-content-header">
                        <FontAwesomeIcon
                          icon={icon}
                          color="var(--bluish-grey)"
                        />
                        <p>{title}</p>
                      </div>
                      <div className="download-content-body">
                        <p className="download-content-text">
                          {text}{' '}
                          <a
                            href={moreInfoLink}
                            target="_blank"
                            rel="noreferrer">
                            {i18n.t('common.moreInfo')}.
                          </a>
                        </p>
                        <div className="download-content-button">
                          <a
                            href={downloadLink}
                            className={!downloadLink ? 'disabled' : ''}
                            download>
                            {i18n.t('common.download')}
                          </a>
                        </div>
                      </div>
                      {token && (
                        <div>
                          <button
                            className="token-button"
                            type="button"
                            onClick={() => handleCopy(token)}
                            disabled={isLoadingCopy[token]}>
                            {token}
                            <FontAwesomeIcon
                              icon={isLoadingCopy[token] ? faCheck : faCopy}
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          </SectionCard>
        </div>
      </SCInstallers>
    </SectionLayout>
  );
};

export default Installers;
