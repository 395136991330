import styled from '@emotion/styled';

const SCForgotPassword = styled.div`
  text-align: center;
  background-color: var(--white);
  border-radius: var(--section-radius);
  padding: 40px;
  height: 100%;
  width: 100%;
  max-width: 420px;
  position: relative;

  box-shadow: 2px 2px 20px #79889714;

  .icon-container {
    margin-bottom: 40px;
  }

  a {
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
  }

  .ForgotPassword_Desc {
    font-size: 16px;
    font-weight: 600;
    margin: 40px 0px;
  }

  .ForgotPassword_Actions {
    margin: 40px 0px 30px 0px;

    button {
      margin-top: 50px;
    }
  }

  .email-sent {
    font-weight: 600;
  }
`;

export default SCForgotPassword;
