import styled from '@emotion/styled';

const SCAccountCreated = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  background-color: var(--white);
  border-radius: var(--section-radius);
  padding: 40px;
  height: 100%;
  width: 100%;
  max-width: 420px;
  box-shadow: 2px 2px 20px #79889714;
  text-align: center;

  .icon-container {
    margin-bottom: 10px;
  }

  p {
    font-family: var(--font2);
    font-weight: 600;
  }

  .email-link-sent {
    color: var(--red);
  }
`;

export default SCAccountCreated;
