import {
  faBuilding,
  faEnvelopeOpen,
  faPen,
  faPhone,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCYourInformationCard from './YourInformationCard.style';
import Button from '../../atoms/Button/Button';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import PhonePrefixSelect from '../../molecules/PhonePrefixSelect/PhonePrefixSelect';
import authService from '../../../api/services/auth.service';
import { UPDATE_USER } from '../../../redux/actions/types';
import {
  DEFAULT_COUNTRY,
  LOCALE_COUNTRIES,
} from '../../../utils/internationalizationModules/countries/countries';
import { handleErrorPopUp } from '../../../utils/constants/errors';

const YourInformationCard = () => {
  const i18n = useTranslation();
  const user = useSelector((state) => state.user);
  const client = useSelector((redux) => redux.client);
  const firebase = useSelector((state) => state.firebase);
  const { currentUser } = firebase.auth();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [formEdit, setFormEdit] = useState(false);
  const [isSaving, setIsSaving] = useState();
  const phoneCountryCodeDefault = user.phone?.country
    ? user.phone?.country
    : DEFAULT_COUNTRY.code;
  const [selectedPhoneCountryCode, setSelectedPhoneCountryCode] = useState(
    phoneCountryCodeDefault
  );

  const { phonePrefix, phoneLength, phoneExample, startWith } =
    LOCALE_COUNTRIES[selectedPhoneCountryCode];

  const onFinish = async ({ firstName, lastName, phoneNumber }) => {
    setIsSaving(true);

    const updatedData = {
      firstName,
      lastName,
      ...(phoneNumber && {
        phone: {
          countryCode: phonePrefix,
          country: selectedPhoneCountryCode,
          number: phoneNumber,
        },
      }),
    };

    try {
      await authService.updateUser(updatedData);
      setFormEdit(false);

      dispatch({
        type: UPDATE_USER,
        payload: updatedData,
      });
    } catch (error) {
      const errorCode = error.response?.data?.error;
      handleErrorPopUp(errorCode);
    } finally {
      setIsSaving(false);
    }
  };

  const onChangePhonePrefix = (value) => {
    setSelectedPhoneCountryCode(value);
    form.validateFields();
  };

  const disableInput = !formEdit || isSaving;
  const disablePhoneInput =
    currentUser?.multiFactor?.enrolledFactors?.length > 0 || disableInput;

  const selectBefore = (
    <PhonePrefixSelect
      defaultValue={selectedPhoneCountryCode}
      onChange={onChangePhonePrefix}
      disabled={disablePhoneInput}
    />
  );

  const isSubmitButtonDisabled = () => {
    const hasError = form.getFieldsError().some(({ errors }) => errors.length);

    return hasError || isSaving;
  };

  return (
    <SCYourInformationCard>
      <SectionCard
        headerIcon={faUser}
        headerTitle={i18n.t('profile.yourDetails')}>
        <Form
          className="your-information-form"
          labelCol={{ span: 20 }}
          form={form}
          onFinish={onFinish}
          layout="horizontal"
          variant={formEdit ? 'outlined' : 'borderless'}
          onFinishFailed={() => {
            console.log('Failed form');
          }}
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phone?.number,
            companyName: client.company.name,
            email: user.email,
          }}>
          <div className="profile-info-grid">
            <div className="your-information-form-item">
              <FontAwesomeIcon
                className="your-information-form-item-icon"
                icon={faUser}
                color="var(--bluish-grey)"
                size="1x"
              />
              <Form.Item
                name="firstName"
                className="your-information-form-item-input"
                rules={[
                  {
                    required: true,
                    message: i18n.t('welcome.formErrors.common.required'),
                  },
                ]}>
                <Input
                  size="large"
                  type="text"
                  inputPlaceholder={i18n.t('profile.name')}
                  disabled={disableInput}
                />
              </Form.Item>
            </div>

            <div className="your-information-form-item">
              <FontAwesomeIcon
                className="your-information-form-item-icon"
                icon={faUser}
                color="var(--bluish-grey)"
              />
              <Form.Item
                className="your-information-form-item-input"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: i18n.t('welcome.formErrors.common.required'),
                  },
                ]}>
                <Input size="large" type="text" disabled={disableInput} />
              </Form.Item>
            </div>

            <div className="your-information-form-item">
              <FontAwesomeIcon
                className="your-information-form-item-icon"
                icon={faPhone}
                color="var(--bluish-grey)"
              />
              <Form.Item
                className="your-information-form-item-input"
                name="phoneNumber"
                rules={[
                  {
                    validator: (_, value) =>
                      !value ||
                      !phoneLength ||
                      phoneLength.includes(value?.length)
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(i18n.t('callMe.telephoneWrongLength'))
                          ),
                  },
                  {
                    pattern: startWith,
                    message: i18n.t('callMe.telephoneWrongStartWith'),
                  },
                ]}
                normalize={(value) => {
                  let auxValue = value;

                  auxValue = value.replace(/[^0-9]/g, '');
                  auxValue.trim();

                  return auxValue;
                }}>
                <Input
                  {...((user.phone?.number || formEdit) && {
                    addonBefore: formEdit
                      ? selectBefore
                      : `+${user.phone?.countryCode}`,
                  })}
                  size="large"
                  type="tel"
                  placeholder={
                    formEdit ? phoneExample : i18n.t('profile.phoneEx')
                  }
                  disabled={disablePhoneInput}
                />
              </Form.Item>
            </div>

            <div className="your-information-form-item">
              <FontAwesomeIcon
                className="your-information-form-item-icon"
                icon={faEnvelopeOpen}
                color="var(--bluish-grey)"
              />
              <Form.Item
                className="your-information-form-item-input"
                name="email">
                <Input size="large" type="text" disabled />
              </Form.Item>
            </div>

            <div className="your-information-form-item">
              <FontAwesomeIcon
                className="your-information-form-item-icon"
                icon={faBuilding}
                color="var(--bluish-grey)"
              />
              <Form.Item
                className="your-information-form-item-input"
                name="companyName">
                <Input size="large" type="text" disabled />
              </Form.Item>
            </div>
          </div>

          <div className="buttons-form-container">
            {formEdit ? (
              <>
                <Form.Item
                  shouldUpdate
                  className="FormItem_button"
                  style={{ marginBottom: 0 }}>
                  {() => (
                    <Button
                      type="submit"
                      text={i18n.t('profile.save')}
                      size="mid"
                      color="bluishGrey"
                      loading={isSaving}
                      disabled={isSubmitButtonDisabled()}
                    />
                  )}
                </Form.Item>

                <Button
                  text={i18n.t('common.cancel')}
                  size="mid"
                  color="white"
                  onClick={() => {
                    form.resetFields();
                    setSelectedPhoneCountryCode(phoneCountryCodeDefault);
                    setFormEdit(false);
                  }}
                />
              </>
            ) : (
              <Button
                text={
                  !isSaving ? (
                    i18n.t('profile.edit')
                  ) : (
                    <SpinnerText text={i18n.t('misc.saving')} />
                  )
                }
                icon={!isSaving && faPen}
                size="mid"
                color="white"
                onClick={() => {
                  setFormEdit(true);
                }}
                disabled={isSaving}
              />
            )}
          </div>
        </Form>
      </SectionCard>
    </SCYourInformationCard>
  );
};
export default YourInformationCard;
