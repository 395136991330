import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from '../../redux/actions/auth.actions';
import authService from '../../api/services/auth.service';

const useSignOut = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const signOutFromApp = async () => {
    setLoading(true);

    try {
      await authService.signOut();
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(signOut());
      setLoading(false);
    }
  };

  return {
    loading,
    signOutFromApp,
  };
};

export default useSignOut;
