import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Radio } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';

import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import { REGULAR_EXPRESSIONS } from '../../../utils/constants/regularExpressions';
import { PARTNER_TYPES } from '../../../utils/constants/partners';

const SCAddCollaboratorFromPartner = styled.div`
  .modal-buttons {
    margin-top: 40px;

    > .ant-form-item {
      margin-bottom: 10px;
    }
  }

  .add-collaborator-from-partner-form-inputs {
    display: grid;
    gap: 0px 10px;
    grid-template-columns: repeat(2, 1fr);

    > div {
      grid-column: span 2;
    }

    .span-1 {
      grid-column: span 1;
    }

    @media (width < 720px) {
      grid-template-columns: auto;

      > div,
      .span-1 {
        grid-column: auto;
      }
    }
  }
`;

const COLLABORATOR_TYPES = [PARTNER_TYPES.MSSP, PARTNER_TYPES.RESELLER];

const AddCollaboratorFromPartner = ({ handleCreateCollaborator }) => {
  const [loading, setLoading] = useState(false);
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const saveCollaborator = async (formValues) => {
    setLoading(true);

    await handleCreateCollaborator(formValues);

    setLoading(false);
  };

  const isButtonDisabled = () => {
    const fieldValues = form.getFieldsValue();

    const inputsAreEmpty = Object.values(fieldValues).some((data) => !data);

    const hasError = form.getFieldsError().some(({ errors }) => errors.length);

    return inputsAreEmpty || hasError || loading;
  };

  return (
    <ModalLayout>
      <SCAddCollaboratorFromPartner>
        <h1>{i18n.t('partner.collaborators.addCollaboratorPopUp.title')}</h1>
        <div className="modal-card-content">
          <Form
            form={form}
            onFinish={saveCollaborator}
            layout="vertical"
            initialValues={{ type: COLLABORATOR_TYPES[0] }}
            onFinishFailed={() => {
              console.log('Failed form');
            }}>
            <div className="add-collaborator-from-partner-form-inputs">
              <Form.Item
                label={i18n.t('partner.addClientPopUp.companyName')}
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: i18n.t('welcome.formErrors.common.required'),
                  },
                ]}>
                <Input
                  size="large"
                  type="text"
                  placeholder={i18n.t('partner.addClientPopUp.companyName')}
                />
              </Form.Item>

              <Form.Item
                label={i18n.t('partner.addClientPopUp.firstName')}
                name="firstName"
                className="span-1"
                rules={[
                  {
                    required: true,
                    message: i18n.t('welcome.formErrors.common.required'),
                  },
                ]}>
                <Input
                  size="large"
                  type="text"
                  placeholder={i18n.t('partner.addClientPopUp.firstName')}
                />
              </Form.Item>

              <Form.Item
                label={i18n.t('partner.addClientPopUp.lastName')}
                name="lastName"
                className="span-1"
                rules={[
                  {
                    required: true,
                    message: i18n.t('welcome.formErrors.common.required'),
                  },
                ]}>
                <Input
                  size="large"
                  type="text"
                  placeholder={i18n.t('partner.addClientPopUp.lastName')}
                />
              </Form.Item>

              <Form.Item
                label={i18n.t('partner.addClientPopUp.email')}
                name="email"
                normalize={(value) => value.trim().toLowerCase()}
                rules={[
                  {
                    required: true,
                    message: i18n.t('welcome.formErrors.common.required'),
                  },
                  {
                    message: i18n.t('errors.wrongEmailFormatShort'),
                    pattern: REGULAR_EXPRESSIONS.EMAIL,
                  },
                  {
                    validator: (_, value) =>
                      !value.startsWith('www.')
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              i18n.t(
                                'setup.company.website.errorStartWWWAtEmail'
                              )
                            )
                          ),
                  },
                ]}>
                <Input
                  size="large"
                  type="text"
                  placeholder={i18n.t(
                    'partner.addClientPopUp.emailPlaceholder'
                  )}
                />
              </Form.Item>

              <Form.Item
                label={i18n.t(
                  'partner.collaborators.addCollaboratorPopUp.type'
                )}
                name="type"
                rules={[
                  {
                    required: true,
                    message: i18n.t('welcome.formErrors.common.required'),
                  },
                ]}>
                <Radio.Group
                  block
                  options={COLLABORATOR_TYPES.map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  optionType="button"
                />
              </Form.Item>
            </div>

            <div className="modal-buttons">
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="submit"
                    size="full"
                    color="bluishGrey"
                    text={
                      loading ? (
                        <SpinnerText text={i18n.t('common.loading')} />
                      ) : (
                        i18n.t(
                          'partner.collaborators.addCollaboratorPopUp.createNewCollaborator'
                        )
                      )
                    }
                    onClick={async () => {
                      await form.validateFields();
                    }}
                    disabled={isButtonDisabled()}
                  />
                )}
              </Form.Item>
              <Button
                size="full"
                color="white"
                text={i18n.t('common.cancel')}
                onClick={() => dispatch(showPopUp(null))}
              />
            </div>
          </Form>
        </div>
      </SCAddCollaboratorFromPartner>
    </ModalLayout>
  );
};

export default AddCollaboratorFromPartner;
