import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SCProfileInfo from './ProfileInfo.style';
import YourInformationCard from '../YourInformationCard/YourInformationCard';
import MySessionCard from '../MySessionCard/MySessionCard';
import YourInterfaceInformationCard from '../YourInterfaceInformationCard/YourInterfaceInformationCard';
import SectionLayout from '../../layouts/SectionLayout/SectionLayout';
import SectionHeader from '../../molecules/SectionHeader/SectionHeader';
import YourPaymentMethodInformationCard from '../YourPaymentMethodInformationCard/YourPaymentMethodInformationCard';
import DeleteAccountCard from '../DeleteAccountCard/DeleteAccountCard';
import { isNonCountryEnvironment } from '../../../utils/locale';
import { PARTNER_TYPES } from '../../../utils/constants/partners';
import BillingInformationCard from '../BillingInformationCard/BillingInformationCard';
import usePartnerConfig from '../../../hooks/usePartnerConfig/usePartnerConfig';
import { EUROPEAN_COUNTRIES } from '../../../utils/internationalizationModules/countries/europe';

const ProfileInfo = () => {
  const client = useSelector((redux) => redux.client);

  const { isPartnerType } = usePartnerConfig();
  const canSeeAllConfig = !isPartnerType([
    PARTNER_TYPES.MSSP,
    PARTNER_TYPES.RESELLER,
  ]);

  const { state } = useLocation();
  const { targetId } = state || {};

  useEffect(() => {
    const el = document.getElementById(targetId);

    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return (
    <SectionLayout>
      <SCProfileInfo>
        <div className="section-organism">
          <SectionHeader />
        </div>

        <div className="section-organism">
          <YourInformationCard />
        </div>

        {canSeeAllConfig && !isNonCountryEnvironment && (
          <div className="section-organism">
            <BillingInformationCard isShownInPopUp={false} />
          </div>
        )}

        {Boolean(client?.customerId) &&
          !isNonCountryEnvironment &&
          client?.billingAddress?.country === EUROPEAN_COUNTRIES.ES.code &&
          canSeeAllConfig && (
            <div className="section-organism" id="payment-method-card">
              <YourPaymentMethodInformationCard />
            </div>
          )}

        <div className="section-organism">
          <YourInterfaceInformationCard />
        </div>

        <div className="section-organism">
          <MySessionCard />
        </div>

        {canSeeAllConfig && <DeleteAccountCard />}
      </SCProfileInfo>
    </SectionLayout>
  );
};

export default ProfileInfo;
