import api from '../config';
import { CAMPAIGNS_API } from '../paths';

const createTestCampaign = (campaign) => {
  return api.post(`${CAMPAIGNS_API}`, campaign);
};

const createCampaign = (campaign) => {
  return api.post(`${CAMPAIGNS_API}/client`, campaign);
};

const deleteCampaign = (id) => {
  return api.delete(`${CAMPAIGNS_API}/client/campaign/${id}`);
};

export default { createTestCampaign, createCampaign, deleteCampaign };
