import styled from '@emotion/styled';

const SCBillingInformationCard = styled.div`
  .profile-billing-info-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    row-gap: 15px;
    justify-content: space-between;
  }

  .profile-billing-info-grid-column {
    display: flex;
    flex-direction: column;
    width: 45%;
    gap: 20px;

    select:disabled {
      cursor: inherit;
    }
  }

  .profile-billing-info-grid > div svg {
    margin-right: 10px;
  }

  .buttons-form-container {
    margin-top: 40px;
    display: flex;
    gap: 20px;
  }
`;

export default SCBillingInformationCard;
