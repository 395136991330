import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import {
  faEnvelope,
  faUser,
  faUserGear,
  faUserShield,
} from '@fortawesome/pro-regular-svg-icons';
import { Form, Input as InputAntd, Select as SelectAntd } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CryptoJS from 'crypto-js';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Button from '../../atoms/Button/Button';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import { TEAM_MEMBERS_ROLES } from '../../../utils/constants/teamMembers';
import { REGULAR_EXPRESSIONS } from '../../../utils/constants/regularExpressions';
import clientsService from '../../../api/services/clients.service';
import { handleErrorPopUp } from '../../../utils/constants/errors';
import securityScanService from '../../../api/services/securityScan.service';

const AddUserInEmailListPopUpContainer = styled.div`
  .phishing-paragraph {
    font-size: 14px;
    margin-bottom: 40px;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .ant-input-affix-wrapper .ant-input-prefix {
    margin-inline-end: 8px;
  }
`;

const InputIcon = ({ icon }) => (
  <FontAwesomeIcon icon={icon} color="var(--bluish-grey)" />
);

const AddUserInEmailList = ({ isAdd, user }) => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const client = useSelector((redux) => redux.client);
  const userAccount = useSelector((redux) => redux.user);
  const teamMembers = useSelector((redux) => redux.teamMembers);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const emailRegEx = new RegExp(REGULAR_EXPRESSIONS.EMAIL);

  const isAddI18n = isAdd ? 'add' : 'update';

  const ROLE_SELECT_OPTIONS = [
    {
      value: TEAM_MEMBERS_ROLES.EMPLOYEE,
      label: i18n.t(`manageEmailList.add.rolEmployee`),
      icon: faUserGear,
    },
    {
      value: TEAM_MEMBERS_ROLES.ADMIN,
      label: i18n.t(`manageEmailList.add.rolAdmin`),
      icon: faUserShield,
    },
  ];

  const isButtonDisabled = () => {
    const inputsAreEmpty = Object.values(form.getFieldsValue()).some(
      (data) => !data
    );

    const hasError = form.getFieldsError().some(({ errors }) => errors.length);

    return inputsAreEmpty || hasError || loading;
  };

  const saveTeamMember = (shouldGoToTag = false) => {
    const { firstName, lastName, email, role } = form.getFieldsValue();

    if (
      email.toLowerCase().split('@')[1] !== client.atEmail &&
      (!client || !client?.status?.serviceLevel.features.allowDifferentEmailAt)
    ) {
      dispatch(
        showPopUp('notification', {
          notificationType: 'warning',
          title: i18n.t('common.warning'),
          text: i18n.t('manageEmailList.error.emailNotMatchWithDomain', {
            domain: `@${client.atEmail}`,
          }),
          closeButtonText: i18n.t('manageEmailList.error.cancel'),
          additionalButtonText: i18n.t('manageEmailList.error.request'),
          onButtonClick: () => {
            axios
              .post('/send_email_form', {
                subject: 'Request adding third party domains',
                companyEmail: userAccount.email,
                message: `${userAccount.firstName} ${userAccount.lastName} from ${client.company.name} (with client id: ${client.id}) has requested adding third party domains.`,
              })
              .then(() => {
                dispatch(showPopUp(null));
                dispatch(
                  showPopUp('notification', {
                    notificationType: 'success',
                    title: '',
                    text: i18n.t('manageEmailList.error.successfulRequest'),
                  })
                );
              })
              .catch((error) => {
                console.error(error);
                dispatch(
                  showPopUp('notification', {
                    notificationType: 'error',
                    title: i18n.t('common.error'),
                    text: i18n.t('common.errorTryLater'),
                  })
                );
              });
          },
        })
      );
      return;
    }

    const numAdmins = teamMembers.filter((item) => item?.role?.admin).length;

    const teamMemberData = {
      firstName,
      lastName,
      role,
    };

    if (isAdd) {
      const numTeamMembers = teamMembers.filter(
        (item) => item?.role?.admin === false
      ).length;

      if (
        role === TEAM_MEMBERS_ROLES.ADMIN &&
        numAdmins >= client?.status?.serviceLevel.limits.maxAdmins
      ) {
        dispatch(showPopUp('maxAdminsInEmailList'));
        return;
      }

      if (
        role !== TEAM_MEMBERS_ROLES.ADMIN &&
        numTeamMembers >= client?.status?.serviceLevel.limits.maxTeamMembers
      ) {
        dispatch(showPopUp('maxUsersInEmailList'));
        return;
      }

      setLoading(true);

      const addTeamMemberData = {
        email: email.toLowerCase(),
        language: i18n.getLanguage(),
        ...teamMemberData,
      };

      clientsService
        .addTeamMember(addTeamMemberData)
        .then(() => {
          securityScanService.runSecurityScan({
            atEmail: client.atEmail,
            emails: [addTeamMemberData.email],
          });

          dispatch(showPopUp(null));

          if (shouldGoToTag) {
            const hashedEmail = CryptoJS.SHA224(
              addTeamMemberData.email
            ).toString(CryptoJS.enc.Hex);

            dispatch(showPopUp('tagPicker', [hashedEmail]));
          }
        })
        .catch((error) => {
          const errorCode = error.response?.data?.error;
          handleErrorPopUp(errorCode);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (
        role === TEAM_MEMBERS_ROLES.ADMIN &&
        numAdmins >= client?.status?.serviceLevel.limits.maxAdmins
      ) {
        dispatch(showPopUp('maxAdminsInEmailList'));
        return;
      }

      setLoading(true);

      clientsService
        .editTeamMember(user.hashedEmail, teamMemberData)
        .then(() => {
          dispatch(showPopUp(null));
        })
        .catch((error) => {
          const errorCode = error.response?.data?.error;
          handleErrorPopUp(errorCode);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const isOwner = user?.role?.owner;

  return (
    <ModalLayout>
      <AddUserInEmailListPopUpContainer>
        <h1>{i18n.t(`manageEmailList.${isAddI18n}.title`)}</h1>

        <div className="modal-card-content">
          <Form
            form={form}
            layout="vertical"
            initialValues={
              !isAdd && user
                ? {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    role: user.role.admin
                      ? TEAM_MEMBERS_ROLES.ADMIN
                      : TEAM_MEMBERS_ROLES.EMPLOYEE,
                  }
                : { role: TEAM_MEMBERS_ROLES.EMPLOYEE }
            }>
            <Form.Item
              label={i18n.t(`manageEmailList.add.firstName`)}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: i18n.t('welcome.formErrors.common.required'),
                },
              ]}>
              <InputAntd
                size="large"
                type="text"
                prefix={<InputIcon icon={faUser} />}
                placeholder={i18n.t(`manageEmailList.add.firstName`)}
              />
            </Form.Item>

            <Form.Item
              label={i18n.t(`manageEmailList.add.lastName`)}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: i18n.t('welcome.formErrors.common.required'),
                },
              ]}>
              <InputAntd
                size="large"
                type="text"
                prefix={<InputIcon icon={faUser} />}
                placeholder={i18n.t(`manageEmailList.add.lastName`)}
              />
            </Form.Item>

            <Form.Item
              label={i18n.t(`manageEmailList.add.email`)}
              name="email"
              rules={[
                {
                  required: true,
                  message: i18n.t('welcome.formErrors.common.required'),
                },
                {
                  message: i18n.t('errors.wrongEmailFormatShort'),
                  pattern: emailRegEx,
                },
              ]}>
              <InputAntd
                size="large"
                type="text"
                prefix={<InputIcon icon={faEnvelope} />}
                placeholder={i18n.t(`manageEmailList.add.email`)}
                disabled={!isAdd}
              />
            </Form.Item>

            <Form.Item
              label={i18n.t('manageEmailList.add.role')}
              name="role"
              rules={[
                {
                  required: true,
                  message: i18n.t('welcome.formErrors.common.required'),
                },
              ]}>
              <SelectAntd
                size="large"
                options={ROLE_SELECT_OPTIONS.map(({ value, label, icon }) => ({
                  value,
                  label: (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}>
                      <InputIcon icon={icon} />
                      {label}
                    </div>
                  ),
                }))}
                disabled={isOwner}
              />
            </Form.Item>

            {isAdd && (
              <p className="phishing-paragraph">
                {i18n.t('manageEmailList.add.note')}
              </p>
            )}

            <div className="buttons-container">
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="submit"
                    size="full"
                    color="bluishGrey"
                    text={
                      loading ? (
                        <SpinnerText text={i18n.t('misc.saving')} />
                      ) : (
                        i18n.t(`manageEmailList.${isAddI18n}.confirm`)
                      )
                    }
                    onClick={() => {
                      saveTeamMember(true);
                    }}
                    disabled={isButtonDisabled()}
                  />
                )}
              </Form.Item>

              {isAdd ? (
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      type="submit"
                      size="full"
                      color="white"
                      text={
                        loading ? (
                          <SpinnerText text={i18n.t('misc.saving')} />
                        ) : (
                          i18n.t(
                            `manageEmailList.${isAddI18n}.confirmWithoutTags`
                          )
                        )
                      }
                      onClick={() => {
                        saveTeamMember(false);
                      }}
                      disabled={isButtonDisabled()}
                    />
                  )}
                </Form.Item>
              ) : (
                <Button
                  text={i18n.t('manageEmailList.removeUser')}
                  onClick={() =>
                    dispatch(showPopUp('removeEmail', user.hashedEmail))
                  }
                  size="full"
                  color="white"
                  disabled={userAccount.email === user.email || isOwner}
                />
              )}

              <Button
                text={i18n.t('websiteSecurity.add.cancel')}
                onClick={() => dispatch(showPopUp(null))}
                size="full"
                color="white"
              />
            </div>
          </Form>
        </div>
      </AddUserInEmailListPopUpContainer>
    </ModalLayout>
  );
};

export default AddUserInEmailList;
