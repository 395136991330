import styled from '@emotion/styled';

const SCNavbar = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 30px;
  height: var(--navbar-height);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;

  > svg {
    max-width: 160px;
    cursor: pointer;
  }

  @media (width < 850px) {
    backdrop-filter: blur(50px);
  }
`;

export default SCNavbar;
