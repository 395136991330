import styled from '@emotion/styled/macro';
import React from 'react';
import { useTranslation } from '../../../i18n';

import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import BillingInformationForm from '../../molecules/BillingInformationForm/BillingInformationForm';

const SCBillingInformationPopUp = styled.div`
  text-align: left !important;

  h1 {
    text-align: center !important;
  }
`;

const BillingInformationPopUp = () => {
  const i18n = useTranslation();

  return (
    <ModalLayout>
      <SCBillingInformationPopUp>
        <h1>{i18n.t('profile.addBillingInformationPopUp.title')}</h1>
        <div className="AddBillingInformation_ContentContainer">
          <BillingInformationForm isShownInPopUp />
        </div>
      </SCBillingInformationPopUp>
    </ModalLayout>
  );
};

export default BillingInformationPopUp;
