import React from 'react';
import SCKPICard from './KPICard.style';
import LastUpdateText from '../../atoms/LastUpdateText/LastUpdateText';
import KPI from '../KPI/KPI';
import SectionCard from '../SectionCard/SectionCard';

const KPICard = ({
  value,
  total,
  title,
  icon,
  iconBackgroundColor,
  loading,
  lastUpdateAt,
  extraContent,
  className,
}) => {
  return (
    <SectionCard className={className}>
      <SCKPICard>
        <KPI
          value={value}
          total={total}
          title={title}
          icon={icon}
          loading={loading}
          iconBackgroundColor={iconBackgroundColor}
        />

        {!loading && (
          <>
            {extraContent}

            {lastUpdateAt && <LastUpdateText date={lastUpdateAt} />}
          </>
        )}
      </SCKPICard>
    </SectionCard>
  );
};

export default KPICard;
