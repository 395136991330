import React, { useState } from 'react';
import {
  faCirclePlus,
  faFileUpload,
  faList,
  faTags,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import SCProfileEmployeesFilterCard from './ProfileEmployeesFilterCard.style';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import { finishTutorial } from '../../../redux/actions/client.actions';
import TagList from '../../molecules/TagList/TagList';
import Tooltip from '../../atoms/Tooltip/Tooltip';
import Icon from '../../atoms/Icon/Icon';

const ProfileEmployeesFilterCard = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const teamMembers = useSelector((redux) => redux.teamMembers);
  const client = useSelector((redux) => redux.client);

  const [selectedHashedEmails, setSelectedHashedEmails] = useState([]);

  const deleteTeamMembers = () => {
    const selectedteamMembers = selectedHashedEmails.map(
      (selectedHashedEmail) => {
        const selectedTeamMember = teamMembers?.find(
          (teamMember) => teamMember.hashedEmail === selectedHashedEmail
        );
        return selectedTeamMember;
      }
    );

    dispatch(showPopUp('removeEmails', selectedteamMembers));
  };

  const getHashedEmailsFromRows = (selectedRows) => {
    return selectedRows.map(
      (selectedRow) =>
        teamMembers?.find(
          (teamMember) => teamMember.email === selectedRow.email.value
        ).hashedEmail
    );
  };

  const mapperFn = (item) => {
    return {
      name: {
        type: 'component',
        value: (
          <Tooltip
            tooltip={
              <p>
                {item.firstName} {item.lastName}
              </p>
            }>
            <p>
              {item.firstName} {item.lastName}
            </p>
          </Tooltip>
        ),
        sortValue: `${item.firstName} ${item.lastName}`,
      },
      email: {
        type: 'string',
        value: item.email,
        sortValue: item.email,
      },
      role: {
        type: 'component',
        value: <TagList teamMember={item} cell />,
      },
      onClick: () => {
        // set session storage with selectedHashedEmails
        dispatch(showPopUp('editUserInEmailList', item));
      },
    };
  };

  const PAGE_SIZE = 10;

  const columns = [
    {
      name: i18n.t(`manageEmailList.name`),
      options: {
        stickyOnScroll: true,
        width: 200,
        stickyOffset: 40,
        ellipsisContent: true,
        ofuscateFS: true,
      },
    },
    {
      name: i18n.t(`manageEmailList.email`),
      options: {
        ofuscateFS: true,
      },
    },
    {
      name: i18n.t(`manageEmailList.tags`),
      options: {
        avoidSort: true,
      },
    },
  ];

  const defaultSortDirection = undefined;
  const sortOptions = {
    activeSortedColumnName: i18n.t(`manageEmailList.name`),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 0,
    onSort: (data) => {
      setSort(data);
    },
  };

  const {
    currentPage,
    setSearch,
    setSort,
    setSearchTags,
    setPage,
    rows,
    length,
  } = useFilterTable(
    PAGE_SIZE,
    mapperFn,
    teamMembers,
    ['firstName', 'lastName', 'email'],
    sortOptions
  );

  const { totalEmployees, totalAdmins } = teamMembers?.reduce(
    (acc, { role }) => {
      if (role?.admin) {
        acc.totalAdmins += 1;
      } else {
        acc.totalEmployees += 1;
      }

      return acc;
    },
    { totalEmployees: 0, totalAdmins: 0 }
  );

  const { maxTeamMembers, maxAdmins } = client?.status?.serviceLevel.limits;

  const title = i18n.t('manageEmailList.titleTotal', {
    employees: totalEmployees,
    employeesTotal: maxTeamMembers,
    admins: totalAdmins,
    adminsTotal: maxAdmins,
  });

  return (
    <SCProfileEmployeesFilterCard>
      <SectionCard headerIcon={faList} hasOverflow headerTitle={title}>
        <div className="employees-list-container">
          {!client?.finishedTutorials?.personalDataAcknowledgment && (
            <InfoBanner
              type="warn"
              text={[i18n.t('profile.employeesWarn')]}
              onClickButton={() => {
                dispatch(finishTutorial('personalDataAcknowledgment'));
              }}
              buttonText={i18n.t('manageEmailList.acknowledged')}
            />
          )}

          <p>{i18n.t('profile.employeesInfo')}</p>
          <TableContainer
            size="customMid"
            onChangeValue={setSearch}
            columns={columns}
            rows={rows}
            emptyMessage={i18n.t('manageEmailList.emptyMessage')}
            hasRowIcon
            hasRowSelect
            onChangeFilterTag={setSearchTags}
            hasHorizontalScroll
            rowIdentifier="email"
            rowHasBeenSelected={(selectedRows) => {
              setSelectedHashedEmails(getHashedEmailsFromRows(selectedRows));
            }}
            onPageChange={(page) => setPage(page)}
            totalCount={length}
            currentPage={currentPage}
            sortOptions={sortOptions}
            pageSize={PAGE_SIZE}
            customSideElements={
              <div className="ProfileEmployeesFilterCard_Actions">
                <Button
                  type="button"
                  color="white"
                  size="customMid"
                  icon={faCirclePlus}
                  text={i18n.t('manageEmailList.add.title')}
                  onClick={() => dispatch(showPopUp('addUserInEmailList'))}
                />
                <Button
                  type="button"
                  color="white"
                  size="customMid"
                  icon={faFileUpload}
                  text={i18n.t('manageEmailList.addWithCSV')}
                  onClick={() => dispatch(showPopUp('addCSVUserInEmailList'))}
                />
              </div>
            }
            customBottomElements={
              <div className="ProfileEmployeesFilterCard_SelectedRowActions">
                <>
                  <div className="ProfileEmployeesFilterCard_SelectedRowActionsInfo">
                    <Icon iconName={faCircleInfo} color="var(--bluish-grey)" />
                    {selectedHashedEmails.length === 0 && (
                      <p>{i18n.t('manageEmailList.selectEmployees')}</p>
                    )}
                    {selectedHashedEmails.length > 0 && (
                      <p>
                        {i18n.t('manageEmailList.selectedEmployees', {
                          numberOfUsers: `${selectedHashedEmails.length}`,
                        })}
                      </p>
                    )}
                  </div>
                  {selectedHashedEmails.length > 0 && (
                    <div className="ProfileEmployeesFilterCard_SelectedRowActionsAction">
                      <Button
                        color="white"
                        size="customMid"
                        icon={faTags}
                        text={i18n.t('manageEmailList.tagAssign')}
                        onClick={() =>
                          dispatch(showPopUp('tagPicker', selectedHashedEmails))
                        }
                        disabled={selectedHashedEmails.length === 0}
                      />
                      <Button
                        color="white"
                        size="customMid"
                        icon={faTrashCan}
                        text={i18n.t('manageEmailList.deleteUsers')}
                        onClick={() => {
                          deleteTeamMembers();
                        }}
                        disabled={selectedHashedEmails.length === 0}
                      />
                    </div>
                  )}
                </>
              </div>
            }
          />
        </div>
      </SectionCard>
    </SCProfileEmployeesFilterCard>
  );
};

export default ProfileEmployeesFilterCard;
