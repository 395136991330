import styled from '@emotion/styled';

const SCSetUpAccount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  width: fit-content;
  text-align: center;
  background-color: var(--white);
  border-radius: var(--section-radius);
  padding: 40px;
  width: 100%;
  max-width: 420px;
  box-shadow: 2px 2px 20px #79889714;

  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 17px;

    .name-input {
      display: flex;
      gap: 10px;
    }
  }

  a {
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
  }

  .google-button {
    background-color: var(--white);
  }

  .NewSignUp_RegisterButton {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .terms-and-conditions-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      font-size: 12px;
      font-family: var(--font1);
      color: var(--bluish-grey);
      line-height: 18px;
      text-align: left;
      font-weight: 500;
    }

    a {
      font-size: inherit;
      line-height: inherit;
    }
  }
`;

export const PopperContainer = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: white;
  padding: 20px;
  text-align: left;
  width: 280px;
  z-index: 100;
  font-size: 14px;

  h2 {
    color: var(--black);
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 16px;
  }

  .success-regex {
    color: #75b531;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
    max-width: 100vw;
  }
`;

export default SCSetUpAccount;
