import en_GB from 'antd/locale/en_US';
import es_ES from 'antd/locale/es_ES';
import pt_BR from 'antd/locale/pt_BR';
import { isUserInTeamMembers } from './functions/teamMembers';
import { auth } from '../firebase';

export const LOCALES = {
  eu: 'eu',
  am: 'am',
  seu: 'seu',
  sam: 'sam',
};

const SUBDOMAIN_LOCALE = {
  'es-staging': LOCALES.eu,
  'es-testing': LOCALES.eu,
  'es-prod': LOCALES.eu,
  'am-prod': LOCALES.am,
  'seu-prod': LOCALES.seu,
  'sam-prod': LOCALES.sam,
};

const DEFAULT_LOCALE = LOCALES.eu;

export const CURRENT_LOCALE =
  SUBDOMAIN_LOCALE[process.env.REACT_APP_ENVIRONMENT] ?? DEFAULT_LOCALE;

const SANTANDER_LOCALES = [LOCALES.sam, LOCALES.seu];

const NON_COUNTRY_LOCALES = [...SANTANDER_LOCALES, LOCALES.am];

const AVAILABLE_INTERNATIONALIZED_IMG_LANGUAGES = ['es', 'en'];

export const getInternationalizedImgLang = (desiredLang) =>
  AVAILABLE_INTERNATIONALIZED_IMG_LANGUAGES.includes(desiredLang)
    ? desiredLang
    : 'en';

export const isSantanderEnvironment =
  SANTANDER_LOCALES.includes(CURRENT_LOCALE);

export const isNonCountryEnvironment =
  NON_COUNTRY_LOCALES.includes(CURRENT_LOCALE);

export const getSantanderEnvironmentRegionText = () => {
  const santanderLocalesTexts = {
    [LOCALES.seu]: 'EU',
    [LOCALES.sam]: 'AM',
  };

  return `Cyber Guardian Santander ${
    santanderLocalesTexts[CURRENT_LOCALE] ?? ''
  }`;
};

export const getCurrentAntDLocale = (lang) => {
  const DEFAULT_ANTD_LOCALE = es_ES;
  const ANTD_LOCALES = {
    es: es_ES,
    en: en_GB,
    'pt-BR': pt_BR,
  };

  return ANTD_LOCALES[lang] || DEFAULT_ANTD_LOCALE;
};

export const getDateFormatByLanguage = (lang) => {
  const DateFormatsByLanguage = {
    en: 'MM/DD/YYYY',
  };

  return DateFormatsByLanguage[lang] ?? 'DD/MM/YYYY';
};

export const isCallMeAvailable = (pathname) => {
  const ROUTES_WITHOUT_CALL_ME_WIDGET = ['/partner', '/admin', '/gerencia'];

  const isNotRestrictedRoute = !ROUTES_WITHOUT_CALL_ME_WIDGET.some((route) =>
    pathname.startsWith(route)
  );

  return (
    isNotRestrictedRoute &&
    !isNonCountryEnvironment &&
    (isUserInTeamMembers() || !auth?.currentUser)
  );
};

export const isTawkToAvailable = (pathname) => {
  const ROUTES_WITHOUT_TAWKTO_WIDGET = ['/admin', '/gerencia'];

  const isNotRestrictedRoute = !ROUTES_WITHOUT_TAWKTO_WIDGET.some((route) =>
    pathname.startsWith(route)
  );

  return isNotRestrictedRoute;
};
