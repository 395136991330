import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import clientsService from '../../api/services/clients.service';

const useGetPartnerStatistics = ({ filter, partnerId }) => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const [partnerStatistics, setPartnerStatistics] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPartnerStatistics = async () => {
      setLoading(true);

      try {
        const resp = await clientsService.getPartnerStatistics({
          filter,
          partnerId,
        });

        setPartnerStatistics(resp?.data?.data);
      } catch (e) {
        dispatch(
          showPopUp('notification', {
            notificationType: 'error',
            title: i18n.t('common.error'),
            text: i18n.t('errors.not_found'),
            closeButtonText: i18n.t('common.close'),
          })
        );
      } finally {
        setLoading(false);
      }
    };

    fetchPartnerStatistics();
  }, [filter, partnerId]);

  return {
    partnerStatistics,
    loading,
  };
};

export default useGetPartnerStatistics;
