import api from '../config';
import { SUBSCRIPTIONS_API } from '../paths';

const exportPartnerClientSubscriptions = ({ partnerId }) => {
  return api.get(`${SUBSCRIPTIONS_API}/partners/licenses/export`, {
    responseType: 'blob',
    params: {
      partnerId,
    },
  });
};

const getPartnerClientSubscription = (clientId) => {
  return api.get(`${SUBSCRIPTIONS_API}/partners/subscriptions/${clientId}`);
};

const addLicenses = ({ clientId, licenses }) => {
  return api.post(`${SUBSCRIPTIONS_API}/partners/extend`, {
    clientId,
    licenses,
  });
};

const extendedSubscription = ({ clientId, nbLicenses }) => {
  return api.post(`${SUBSCRIPTIONS_API}/partners/renewal`, {
    clientId,
    nbLicenses,
  });
};

export default {
  exportPartnerClientSubscriptions,
  getPartnerClientSubscription,
  addLicenses,
  extendedSubscription,
};
