import React from 'react';
import {
  // faEnvelope,
  faPaperclipVertical,
  faTrashCan,
  faInbox,
} from '@fortawesome/pro-regular-svg-icons';
import { faRadiation } from '@fortawesome/pro-solid-svg-icons';

import { useNavigate } from 'react-router-dom';
import ThreatsNeutralized from '../../molecules/ThreatsNeutralized/ThreatsNeutralized';
import Button from '../../atoms/Button/Button';
import { COUNTER_TYPES } from '../../../hooks/useManagedThreatCounters/useManagedThreatCounters';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import EmptyCardContent from '../../molecules/EmptyCardContent/EmptyCardContent';
import useLicenses from '../../../hooks/licenses/useLicenses';
import { hasEmailProtection } from '../../../utils/functions/emailProtection';
import useFetchQuarantineEmails from '../../../hooks/emailProtection/useFetchQuarantineEmails';
import { PARTNER_TYPES } from '../../../utils/constants/partners';
import usePartnerConfig from '../../../hooks/usePartnerConfig/usePartnerConfig';

const ThreatsNeutralizedMail = ({ counters }) => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const { tableParams } = useFetchQuarantineEmails();
  const { total } = tableParams?.pagination ?? {};

  const { emailProtection = 0 } = useLicenses().licenses;
  const { isPartnerType } = usePartnerConfig();

  const canContactProvider = isPartnerType([
    PARTNER_TYPES.MSSP,
    PARTNER_TYPES.RESELLER,
  ]);
  const isEmailProtectionActive = hasEmailProtection();
  const hasQuarantineEmails = Boolean(total);

  const THREATS_NEUTRALIZED_MAIL = [
    {
      iconName: faPaperclipVertical,
      amount: counters[COUNTER_TYPES.EMAIL_PROTECTION_FILES_ANALYZED],
      description: i18n.t(
        'managed-threats.widgets.email-threats-neutralized.labels.attachedAnalyzed'
      ),
    },
    {
      iconName: faRadiation,
      amount: counters[COUNTER_TYPES.EMAIL_PROTECTION_EMAILS_IN_QUARANTINE],
      description: i18n.t(
        'managed-threats.widgets.email-threats-neutralized.labels.quarantineEmails'
      ),
      isHighlighted:
        !!counters[COUNTER_TYPES.EMAIL_PROTECTION_EMAILS_IN_QUARANTINE],
    },
    {
      iconName: faTrashCan,
      amount: counters[COUNTER_TYPES.EMAIL_PROTECTION_MALICIOUS_EMAILS_DELETED],
      description: i18n.t(
        'managed-threats.widgets.email-threats-neutralized.labels.removedEmails'
      ),
    },
  ];

  return (
    <SectionCard
      headerTitle={i18n.t(
        'managed-threats.widgets.email-threats-neutralized.title'
      )}
      headerIcon={faInbox}>
      {emailProtection > 0 && isEmailProtectionActive && (
        <ThreatsNeutralized
          displaySubtitle={hasQuarantineEmails}
          subtitle={i18n.t(
            'managed-threats.widgets.email-threats-neutralized.subtitle'
          )}
          isSubtitleHighlighted={hasQuarantineEmails}
          threatsCounters={THREATS_NEUTRALIZED_MAIL}
          button={
            <Button
              text={i18n.t(
                'managed-threats.widgets.email-threats-neutralized.actions.reviewQuarantineEmails'
              )}
              size="full"
              color="white"
              onClick={() => navigate('/client/mailbox-security')}
            />
          }
        />
      )}

      {emailProtection > 0 && !isEmailProtectionActive && (
        <EmptyCardContent
          text={i18n.t(
            'managed-threats.widgets.email-threats-neutralized.emptyCard.activeMailboxSecurityText'
          )}
          buttonText={i18n.t(
            'managed-threats.widgets.email-threats-neutralized.emptyCard.activeMailboxSecurityButton'
          )}
          buttonOnClick={() => navigate('/client/mailbox-security')}
        />
      )}

      {emailProtection === 0 && canContactProvider && (
        <EmptyCardContent text={i18n.t('common.noLicensesContactProvider')} />
      )}

      {emailProtection === 0 && !canContactProvider && (
        <EmptyCardContent
          text={i18n.t('common.noLicensesContactUs')}
          buttonText={i18n.t('common.contact')}
          buttonOnClick={() => Tawk_API.toggle()}
        />
      )}
    </SectionCard>
  );
};

export default ThreatsNeutralizedMail;
