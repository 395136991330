import { useTranslation } from '../../../i18n';
import { LOCALE_COUNTRIES } from '../countries/countries';

export const getPhonePrefixOptions = () => {
  const i18n = useTranslation();

  return Object.values(LOCALE_COUNTRIES).map(({ code, phonePrefix, flag }) => ({
    value: code,
    label: `+${phonePrefix}`,
    flag,
    country: i18n.t(`countries.${code}`),
  }));
};

export const getFullPhoneNumber = (prefix, phoneNumber) => {
  return `+${prefix}${phoneNumber}`;
};
