import { useEffect, useState } from 'react';
import subscriptionsService from '../../api/services/subscriptions.service';

const usePartnerClientSubscription = ({ clientId }) => {
  const [subscription, setSubscription] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchPartnerClientSubscription = async () => {
    if (clientId) {
      try {
        const resp = await subscriptionsService.getPartnerClientSubscription(
          clientId
        );
        setSubscription(resp.data?.data);
      } catch (e) {
        setError(e);
      }
    }
  };

  useEffect(() => {
    if (clientId) {
      setLoading(true);
      setError(false);

      fetchPartnerClientSubscription().finally(() => {
        setLoading(false);
      });
    }
  }, [clientId]);

  return {
    subscription,
    loading,
    error,
    fetchPartnerClientSubscription,
  };
};

export default usePartnerClientSubscription;
