// Los códigos de país siguen el ISO 3166-1 alfa-2
// https://www.mjusticia.gob.es/es/Ciudadano/Registros/Documents/1292428778575-CODIGOS_ISO_3166_1.PDF
export const AMERICAN_COUNTRIES = {
  US: {
    code: 'US',
    phonePrefix: '1',
    flag: '🇺🇸',
    phoneLength: [10],
  },
  MX: {
    code: 'MX',
    phonePrefix: '52',
    flag: '🇲🇽',
    phoneLength: [10, 11],
  },
  BR: {
    code: 'BR',
    phonePrefix: '55',
    flag: '🇧🇷',
    phoneLength: [10, 11],
  },
  UY: {
    code: 'UY',
    phonePrefix: '598',
    flag: '🇺🇾',
    phoneLength: [8],
  },
  AR: {
    code: 'AR',
    phonePrefix: '54',
    flag: '🇦🇷',
    phoneLength: [10, 11, 12, 13, 14],
  },
};
