import { SET_PARTNER_CONFIG } from './types';
import clientsService from '../../api/services/clients.service';

const setPartnerConfig = (licensee, dispatch) => {
  clientsService.getPartnerData(licensee).then((resp) => {
    const partnerConfig = resp.data.data;

    dispatch({
      type: SET_PARTNER_CONFIG,
      payload: partnerConfig,
    });
  });
};

export default setPartnerConfig;
