import React, { useState } from 'react';
import {
  faCirclePlus,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SCPartnerCollaborators from './PartnerCollaborators.style';
import clientsService from '../../api/services/clients.service';
import SectionCard from '../../components/molecules/SectionCard/SectionCard';
import Input from '../../components/atoms/Input/Input';
import { useTranslation } from '../../i18n';
import Button from '../../components/atoms/Button/Button';
import useGetPartnerCollaborators from '../../hooks/useGetPartnerCollaborators/useGetPartnerCollaborators';
import { showPopUp } from '../../redux/actions/popUp.actions';
import { ERRORS } from '../../utils/constants/errors';
import { ErrorKey } from '../../track';

const sortDirections = ['ascend', 'descend', 'ascend'];

const PartnerCollaborators = () => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { partnerCollaborators, loading, fetchPartnerCollaborators } =
    useGetPartnerCollaborators();

  const [inputFilter, setInputFilter] = useState();

  const filteredPartnerCollaborators = inputFilter
    ? partnerCollaborators.filter(({ name }) =>
        name.toLowerCase().includes(inputFilter.toLowerCase())
      )
    : partnerCollaborators;

  const handleCreateCollaborator = async (formValues) => {
    try {
      await clientsService.addPartnerCollaborator(formValues);
      dispatch(showPopUp(null));
      fetchPartnerCollaborators();
    } catch (error) {
      const { error: errorCode } = error.response.data;

      const i18nKeys = {
        [ERRORS.INVALID_EMAIL]: 'errors.wrongEmailFormatShort',
        [ERRORS.CLIENT_ALREADY_EXIST]: 'setup.company.genericError',
        [ERRORS.MEMBER_ALREADY_EXIST]: 'errors.memberAlreadyExist',
      };

      const i18nKey = i18nKeys[errorCode] ?? 'errors.not_found';

      ErrorKey(i18nKey);
    }
  };

  const columns = [
    {
      title: i18n.t('partner.collaborators.table.name'),
      key: 'name',
      dataIndex: 'name',
      sortDirections,
      sorter: ({ name: aName }, { name: bName }) => {
        return aName.localeCompare(bName);
      },
    },
    {
      title: i18n.t('partner.collaborators.table.type'),
      key: 'type',
      dataIndex: 'type',
      sortDirections,
      sorter: ({ type: aType }, { type: bType }) => {
        return aType.localeCompare(bType);
      },
    },
    {
      title: i18n.t('partner.collaborators.table.nClients'),
      key: 'clientQuantity',
      dataIndex: 'clientQuantity',
      sortDirections,
      sorter: (a, b) => a.clientQuantity - b.clientQuantity,
    },
    {
      title: i18n.t('partner.table.headers.licenses'),
      children: [
        {
          title: i18n.t('partner.table.headers.malware'),
          key: 'malwareProtection',
          dataIndex: ['clientLicenses', 'malwareProtection'],
          sortDirections,
          sorter: (a, b) =>
            a.clientLicenses.malwareProtection -
            b.clientLicenses.malwareProtection,
        },
        {
          title: i18n.t('partner.table.headers.web'),
          key: 'webProtection',
          dataIndex: ['clientLicenses', 'webProtection'],
          sortDirections,
          sorter: (a, b) =>
            a.clientLicenses.webProtection - b.clientLicenses.webProtection,
        },
        {
          title: i18n.t('partner.table.headers.email'),
          key: 'emailProtection',
          dataIndex: ['clientLicenses', 'emailProtection'],
          sortDirections,
          sorter: (a, b) =>
            a.clientLicenses.emailProtection - b.clientLicenses.emailProtection,
        },
      ],
    },
  ];

  return (
    <SCPartnerCollaborators>
      <SectionCard>
        <div className="actions-container">
          <Input
            inputType="text"
            inputPlaceholder={`${i18n.t('common.search')}...`}
            onChangeValue={setInputFilter}
            icon={faMagnifyingGlass}
            size="short"
            design="round"
            disabled={loading}
          />

          <Button
            icon={faCirclePlus}
            text="Nuevo colaborador"
            color="bluishGrey"
            size="small"
            onClick={() =>
              dispatch(
                showPopUp(
                  'addCollaboratorFromPartner',
                  handleCreateCollaborator
                )
              )
            }
          />
        </div>

        <Table
          pagination={false}
          scroll={{ y: 560, x: 950 }}
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(`/partner/clients?collaboratorId=${record.id}`),
            };
          }}
          bordered
          rowClassName="cursor-pointer"
          columns={columns}
          dataSource={filteredPartnerCollaborators}
          loading={loading}
        />
      </SectionCard>
    </SCPartnerCollaborators>
  );
};

export default PartnerCollaborators;
