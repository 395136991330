import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import clientsService from '../../api/services/clients.service';
import { showPopUp } from '../../redux/actions/popUp.actions';
import { useTranslation } from '../../i18n';
import usePartnerConfig from '../usePartnerConfig/usePartnerConfig';

const useGetPartnerInfo = ({ clientId }) => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const { isParentPartner } = usePartnerConfig();

  const [partnerInfo, setPartnerInfo] = useState(null);
  // En caso de que se haga la petición, el valor por defecto del loading será true, sino, será false.
  const [loading, setLoading] = useState(Boolean(isParentPartner));

  useEffect(() => {
    const fetchPartnerInfo = async () => {
      setLoading(true);

      try {
        const resp = await clientsService.getPartnerInfo({ clientId });
        setPartnerInfo(resp?.data?.data);
      } catch (e) {
        dispatch(
          showPopUp('notification', {
            notificationType: 'error',
            title: i18n.t('common.error'),
            text: i18n.t('errors.not_found'),
            closeButtonText: i18n.t('common.close'),
          })
        );
      } finally {
        setLoading(false);
      }
    };

    if (isParentPartner && clientId) {
      fetchPartnerInfo();
    }
  }, [clientId]);

  return {
    partnerInfo,
    loading,
  };
};

export default useGetPartnerInfo;
