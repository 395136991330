import { ErrorKey } from '../../track';

export const ERRORS = {
  DOMAIN_ALREADY_EXISTS: 'domain_already_exists',
  MEMBER_ALREADY_EXIST: 'member_already_exist',
  INVALID_DOMAIN: 'invalid_domain',
  CLIENT_ALREADY_EXIST: 'client_already_exist',
  INVALID_EMAIL: 'invalid_email',
  ALREADY_HAS_RENEWAL: 'already_has_renewal',
  CANNOT_ADD_YOUR_DOMAINS: 'cannot_add_your_domains',
  PROVIDER_ALREADY_EXISTS: 'provider_already_exists',
  INVALID_COMPANY_NAME: 'invalid_company_name',
  TIN_INVALID: 'tin_invalid',
  INVALID_FIRSTNAME: 'invalid_firstname',
  INVALID_LASTNAME: 'invalid_lastname',
  MAX_TEAM_MEMBERS: 'max_team_members',
  MAX_ADMINS: 'max_admins',
  INVALID_ADDRESS: 'invalid_address',
  INVALID_CITY: 'invalid_city',
  INVALID_STATE: 'invalid_state',
  INVALID_POSTAL_CODE: 'invalid_postal_code',
  INVALID_PROMO_CODE: 'invalid_promo_code',
  EMAIL_ALREADY_IN_USE: 'email_already_in_use',
};

export const handleErrorPopUp = (errorCode) => {
  const i18nKeys = {
    [ERRORS.INVALID_DOMAIN]: 'websiteSecurity.add.errorDomainFormat',
    [ERRORS.INVALID_COMPANY_NAME]: 'errors.invalidCompanyName',
    [ERRORS.TIN_INVALID]: 'errors.invalidTin',
    [ERRORS.INVALID_FIRSTNAME]: 'errors.invalidFirstName',
    [ERRORS.INVALID_LASTNAME]: 'errors.invalidLastName',
    [ERRORS.MAX_TEAM_MEMBERS]: 'welcome.restrictions.maxUsersInEmailList_first',
    [ERRORS.MAX_ADMINS]: 'welcome.restrictions.maxAdminsInEmailList_first',
    [ERRORS.MEMBER_ALREADY_EXIST]: 'manageEmailList.error.memberAlreadyExists',
    [ERRORS.INVALID_EMAIL]: 'errors.wrongEmailFormatShort',
    [ERRORS.INVALID_ADDRESS]: 'errors.invalidAddress',
    [ERRORS.INVALID_CITY]: 'errors.invalidCity',
    [ERRORS.INVALID_STATE]: 'errors.invalidState',
    [ERRORS.INVALID_POSTAL_CODE]: 'errors.invalidPostalCode',
    [ERRORS.INVALID_PROMO_CODE]: 'errors.invalidPromoCode',
    [ERRORS.EMAIL_ALREADY_IN_USE]: 'errors.alreadyRegistered',
  };

  const i18nKey = i18nKeys[errorCode] ?? 'errors.not_found';

  ErrorKey(i18nKey);
};
