import React from 'react';
import { Navigate } from 'react-router-dom';
import usePartnerConfig from '../../hooks/usePartnerConfig/usePartnerConfig';
import { PARTNER_TYPES } from '../../utils/constants/partners';

const PartnerClientControlPanelGuard = ({ children }) => {
  const { isPartnerType } = usePartnerConfig();

  const canAccess = isPartnerType([PARTNER_TYPES.MSSP, PARTNER_TYPES.RESELLER]);

  return canAccess ? children : <Navigate to="/partner" />;
};

export default PartnerClientControlPanelGuard;
