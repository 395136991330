import { CURRENT_LOCALE } from '../../locale';

const COMPANY_ID_EXAMPLES = {
  es: { name: 'CIF', example: 'A1234567A' },
};

const getCompanyIDExample = () => {
  const defaultCompanyIDExample = { name: 'ID', example: '' };

  return COMPANY_ID_EXAMPLES[CURRENT_LOCALE] || defaultCompanyIDExample;
};

export default getCompanyIDExample;
