import api from '../config';
import { LEADS_API } from '../paths';
import {
  filtersToString,
  sortToString,
} from '../../utils/functions/queryParameters';

const requestContact = (data, captcha) => {
  return api.post(`${LEADS_API}/contact-request`, data, {
    headers: { captcha },
  });
};

const createLead = (data, captcha) => {
  return api.post(`${LEADS_API}/leads`, data, {
    headers: { captcha },
  });
};

const getAllLeadsOwners = () => {
  return api.get(`${LEADS_API}/owners`);
};

const getLeadsOwner = () => {
  return api.get(`${LEADS_API}/lead-owners`);
};

const getLeadsSteps = () => {
  return api.get(`${LEADS_API}/steps`);
};

const getLeadsEvents = () => {
  return api.get(`${LEADS_API}/events`);
};

const editLead = (id, data) => {
  return api.put(`${LEADS_API}/leads/${id}`, data);
};

const getLeads = ({ pagination, sorter, filters }) => {
  return api.get(`${LEADS_API}/leads`, {
    params: {
      filter: filters ? `${filtersToString(filters)}` : null,
      page: pagination.current,
      order: sortToString(sorter),
      limit: pagination.pageSize,
    },
  });
};

export default {
  requestContact,
  createLead,
  getAllLeadsOwners,
  getLeadsOwner,
  getLeadsSteps,
  getLeadsEvents,
  editLead,
  getLeads,
};
