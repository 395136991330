import React, { useEffect } from 'react';
import useSignOut from '../../hooks/useSignOut/useSignOut';

const SignOutPage = () => {
  const { signOutFromApp } = useSignOut();

  useEffect(signOutFromApp, []);

  return <></>;
};

export default SignOutPage;
