import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { firestore } from '../../firebase';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import { hasPassedMinutes } from '../../utils/functions/date';
import { MINUTES_BETWEEN_SCANS } from '../../utils/constants/scans';
import securityScanService from '../../api/services/securityScan.service';

const useRunScan = ({ domain, atEmail }) => {
  const [scanResult, setScanResult] = useState(null);
  const [isLoadingNewScan, setIsLoadingNewScan] = useState(false);
  const [isAbleToRescan, setIsAbleToRescan] = useState(false);

  const dispatch = useDispatch();
  const i18n = useTranslation();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (domain && atEmail) {
      const unsuscribe = firestore
        .collection('scans')
        .doc(domain)
        .collection('results')
        .where('email_at', '==', atEmail)
        .orderBy('timestamp', 'desc')
        .limit(1)
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc.data()) {
              const scanResultDocument = doc.data();

              const inProgress = Object.values(scanResultDocument.done).some(
                (value) => !value
              );

              setScanResult({
                ...scanResultDocument,
                inProgress,
                id: doc.id,
              });
            }
          });
        });

      return () => {
        setScanResult(null);
        unsuscribe();
      };
    }
  }, [domain, atEmail]);

  /**
   * Existe un delay entre que el back devuelve respuesta del runScan y nuestro scanResult.inProgress se actualiza.
   * Justo antes de realizar la petición establecemos el isLoading a true. Pero una vez recibida la respuesta,
   * delegamos la tarea de devolver el isLoading a false al momento en el que el scan termine.
   * Mientras el scan se realiza, isLoading seguirá siendo true.
   */
  useEffect(() => {
    setIsLoadingNewScan(Boolean(scanResult?.inProgress));
  }, [scanResult?.inProgress]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAbleToRescan(
        hasPassedMinutes(scanResult?.timestamp, MINUTES_BETWEEN_SCANS)
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [scanResult?.timestamp]);

  const handleRunScan = async () => {
    setIsLoadingNewScan(true);

    try {
      await securityScanService.runSecurityScan({
        domain,
        atEmail,
      });

      dispatch(
        showPopUp('notification', {
          notificationType: 'success',
          title: i18n.t('common.success'),
          text: i18n.t('websiteSecurity.scanIsRunning'),
        })
      );
    } catch (e) {
      dispatch(
        showPopUp('notification', {
          notificationType: 'error',
          title: i18n.t('common.error'),
          text: i18n.t('errors.not_found'),
        })
      );

      setIsLoadingNewScan(false);
    }
  };

  return {
    scanResult,
    loading: scanResult === null,
    isLoadingNewScan,
    isAbleToRescan,
    handleRunScan,
  };
};

export default useRunScan;
