import styled from '@emotion/styled';
import { Form } from 'antd';

const SCBillingInformationForm = styled(Form)`
  input,
  span {
    font-family: var(--font2) !important;
  }

  .billing-form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;

    .billing-form-item {
      display: flex;
      gap: 10px;

      .billing-form-item-icon {
        height: 16px;
        width: 16px;
        margin-top: 11px;
      }

      .billing-form-item-input {
        width: 100%;

        .ant-select-borderless .ant-select-selector {
          border: 1px solid transparent !important;
        }

        .ant-select-selector {
          border-radius: 6px !important;
        }

        .ant-input-borderless[disabled],
        .ant-select-borderless.ant-select-disabled:not(
            .ant-select-customize-input
          )
          .ant-select-selector,
        .ant-input-borderless.ant-input-disabled {
          color: var(--black);
        }
      }
    }

    .street-form-item {
      grid-column: span 2;
    }

    .billing-form-info-banner {
      grid-row-start: 2;
      grid-column: span 2;
      margin-bottom: 24px;
    }
  }

  .buttons-pop-up {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .buttons-card {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
`;

export default SCBillingInformationForm;
