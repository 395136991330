import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import Icon from '../Icon/Icon';
import { tagStyles } from '../TagsPalette/TagsPalette';
import SCTagLabel from './TagLabel.style';

const TagLabel = ({
  value = '',
  color,
  isErasable = false,
  height,
  fontSize,
}) => {
  const handleRemoveTagLabel = () => {};

  return (
    <SCTagLabel
      tagStyle={
        tagStyles.find((tagStyle) => tagStyle.id === color) ?? tagStyles[0]
      }
      height={height}
      fontSize={fontSize}>
      <p>{value}</p>

      {isErasable && (
        <button type="button" onClick={() => handleRemoveTagLabel()}>
          <Icon iconName={faXmark} />
        </button>
      )}
    </SCTagLabel>
  );
};

export default TagLabel;
