const getInstallerFAQLink = (language) => {
  const DEFAULT_LANGUAGE = 'es';

  const INSTALLER_URLS = {
    es: {
      generic:
        'https://cyberguardian.tawk.help/article/como-funciona-el-instalador-de-cyber-guardian-y-que-sistemas-operativos-soporta',
      windows:
        'https://cyberguardian.tawk.help/article/como-instalo-las-protecciones-de-cyber-guardian-en-windows',
      macOS:
        'https://cyberguardian.tawk.help/article/como-instalo-las-protecciones-de-cyber-guardian-en-mi-dispositivo-macos',
      linux:
        'https://cyberguardian.tawk.help/article/como-instalo-las-protecciones-de-cyber-guardian-en-mi-dispositivo-linux',
      android:
        'https://cyberguardian.tawk.help/article/como-instalo-las-protecciones-de-cyber-guardian-en-mi-dispositivo-android',
      ios: 'https://cyberguardian.tawk.help/article/como-instalo-las-protecciones-de-cyber-guardian-en-mi-dispositivo-ios',
    },
    en: {
      generic:
        'https://cyberguardian.tawk.help/en-us/article/how-does-the-cyber-guardian-installer-work-and-what-operating-systems-does-it-support',
      windows:
        'https://cyberguardian.tawk.help/en-us/article/how-do-i-install-cyber-guardian-protections-on-windows',
      macOS:
        'https://cyberguardian.tawk.help/en-us/article/how-do-i-install-cyber-guardian-protections-on-my-macos-device',
      linux:
        'https://cyberguardian.tawk.help/en-us/article/how-do-i-install-cyber-guardian-protections-on-my-linux-device',
      android:
        'https://cyberguardian.tawk.help/en-us/article/how-do-i-install-cyber-guardian-protections-on-my-android-device',
      ios: 'https://cyberguardian.tawk.help/en-us/article/how-do-i-install-cyber-guardian-protections-on-my-ios-device',
    },
    'pt-BR': {
      generic:
        'https://cyberguardian.tawk.help/pt-br/article/como-funciona-o-instalador-do-cyber-guardian-e-que-sistemas-operativos-suporta',
      windows:
        'https://cyberguardian.tawk.help/pt-br/article/como-instalo-as-protecaooes-cyber-guardian-no-windows',
      macOS:
        'https://cyberguardian.tawk.help/pt-br/article/como-instalo-as-protecoes-cyber-guardian-em-meu-dispositivo-macos',
      linux:
        'https://cyberguardian.tawk.help/pt-br/article/como-instalo-protecoes-cyber-guardian-no-meu-dispositivo-linux',
      android:
        'https://cyberguardian.tawk.help/pt-br/article/como-instalo-protecoes-cyber-guardian-no-meu-dispositivo-android',
      ios: 'https://cyberguardian.tawk.help/pt-br/article/como-instalo-as-protecaooes-cyber-guardian-no-meu-dispositivo-ios',
    },
  };

  return INSTALLER_URLS[language] ?? INSTALLER_URLS[DEFAULT_LANGUAGE];
};

export default getInstallerFAQLink;
