import React from 'react';
import {
  faFolderMagnifyingGlass,
  faHeartPulse,
  faShieldCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { faRadiation } from '@fortawesome/pro-solid-svg-icons';

import ThreatsNeutralized from '../../molecules/ThreatsNeutralized/ThreatsNeutralized';
import { COUNTER_TYPES } from '../../../hooks/useManagedThreatCounters/useManagedThreatCounters';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { getNumberFormat } from '../../../utils/format/dataFormat';
import useLicenses from '../../../hooks/licenses/useLicenses';
import EmptyCardContent from '../../molecules/EmptyCardContent/EmptyCardContent';
import { PARTNER_TYPES } from '../../../utils/constants/partners';
import usePartnerConfig from '../../../hooks/usePartnerConfig/usePartnerConfig';

const ThreatsNeutralizedDevices = ({ counters }) => {
  const i18n = useTranslation();
  const { malwareProtection = 0 } = useLicenses().licenses;
  const { isPartnerType } = usePartnerConfig();

  const canContactProvider = isPartnerType([
    PARTNER_TYPES.MSSP,
    PARTNER_TYPES.RESELLER,
  ]);

  const THREATS_NEUTRALIZED_DEVICES = [
    {
      iconName: faHeartPulse,
      amount: counters[COUNTER_TYPES.MALWARE_PROTECTION_TOTAL_EVENTS],
      description: i18n.t(
        'managed-threats.widgets.device-threats-neutralized.labels.eventsAnalyzed'
      ),
    },
    {
      iconName: faFolderMagnifyingGlass,
      amount: counters[COUNTER_TYPES.MALWARE_PROTECTION_FILES_ANALYZED],
      description: i18n.t(
        'managed-threats.widgets.device-threats-neutralized.labels.filesAnalyzed'
      ),
    },
    {
      iconName: faRadiation,
      amount: counters[COUNTER_TYPES.MALWARE_PROTECTION_LOW_ALERTS],
      description: i18n.t(
        'managed-threats.widgets.device-threats-neutralized.labels.lowSeverityThreats'
      ),
    },
    {
      iconName: faRadiation,
      amount: counters[COUNTER_TYPES.MALWARE_PROTECTION_HIGH_ALERTS],
      description: i18n.t(
        'managed-threats.widgets.device-threats-neutralized.labels.highSeverityThreats'
      ),
    },
  ];

  return (
    <SectionCard
      headerTitle={i18n.t(
        'managed-threats.widgets.device-threats-neutralized.title'
      )}
      headerIcon={faShieldCheck}>
      {malwareProtection > 0 && (
        <ThreatsNeutralized
          subtitle={i18n.t(
            'managed-threats.widgets.device-threats-neutralized.subtitle',
            {
              amount: getNumberFormat(
                (counters[COUNTER_TYPES.MALWARE_PROTECTION_LOW_ALERTS] ?? 0) +
                  (counters[COUNTER_TYPES.MALWARE_PROTECTION_HIGH_ALERTS] ?? 0)
              ),
            }
          )}
          threatsCounters={THREATS_NEUTRALIZED_DEVICES}
        />
      )}

      {malwareProtection === 0 && canContactProvider && (
        <EmptyCardContent text={i18n.t('common.noLicensesContactProvider')} />
      )}

      {malwareProtection === 0 && !canContactProvider && (
        <EmptyCardContent
          text={i18n.t('common.noLicensesContactUs')}
          buttonText={i18n.t('common.contact')}
          buttonOnClick={() => Tawk_API.toggle()}
        />
      )}
    </SectionCard>
  );
};

export default ThreatsNeutralizedDevices;
