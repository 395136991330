import { firestore } from '../../firebase';
import { GET_USER, UPDATE_USER } from './types';

export const getUser = (uid: string) => async (dispatch: Function) => {
  const user = await firestore
    .collection('users')
    .doc(uid)
    .get()
    .then((doc: any) => doc.data());
  dispatch({
    type: GET_USER,
    payload: { uid, ...user },
  });
};

type UserData = {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
};

export const update2fa =
  ({
    requires2fa,
    verified2fa,
  }: {
    requires2fa: boolean;
    verified2fa: boolean;
  }) =>
  (dispatch: Function, getState: Function) => {
    const { user } = getState();
    const newUser = { ...user, requires2fa, verified2fa };
    dispatch({
      type: UPDATE_USER,
      payload: newUser,
    });
  };

export default 1;
