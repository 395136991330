import styled from '@emotion/styled';

const SCNotFound = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  text-align: center;

  background-color: var(--white);
  border-radius: var(--section-radius);
  padding: 40px;
  height: 100%;
  width: 100%;
  max-width: 420px;
  box-shadow: 2px 2px 20px #79889714;

  h1 {
    font-size: 80px;
    color: var(--red);
  }

  h2 {
    font-size: 25px;
    font-weight: 600;
  }

  p {
    font-family: var(--font1);
    font-weight: 600;
  }
`;

export default SCNotFound;
