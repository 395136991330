import styled from '@emotion/styled';

const SCPartnerCollaborators = styled.div`
  .actions-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

export default SCPartnerCollaborators;
