import styled from '@emotion/styled';

const SCPhishingUploadLogo = styled.div`
  .ant-upload-list-picture-card {
    height: 150px;

    .ant-upload-select {
      height: auto !important;
      flex: 1;
    }

    .ant-upload-select:not(.ant-upload-disabled):hover {
      border-color: var(--bluish-grey) !important;
    }

    .ant-upload-list-item-container {
      height: auto !important;
      width: 150px !important;
    }
  }

  .upload-button {
    border: 0;
    background: none;

    p {
      margin-top: 8px;
      padding-inline: 10px;
      color: var(--bluish-grey);
    }
  }
`;

export default SCPhishingUploadLogo;
