import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Divider } from 'antd';

const SCInstallers = styled.div`
  .section-content {
    display: flex;
    flex-direction: column;
    gap: 50px;

    .header {
      display: flex;
      align-items: center;
      gap: 60px;

      .title {
        max-width: 250px;
        font-family: var(--font2);
        font-size: 30px;
        font-weight: 500;
        color: var(--bluish-grey);
      }

      .os-icons-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        > p {
          padding-inline-start: 10px;
          font-weight: 500;
        }
        .os-icons {
          display: flex;
          gap: 50px;
        }
      }
    }
    .download-content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .download-content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .download-content-header {
          display: flex;
          gap: 20px;
          align-items: center;

          p {
            font-weight: 500;
          }
        }

        .download-content-body {
          display: flex;
          gap: 40px;

          .download-content-text {
            text-align: justify;
          }
        }

        .download-content-button {
          display: flex;
          align-items: center;
          a {
            color: var(--white);
            background-color: var(--bluish-grey);
            border-radius: 50vh;
            padding: 5px 60px;
            text-decoration: none;

            :hover {
              background-color: var(--bluish-grey-hover);
              color: var(--skull-white);
              transition: var(--transition-delay) background-color;
            }
          }

          a.disabled {
            pointer-events: none;
            cursor: default;
            background-color: var(--light-grey-2);
          }
        }

        .token-button {
          display: flex;
          gap: 20px;
          align-items: center;
          font-weight: 300;
          background-color: var(--bluish-grey-2);
          color: var(--white);
          padding: 5px 10px;
          border-radius: 5px;
          cursor: pointer;

          :disabled {
            cursor: default;
          }
        }
      }
    }

    .download-content:not(:last-of-type)::after {
      content: '';
      border-bottom: 1px solid var(--bluish-grey-2);
      margin-top: 10px;
    }

    p {
      color: var(--bluish-grey);
    }
  }

  @media (width < 700px) {
    .section-content {
      .header {
        flex-direction: column;
        gap: 30px;

        h2,
        p {
          text-align: center;
        }

        .ant-divider {
          display: none;
        }
      }

      .download-content-wrapper {
        .download-content {
          .download-content-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
          }
        }
      }
    }
  }
`;

export const SCDivider = styled(Divider)`
  height: 60px;
  border-inline-start-color: var(--bluish-grey-2);
`;

const selectedOSButtonStyles = () => css`
  color: var(--bluish-grey);
  background-color: var(--bluish-grey-3);
`;

export const SCOSButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  padding: 10px 15px;
  color: var(--bluish-grey-2);
  border-radius: var(--section-radius);
  transition: color 0.1s linear, background-color 0.1s linear;
  ${({ selected }) => selected && selectedOSButtonStyles};

  :hover {
    scale: 1.1;
    transition: scale 0.1s ease-in;
  }
`;

export default SCInstallers;
