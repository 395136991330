import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CyberGuardianLogo } from '../../../img/cyberguardian/cyberguardian-logo.svg';
import SCNavbar from './Navbar.style';
import { auth } from '../../../firebase';
import { PUBLIC_HOME } from '../../../utils/constants/urls';

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogo = () => {
    if (auth.currentUser) {
      navigate('/client');
    } else {
      window.location.href = PUBLIC_HOME;
    }
  };

  return (
    <SCNavbar>
      <CyberGuardianLogo onClick={handleLogo} />
    </SCNavbar>
  );
};

export default Navbar;
