import api from '../config';
import { SECURITY_SCAN_API } from '../paths';

const runSecurityScan = ({ domain, atEmail, emails }) => {
  return api.post(`${SECURITY_SCAN_API}/run`, {
    domain,
    atEmail,
    emails,
  });
};

export default { runSecurityScan };
