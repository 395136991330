import styled from '@emotion/styled';

const SCYourInformationCard = styled.div`
  .your-information-form input,
  .your-information-form span {
    font-family: var(--font2) !important;
  }

  .profile-info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;

    .your-information-form-item {
      display: flex;
      gap: 10px;

      .your-information-form-item-icon {
        height: 16px;
        width: 16px;
        margin-top: 11px;
      }

      .your-information-form-item-input {
        width: 100%;

        .ant-input-borderless[disabled],
        .ant-input-group-addon {
          color: var(--black);
        }
      }
    }
  }

  .ant-select-selector {
    border: none !important;
  }

  .ant-input-group-addon {
    color: rgba(0, 0, 0, 0.25);
  }

  .buttons-form-container {
    margin-top: 20px;
    display: flex;
    gap: 20px;
  }
`;

export default SCYourInformationCard;
