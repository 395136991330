import React from 'react';
import ContactLegalInformation from '../../molecules/ContactLegalInformation/ContactLegalInformation';
import Navbar from '../../Partners/Navbar/Navbar';
import SCOnboardLayout from './OnboardLayout.style';

const OnboardLayout = ({ children }) => {
  return (
    <SCOnboardLayout className="wings-section-background">
      <Navbar />

      <div className="onboard-elements">{children}</div>

      <ContactLegalInformation color="transparent" size="section" separator />
    </SCOnboardLayout>
  );
};
export default OnboardLayout;
