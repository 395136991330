import React from 'react';
import {
  faChartColumn,
  faPowerOff,
  faSpinner,
  faUserGroup,
} from '@fortawesome/pro-regular-svg-icons';
import { Outlet, Link, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/pro-solid-svg-icons';
import { ReactComponent as CyberGuardianLogo } from '../../../img/cyberguardian/cyberguardian-logo-white.svg';
import { ReactComponent as CyberGuardianLogoSimple } from '../../../img/cyberguardian/cyberGuardianWhiteSimple.svg';
import SCPartnerLayout from './PartnerLayout.style';
import ContactLegalInformation from '../../molecules/ContactLegalInformation/ContactLegalInformation';
import { useTranslation } from '../../../i18n';
import useSignOut from '../../../hooks/useSignOut/useSignOut';
import usePartnerConfig from '../../../hooks/usePartnerConfig/usePartnerConfig';
import { PARTNER_TYPES } from '../../../utils/constants/partners';

const PartnerLayout = () => {
  const i18n = useTranslation();
  const location = useLocation();
  const params = useParams();

  const { signOutFromApp, loading: loadingSignOut } = useSignOut();
  const { isPartnerType, isParentPartner } = usePartnerConfig();

  const { pathname } = location;

  const basePath = Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ''),
    pathname
  );

  const hasAccessToCollaborators =
    isParentPartner &&
    isPartnerType([PARTNER_TYPES.MSSP, PARTNER_TYPES.RESELLER]);

  const PAGES_CONFIG = {
    '/partner': {
      to: '/partner',
      title: 'Dashboard',
      icon: faChartColumn,
      isStickyHeader: true,
    },
    ...(hasAccessToCollaborators && {
      '/partner/collaborators': {
        title: i18n.t('partner.collaborators.title'),
        to: '/partner/collaborators',
        icon: faBriefcase,
        isStickyHeader: true,
      },
    }),
    '/partner/clients': {
      title: i18n.t('common.clients'),
      to: '/partner/clients',
      icon: faUserGroup,
      isStickyHeader: true,
    },
    '/partner/clients/control-panel': {
      title: i18n.t('common.clients'),
      icon: faUserGroup,
      size: 'var(--section-width-xl)',
    },
  };

  const { isStickyHeader, size } = PAGES_CONFIG[basePath] || {};

  const isCurrentRoute = (route) =>
    pathname.split('/', 3).toString() === route.split('/').toString();

  return (
    <SCPartnerLayout isStickyHeader={isStickyHeader} size={size}>
      <div className="page-content">
        <div className="partner-menu">
          <Link className="logo-container" to="/partner">
            <CyberGuardianLogo className="cyberGuardian-logo" />
            <CyberGuardianLogoSimple className="cyberGuardian-simple-logo" />
            <h3>Partners</h3>
          </Link>

          <div className="navigation-container">
            {Object.values(PAGES_CONFIG).map(
              (route) =>
                route.to && (
                  <Link
                    className={`partner-menu-button${
                      isCurrentRoute(route.to) ? ' selected' : ''
                    }`}
                    to={
                      isCurrentRoute(route.to) ? window.location.href : route.to
                    }>
                    <FontAwesomeIcon
                      icon={route.icon}
                      fontSize="18px"
                      color={
                        isCurrentRoute(route.to)
                          ? 'inherit'
                          : 'var(--partner-primary)'
                      }
                    />
                    <span>{route.title}</span>
                  </Link>
                )
            )}
          </div>

          <button
            className="sign-out-button"
            type="button"
            onClick={signOutFromApp}
            disabled={loadingSignOut}>
            <FontAwesomeIcon
              icon={!loadingSignOut ? faPowerOff : faSpinner}
              spin={loadingSignOut}
            />
            <span>{i18n.t('common.signOut')}</span>
          </button>
        </div>

        <div className="partner-dashboard">
          {PAGES_CONFIG[pathname]?.title && (
            <header>
              <div className="header-container">
                <h3>{PAGES_CONFIG[pathname]?.title}</h3>
              </div>
            </header>
          )}

          <div className="dashboard-wrapper">
            <div className="dashboard-content">
              <Outlet />
            </div>
          </div>

          <ContactLegalInformation
            color="transparent"
            size="sectionXL"
            separator
          />
        </div>
      </div>
    </SCPartnerLayout>
  );
};

export default PartnerLayout;
