import React, { useState } from 'react';
import { Form, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faCity,
  faEarthEurope,
  faEnvelope,
  faLocationDot,
  faMap,
  faPen,
  faUserTag,
} from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import SCBillingInformationForm from './BillingInformationForm.style';
import { useTranslation } from '../../../i18n';
import { EUROPEAN_COUNTRIES } from '../../../utils/internationalizationModules/countries/europe';
import {
  isValidCompanyID,
  isValidPersonID,
} from '../../../utils/internationalizationModules/internationalizationHelper';
import SPANISH_STATES from '../../../utils/internationalizationModules/states/spanishStates';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import clientsService from '../../../api/services/clients.service';
import InfoBanner from '../InfoBanner/InfoBanner';
import {
  DEFAULT_COUNTRY,
  LOCALE_COUNTRIES,
} from '../../../utils/internationalizationModules/countries/countries';
import { handleErrorPopUp } from '../../../utils/constants/errors';

const getStateFromPostalCode = (postalCode) => {
  if (postalCode.length !== 5) {
    return null;
  }

  const stateId = postalCode.substr(0, 2);
  const newState = SPANISH_STATES.find((state) => {
    return state.code === stateId;
  });

  return newState ? newState.name : null;
};

const BillingInformationForm = ({ isShownInPopUp }) => {
  const client = useSelector((redux) => redux.client);
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [formEdit, setFormEdit] = useState(Boolean(isShownInPopUp));
  const [isSaving, setIsSaving] = useState(false);

  const initialCountry = client.billingAddress?.country || DEFAULT_COUNTRY.code;

  const country = Form.useWatch('country', form) ?? initialCountry;

  const countriesOptions = Object.values(LOCALE_COUNTRIES).map(
    ({ code, flag }) => ({
      value: code,
      label: `${flag} ${i18n.t(`countries.${code}`)}`,
      country: i18n.t(`countries.${code}`),
    })
  );

  const onValuesChange = (changedValues) => {
    const formPostalCode = changedValues?.postalCode;

    if (formPostalCode) {
      const state = getStateFromPostalCode(formPostalCode);
      form.setFieldValue('state', state);
    }
  };

  const onCancel = () => {
    if (isShownInPopUp) {
      dispatch(showPopUp(null));
    } else {
      form.resetFields();
      setFormEdit(false);
    }
  };

  const isSubmitButtonDisabled = () => {
    const inputsAreEmpty = Object.values(form.getFieldsValue()).some(
      (data) => !data
    );

    const hasError = form.getFieldsError().some(({ errors }) => errors.length);

    return inputsAreEmpty || hasError || isSaving;
  };

  const onFinish = async (billingFormValues) => {
    setIsSaving(true);

    try {
      await clientsService.updateBillingAddress(billingFormValues);

      if (isShownInPopUp) {
        dispatch(showPopUp(null));
        navigate('/client/contract-subscription');
      } else {
        setFormEdit(false);
      }
    } catch (error) {
      const errorCode = error.response?.data?.error;
      handleErrorPopUp(errorCode);
    } finally {
      setIsSaving(false);
    }
  };

  const buttonSize = isShownInPopUp ? 'full' : 'mid';
  const disableInput = !formEdit || isSaving;

  return (
    <SCBillingInformationForm
      labelCol={{ span: 20 }}
      form={form}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      layout="horizontal"
      variant={formEdit ? 'outlined' : 'borderless'}
      initialValues={{
        country: initialCountry,
        cif: client.company.cif,
        line1: client?.billingAddress?.line1,
        corporateName: client?.billingAddress?.corporateName,
        postalCode: client?.billingAddress?.postalCode,
        state: client?.billingAddress?.state,
        city: client?.billingAddress?.city,
      }}>
      <div className="billing-form-grid">
        <div className="billing-form-item">
          <FontAwesomeIcon
            className="billing-form-item-icon"
            icon={faEarthEurope}
            color="var(--bluish-grey)"
            size="1x"
          />
          <Form.Item
            name="country"
            className="billing-form-item-input"
            rules={[
              {
                required: true,
                message: i18n.t('welcome.formErrors.common.required'),
              },
            ]}>
            <Select
              size="large"
              options={countriesOptions}
              popupMatchSelectWidth={false}
              optionFilterProp="country"
              filterSort={(optionA, optionB) =>
                (optionA?.country ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.country ?? '').toLowerCase())
              }
              disabled={disableInput}
              // Remove suffix icon if form is not being editted
              {...(!formEdit && {
                suffixIcon: <></>,
              })}
            />
          </Form.Item>
        </div>

        {country === EUROPEAN_COUNTRIES.ES.code ? (
          <>
            <div className="billing-form-item">
              <FontAwesomeIcon
                className="billing-form-item-icon"
                icon={faUserTag}
                color="var(--bluish-grey)"
                size="1x"
              />
              <Form.Item
                name="cif"
                className="billing-form-item-input"
                normalize={(value) => value.trim()}
                rules={[
                  {
                    required: true,
                    message: i18n.t('welcome.formErrors.common.required'),
                  },
                  {
                    message: i18n.t(`setup.company.errorCompanyID.es`),
                    validator: (_, value) =>
                      !value ||
                      isValidCompanyID(value, 'es') ||
                      isValidPersonID(value, 'es')
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(i18n.t(`setup.company.errorCompanyID.es`))
                          ),
                  },
                ]}>
                <Input
                  size="large"
                  type="text"
                  placeholder="A1234567A"
                  disabled={Boolean(client.company.cif) || disableInput}
                />
              </Form.Item>
            </div>

            <div className="billing-form-item street-form-item">
              <FontAwesomeIcon
                className="billing-form-item-icon"
                icon={faLocationDot}
                color="var(--bluish-grey)"
                size="1x"
              />
              <Form.Item
                name="line1"
                className="billing-form-item-input"
                rules={[
                  {
                    required: true,
                    message: i18n.t('welcome.formErrors.common.required'),
                  },
                ]}>
                <Input
                  size="large"
                  type="text"
                  placeholder={i18n.t(
                    'profile.addBillingInformationPopUp.billingAddress'
                  )}
                  disabled={disableInput}
                />
              </Form.Item>
            </div>

            <div className="billing-form-item">
              <FontAwesomeIcon
                className="billing-form-item-icon"
                icon={faBuilding}
                color="var(--bluish-grey)"
                size="1x"
              />
              <Form.Item
                name="corporateName"
                className="billing-form-item-input"
                rules={[
                  {
                    required: true,
                    message: i18n.t('welcome.formErrors.common.required'),
                  },
                ]}>
                <Input
                  size="large"
                  type="text"
                  placeholder={i18n.t('profile.billingCorporateName')}
                  disabled={disableInput}
                />
              </Form.Item>
            </div>

            <div className="billing-form-item">
              <FontAwesomeIcon
                className="billing-form-item-icon"
                icon={faEnvelope}
                color="var(--bluish-grey)"
                size="1x"
              />
              <Form.Item
                name="postalCode"
                className="billing-form-item-input"
                rules={[
                  {
                    required: true,
                    message: i18n.t('welcome.formErrors.common.required'),
                  },
                ]}
                normalize={(value) => value.substring(0, 5)}>
                <Input
                  size="large"
                  type="number"
                  showCount={formEdit}
                  maxLength={5}
                  placeholder={i18n.t('profile.billingPostalCode')}
                  disabled={disableInput}
                />
              </Form.Item>
            </div>

            <div className="billing-form-item">
              <FontAwesomeIcon
                className="billing-form-item-icon"
                icon={faMap}
                color="var(--bluish-grey)"
                size="1x"
              />
              <Form.Item
                name="state"
                className="billing-form-item-input"
                rules={[
                  {
                    required: true,
                    message: i18n.t('welcome.formErrors.common.required'),
                  },
                ]}>
                <Input
                  size="large"
                  type="text"
                  placeholder={i18n.t('profile.billingState')}
                  disabled
                />
              </Form.Item>
            </div>

            <div className="billing-form-item">
              <FontAwesomeIcon
                className="billing-form-item-icon"
                icon={faCity}
                color="var(--bluish-grey)"
                size="1x"
              />
              <Form.Item
                name="city"
                className="billing-form-item-input"
                rules={[
                  {
                    required: true,
                    message: i18n.t('welcome.formErrors.common.required'),
                  },
                ]}>
                <Input
                  size="large"
                  type="text"
                  placeholder={i18n.t('profile.billingCity')}
                  disabled={disableInput}
                />
              </Form.Item>
            </div>
          </>
        ) : (
          <div className="billing-form-info-banner">
            <InfoBanner
              type="error"
              text={[i18n.t('errors.purchaseNotAvailable')]}
            />
          </div>
        )}
      </div>

      <div className={isShownInPopUp ? 'buttons-pop-up' : 'buttons-card'}>
        {formEdit ? (
          <>
            <Form.Item
              shouldUpdate
              className="FormItem_button"
              style={{ marginBottom: 0 }}>
              {() =>
                country === EUROPEAN_COUNTRIES.ES.code ? (
                  <Button
                    type="submit"
                    text={i18n.t('profile.save')}
                    loading={isSaving}
                    size={buttonSize}
                    color="bluishGrey"
                    disabled={isSubmitButtonDisabled()}
                  />
                ) : (
                  <Button
                    text={i18n.t('common.contactSupport')}
                    color="bluishGrey"
                    size={buttonSize}
                    onClick={() => {
                      Tawk_API.toggle();
                      dispatch(showPopUp(null));
                    }}
                  />
                )
              }
            </Form.Item>

            <Button
              text={i18n.t('common.cancel')}
              size={buttonSize}
              color="white"
              onClick={onCancel}
            />
          </>
        ) : (
          <Button
            text={i18n.t('profile.edit')}
            icon={faPen}
            loading={isSaving}
            size="mid"
            color="white"
            onClick={() => {
              setFormEdit(true);
            }}
            disabled={isSaving}
          />
        )}
      </div>
    </SCBillingInformationForm>
  );
};

export default BillingInformationForm;
