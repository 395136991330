import React, { useEffect, useState } from 'react';

import { faEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons';

import SCAccountCreated from './AccountCreated.style';
import Icon from '../../components/atoms/Icon/Icon';
import Button from '../../components/atoms/Button/Button';
import { auth } from '../../firebase';
import { useTranslation } from '../../i18n';
import { ErrorKey } from '../../track';

const AccountCreated = () => {
  const i18n = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (auth?.currentUser?.emailVerified) {
      window.location.href = '/client';
    }
  }, []);

  const sendEmailVerification = () => {
    setIsLoading(true);

    auth.currentUser
      .sendEmailVerification({ url: window.location.origin })
      .then(() => {
        setIsSent(true);
      })
      .catch(() => {
        ErrorKey('errors.not_found');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <SCAccountCreated>
      <Icon
        className="icon-container"
        iconName={faEnvelopeOpen}
        size="extraLarge"
        color="var(--red)"
      />

      <h1>{i18n.t('emailVerification.oneStep')}</h1>
      <p>{i18n.t('emailVerification.accountCreated')}</p>
      <p>{i18n.t('emailVerification.noEmailReceived')}</p>

      {!isSent ? (
        <Button
          text={i18n.t('emailVerification.resendLink')}
          onClick={sendEmailVerification}
          size="full"
          color="red"
          disabled={isLoading}
        />
      ) : (
        <p className="email-link-sent">
          {i18n.t('emailVerification.emailLinkSent')}
        </p>
      )}
    </SCAccountCreated>
  );
};

export default AccountCreated;
