import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  faBarsFilter,
  faCalendarClock,
  faChartSimple,
  faCompass,
  faEnvelopeCircleCheck,
  faEnvelopesBulk,
  faGauge,
  faLaptopMobile,
  faMailboxFlagUp,
  faUserGroup,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faBriefcase, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { Select, Skeleton, Table } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactApexChart from 'react-apexcharts';
import SCPartnerDashboard from './PartnerDashboard.style';
import {
  getBarChartOptions,
  getDonutChartOptions,
} from '../../utils/chartFactory/chartFactory';
import { useTranslation } from '../../i18n';
import SectionCard from '../../components/molecules/SectionCard/SectionCard';

import { getDateFormat } from '../../utils/format/dataFormat';
import KPICard from '../../components/molecules/KPICard/KPICard';
import { getLicensesByType } from '../../utils/functions/licenses';
import KPI from '../../components/molecules/KPI/KPI';
import { PROTECTION_TYPES } from '../../utils/constants/licenses';
import useLicenses from '../../hooks/licenses/useLicenses';
import useGetPartnerStatistics from '../../hooks/useGetPartnerStatistics/useGetPartnerStatistics';
import { PARTNER_TYPES } from '../../utils/constants/partners';
import SelectPartnerCollaborator from '../../components/molecules/SelectPartnerCollaborators/SelectPartnerCollaborator';
import usePartnerConfig from '../../hooks/usePartnerConfig/usePartnerConfig';

const ChartSkeleton = () => (
  <Skeleton
    active
    title={false}
    paragraph={{ rows: 3, width: ['30%', '100%', '50%'] }}
  />
);

const WIDGET = {
  MALWARE_PROTECTION: 'MALWARE_PROTECTION',
  WEB_PROTECTION: 'WEB_PROTECTION',
  MAILBOX_PROTECTION: 'MAILBOX_PROTECTION',
  PROTECTION_LEVEL: 'PROTECTION_LEVEL',
  SUBSCRIPTION_STATE: 'SUBSCRIPTION_STATE',
  LICENSES_RANGE: 'LICENSES_RANGE',
};

/* eslint-disable prettier/prettier */
const OPERATOR_FILTERS = {
  MALWARE_PROTECTION_INSTALLED_LICENSES: 'MALWARE_PROTECTION_INSTALLED_LICENSES',
  MALWARE_PROTECTION_SENT_NOT_INSTALLED_LICENSES: 'MALWARE_PROTECTION_SENT_NOT_INSTALLED_LICENSES',
  MALWARE_PROTECTION_AVAILABLE_TO_SEND: 'MALWARE_PROTECTION_AVAILABLE_TO_SEND',
  WEB_PROTECTION_INSTALLED_LICENSES: "WEB_PROTECTION_INSTALLED_LICENSES",
  WEB_PROTECTION_SENT_NOT_INSTALLED_LICENSES: "WEB_PROTECTION_SENT_NOT_INSTALLED_LICENSES",
  WEB_PROTECTION_AVAILABLE_TO_SEND: "WEB_PROTECTION_AVAILABLE_TO_SEND",
  MAILBOX_PROTECTION_ACTIVE: "MAILBOX_PROTECTION_ACTIVE",
  MAILBOX_PROTECTION_PROTECTED: "MAILBOX_PROTECTION_PROTECTED",
  MAILBOX_PROTECTION_EXCESS: "MAILBOX_PROTECTION_EXCESS",
  PROTECTION_LEVEL_UNKNOWN: "PROTECTION_LEVEL_UNKNOWN",
  PROTECTION_LEVEL_CRITICAL: "PROTECTION_LEVEL_CRITICAL",
  PROTECTION_LEVEL_LOW: "PROTECTION_LEVEL_LOW",
  PROTECTION_LEVEL_MEDIUM: "PROTECTION_LEVEL_MEDIUM",
  PROTECTION_LEVEL_HIGH: "PROTECTION_LEVEL_HIGH",
  SUBSCRIPTION_STATE_EXPIRED: "SUBSCRIPTION_STATE_EXPIRED",
  SUBSCRIPTION_STATE_LESS_THAN_3_MONTHS: "SUBSCRIPTION_STATE_LESS_THAN_3_MONTHS",
  SUBSCRIPTION_STATE_MORE_THAN_3_MONTHS: "SUBSCRIPTION_STATE_MORE_THAN_3_MONTHS",
  MALWARE_PROTECTION_LICENSES_RANGE_MORE_THAN_50: "MALWARE_PROTECTION_LICENSES_RANGE_MORE_THAN_50",
  MALWARE_PROTECTION_LICENSES_RANGE_BETWEEN_10_AND_50: "MALWARE_PROTECTION_LICENSES_RANGE_BETWEEN_10_AND_50",
  MALWARE_PROTECTION_LICENSES_RANGE_LESS_THAN_10: "MALWARE_PROTECTION_LICENSES_RANGE_LESS_THAN_10",
  WEB_PROTECTION_LICENSES_RANGE_MORE_THAN_50: "WEB_PROTECTION_LICENSES_RANGE_MORE_THAN_50",
  WEB_PROTECTION_LICENSES_RANGE_BETWEEN_10_AND_50: "WEB_PROTECTION_LICENSES_RANGE_BETWEEN_10_AND_50",
  WEB_PROTECTION_LICENSES_RANGE_LESS_THAN_10: "WEB_PROTECTION_LICENSES_RANGE_LESS_THAN_10",
  EMAIL_PROTECTION_LICENSES_RANGE_MORE_THAN_50: "EMAIL_PROTECTION_LICENSES_RANGE_MORE_THAN_50",
  EMAIL_PROTECTION_LICENSES_RANGE_BETWEEN_10_AND_50: "EMAIL_PROTECTION_LICENSES_RANGE_BETWEEN_10_AND_50",
  EMAIL_PROTECTION_LICENSES_RANGE_LESS_THAN_10: "EMAIL_PROTECTION_LICENSES_RANGE_LESS_THAN_10"
};
/* eslint-enable prettier/prettier */

const donutChartResponsiveOptions = [
  {
    breakpoint: 550,
    options: {
      chart: {
        width: '100%',
        height: '250',
      },
      legend: {
        position: 'bottom',
      },
    },
  },
];

const chartValuesFormatter = (value) => {
  return value % 1 !== 0 ? value.toFixed(1) : value.toFixed(0);
};

const PartnerDashboard = () => {
  const subscription = useSelector((redux) => redux.subscription);
  const client = useSelector((redux) => redux.client);
  const user = useSelector((redux) => redux.user);
  const { lang } = useTranslation();
  const i18n = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { licenses, licensesSent, licensesUsed } = useLicenses();
  const { malwareProtectionLicenses, webProtectionLicenses } =
    getLicensesByType(licenses, licensesSent, licensesUsed);
  const {
    emailProtection: clientEmailProtection = 0,
    malwareProtection: clientMalwareProtection = 0,
    webProtection: clientWebProtection = 0,
  } = licenses;
  const { isPartnerType, isParentPartner } = usePartnerConfig();

  const [sortedInfo, setSortedInfo] = useState({
    columnKey: 'score',
    order: 'descend',
  });
  const [licensesRangeFilter, setLicensesRangeFilter] = useState(
    PROTECTION_TYPES.MALWARE
  );

  const fieldFromUrl = WIDGET[searchParams.get('field')];
  const operatorFromUrl = OPERATOR_FILTERS[searchParams.get('operator')];
  const collaboratorIdFromUrl = searchParams.get('collaboratorId');

  const { partnerStatistics, loading } = useGetPartnerStatistics({
    filter: operatorFromUrl,
    partnerId: collaboratorIdFromUrl,
  });

  const canSeeScore = isPartnerType([
    PARTNER_TYPES.MSSP,
    PARTNER_TYPES.RESELLER,
  ]);

  const DEFAULT_FILTER_FIELD = canSeeScore
    ? WIDGET.PROTECTION_LEVEL
    : WIDGET.MALWARE_PROTECTION;

  useEffect(() => {
    const sortedFields = {
      [WIDGET.MALWARE_PROTECTION]: 'malwareProtection',
      [WIDGET.WEB_PROTECTION]: 'webProtection',
      [WIDGET.MAILBOX_PROTECTION]: 'emailProtection',
      [WIDGET.PROTECTION_LEVEL]: 'score',
      [WIDGET.SUBSCRIPTION_STATE]: 'expiredAt',
      [WIDGET.LICENSES_RANGE]: licensesRangeFilter,
    };
    const DEFAULT_FIELD = sortedFields[DEFAULT_FILTER_FIELD];

    setSortedInfo({
      columnKey: fieldFromUrl ? sortedFields[fieldFromUrl] : DEFAULT_FIELD,
      order: 'descend',
    });
  }, [fieldFromUrl, licensesRangeFilter]);

  useEffect(() => {
    const metadata = {
      language: lang,
      name: `${user?.firstName} ${user?.lastName}`,
      email: user?.email,
      country: process.env.REACT_APP_ENVIRONMENT,
      userName: `${user?.firstName} ${user?.lastName}`,
      userEmail: user?.email,
      userEmailDomain: client?.atEmail,
      userDetails: `partner: ${client?.partner}`,
      companyName: client?.company?.name,
      companyCif: client?.company?.cif,
      companyWebsite: client?.company?.website,
      subscriptionType: subscription?.product || '-',
      subscriptionStartDate:
        subscription?.startTime?.toDate().toLocaleDateString('es-ES') || '-',
      subscriptionEndDate:
        subscription?.endTime?.toDate().toLocaleDateString('es-ES') || '-',
      subscriptionLicenses: `Total licencias dispositivo:${clientMalwareProtection} Total licencias navegación:${clientWebProtection} Total licencias seguridad correo:${clientEmailProtection}`,
      subscriptionSentLicenses: `Total licencias enviadas dispositivo:${malwareProtectionLicenses.sent} Total licencias enviadas navegación:${webProtectionLicenses.sent}`,
      subscriptionInstalledLicenses: `Total licencias instaladas dispositivo:${malwareProtectionLicenses.used} Total licencias instaladas navegación:${webProtectionLicenses.used}`,
      hash: 'f88628668a083c77dd68e5e43b05f1adf7bcce95e8ed381d3daf8c2f7dc15857',
    };

    if (window.Tawk_API && window.Tawk_API.onLoaded) {
      if (window.Tawk_API) {
        window.Tawk_API.setAttributes(metadata, (error) => {
          if (error) console.log('Tawk_API.setAttributes error', error);
        });
      }
    }
  }, [client, subscription, lang]);

  const partnerClientsFiltered = operatorFromUrl
    ? partnerStatistics?.filtered
    : partnerStatistics?.all;

  const getClientsByField = (field) => {
    if (!operatorFromUrl || fieldFromUrl === field) {
      return partnerStatistics?.all;
    }

    return partnerStatistics?.filtered;
  };

  const { malwareProtection } =
    getClientsByField(WIDGET.MALWARE_PROTECTION) ?? {};
  const { webProtection } = getClientsByField(WIDGET.WEB_PROTECTION) ?? {};
  const { emailProtection, totalClients: totalClientsMailboxProtection } =
    getClientsByField(WIDGET.MAILBOX_PROTECTION) ?? {};
  const { scoring } = getClientsByField(WIDGET.PROTECTION_LEVEL) ?? {};
  const { subscriptionExpirationRange } =
    getClientsByField(WIDGET.SUBSCRIPTION_STATE) ?? {};
  const { malwareProtectionRange, webProtectionRange, emailProtectionRange } =
    getClientsByField(WIDGET.LICENSES_RANGE) ?? {};
  const licensesRange = {
    [PROTECTION_TYPES.MALWARE]: malwareProtectionRange,
    [PROTECTION_TYPES.WEB]: webProtectionRange,
    [PROTECTION_TYPES.EMAIL]: emailProtectionRange,
  };

  const nClients = partnerClientsFiltered?.totalClients;

  const updateFilter = (newFilter) => {
    const { field, operator } = newFilter;

    if (operatorFromUrl !== operator) {
      setSearchParams((prev) => {
        prev.set('field', field);
        prev.set('operator', operator);

        return prev;
      });
    } else {
      resetFilter();
    }
  };

  const CHART_OPTIONS_MALWARE_PROTECTION = {
    series: malwareProtection?.licenses
      ? [
          malwareProtection?.used,
          malwareProtection?.sentAndNotUsed,
          malwareProtection?.available,
        ]
      : [1],
    options: getDonutChartOptions({
      labels: malwareProtection?.licenses
        ? [
            i18n.t('controlPanel.widgetSubscription.licensesInstalled'),
            i18n.t('controlPanel.widgetSubscription.licensesSent'),
            i18n.t('controlPanel.widgetSubscription.lisensesAvailable'),
          ]
        : [i18n.t('controlPanel.widgetSubscription.lisensesAvailable')],
      colors: malwareProtection?.licenses
        ? ['var(--green)', 'var(--light-green2)', 'var(--light-grey-3)']
        : ['var(--light-grey-3)'],
      dataLabelsColors: malwareProtection?.licenses
        ? ['var(--light-grey-4)', 'var(--light-grey-4)', 'var(--bluish-grey)']
        : ['var(--bluish-grey)'],
      dataLabelsEnable: !!malwareProtection?.licenses,
      legendWidth: 200,
      responsive: donutChartResponsiveOptions,
      dataPointSelectionEvent: (event, chartContext, config) => {
        const OPERATORS = {
          0: OPERATOR_FILTERS.MALWARE_PROTECTION_INSTALLED_LICENSES,
          1: OPERATOR_FILTERS.MALWARE_PROTECTION_SENT_NOT_INSTALLED_LICENSES,
          2: OPERATOR_FILTERS.MALWARE_PROTECTION_AVAILABLE_TO_SEND,
        };

        const auxFilter = {
          field: WIDGET.MALWARE_PROTECTION,
          operator: OPERATORS[config.dataPointIndex],
        };

        updateFilter(auxFilter);
      },
    }),
  };

  const CHART_OPTIONS_WEB_PROTECTION = {
    series: webProtection?.licenses
      ? [
          webProtection?.used,
          webProtection?.sentAndNotUsed,
          webProtection?.available,
        ]
      : [1],
    options: getDonutChartOptions({
      labels: webProtection?.licenses
        ? [
            i18n.t('controlPanel.widgetSubscription.licensesInstalled'),
            i18n.t('controlPanel.widgetSubscription.licensesSent'),
            i18n.t('controlPanel.widgetSubscription.lisensesAvailable'),
          ]
        : [i18n.t('controlPanel.widgetSubscription.lisensesAvailable')],
      colors: webProtection?.licenses
        ? ['var(--green)', 'var(--light-green2)', 'var(--light-grey-3)']
        : ['var(--light-grey-3)'],
      dataLabelsColors: webProtection?.licenses
        ? ['var(--light-grey-4)', 'var(--light-grey-4)', 'var(--bluish-grey)']
        : ['var(--bluish-grey)'],
      dataLabelsEnable: !!webProtection?.licenses,
      legendWidth: 200,
      responsive: donutChartResponsiveOptions,
      dataPointSelectionEvent: (event, chartContext, config) => {
        const OPERATORS = {
          0: OPERATOR_FILTERS.WEB_PROTECTION_INSTALLED_LICENSES,
          1: OPERATOR_FILTERS.WEB_PROTECTION_SENT_NOT_INSTALLED_LICENSES,
          2: OPERATOR_FILTERS.WEB_PROTECTION_AVAILABLE_TO_SEND,
        };

        const auxFilter = {
          field: WIDGET.WEB_PROTECTION,
          operator: OPERATORS[config.dataPointIndex],
        };

        updateFilter(auxFilter);
      },
    }),
  };

  const CHART_OPTIONS_PROTECTION_LEVEL = {
    series:
      nClients > 0
        ? [
            scoring?.unknown,
            scoring?.critical,
            scoring?.low,
            scoring?.medium,
            scoring?.high,
          ]
        : [1],
    options: getDonutChartOptions({
      labels:
        nClients > 0
          ? [
              i18n.t('partner.dashboard.protectionLevelChart.labels.unknown'),
              i18n.t('partner.dashboard.protectionLevelChart.labels.critical'),
              i18n.t('partner.dashboard.protectionLevelChart.labels.low'),
              i18n.t('partner.dashboard.protectionLevelChart.labels.medium'),
              i18n.t('partner.dashboard.protectionLevelChart.labels.high'),
            ]
          : [i18n.t('partner.dashboard.protectionLevelChart.labels.unknown')],
      colors:
        nClients > 0
          ? [
              'var(--light-grey-3)',
              'var(--red)',
              'var(--light-red)',
              'var(--orange)',
              'var(--green)',
            ]
          : ['var(--light-grey-3)'],
      dataLabelsColors: [
        'var(--bluish-grey)',
        'var(--white)',
        'var(--bluish-grey)',
        'var(--white)',
        'var(--white)',
      ],
      dataLabelsEnable: nClients > 0,
      legendWidth: 200,
      responsive: donutChartResponsiveOptions,
      dataPointSelectionEvent: (event, chartContext, config) => {
        const OPERATORS = {
          0: OPERATOR_FILTERS.PROTECTION_LEVEL_UNKNOWN,
          1: OPERATOR_FILTERS.PROTECTION_LEVEL_CRITICAL,
          2: OPERATOR_FILTERS.PROTECTION_LEVEL_LOW,
          3: OPERATOR_FILTERS.PROTECTION_LEVEL_MEDIUM,
          4: OPERATOR_FILTERS.PROTECTION_LEVEL_HIGH,
        };

        const auxFilter = {
          field: WIDGET.PROTECTION_LEVEL,
          operator: OPERATORS[config.dataPointIndex],
        };

        updateFilter(auxFilter);
      },
    }),
  };

  const CHART_OPTIONS_SUBSCRIPTION_STATE = {
    series: [
      {
        data: [
          subscriptionExpirationRange?.expired ?? 0,
          subscriptionExpirationRange?.lessThanThreeMonths ?? 0,
          subscriptionExpirationRange?.moreThanThreeMonths ?? 0,
        ],
      },
    ],
    options: getBarChartOptions({
      categories: [
        i18n.t(
          'partner.dashboard.subscriptionExpirationChart.categories.expired'
        ),
        i18n.t(
          'partner.dashboard.subscriptionExpirationChart.categories.lessThan3Months'
        ),
        i18n.t(
          'partner.dashboard.subscriptionExpirationChart.categories.moreThan3Months'
        ),
      ],
      colors: ['var(--partner-primary)'],
      tooltipFormatter: () => `${i18n.t('common.clients')}:`,
      valuesFormatter: (value) => chartValuesFormatter(value),
      dataPointSelectionEvent: (event, chartContext, config) => {
        const OPERATORS = {
          0: OPERATOR_FILTERS.SUBSCRIPTION_STATE_EXPIRED,
          1: OPERATOR_FILTERS.SUBSCRIPTION_STATE_LESS_THAN_3_MONTHS,
          2: OPERATOR_FILTERS.SUBSCRIPTION_STATE_MORE_THAN_3_MONTHS,
        };

        const auxFilter = {
          field: WIDGET.SUBSCRIPTION_STATE,
          operator: OPERATORS[config.dataPointIndex],
        };

        updateFilter(auxFilter);
      },
    }),
  };

  const CHART_OPTIONS_LICENSES_RANGE = {
    series: [
      {
        data: [
          licensesRange?.[licensesRangeFilter]?.moreThanFifty ?? 0,
          licensesRange?.[licensesRangeFilter]?.tenToFifty ?? 0,
          licensesRange?.[licensesRangeFilter]?.zeroToTen ?? 0,
        ],
      },
    ],
    options: getBarChartOptions({
      categories: [
        i18n.t(
          'partner.dashboard.purchasedLicensesRangeChart.categories.moreThan50'
        ),
        i18n.t(
          'partner.dashboard.purchasedLicensesRangeChart.categories.between10And50'
        ),
        i18n.t(
          'partner.dashboard.purchasedLicensesRangeChart.categories.lessThan10'
        ),
      ],
      colors: ['var(--partner-primary)'],
      tooltipFormatter: () => `${i18n.t('common.clients')}:`,
      valuesFormatter: (value) => chartValuesFormatter(value),
      dataPointSelectionEvent: (event, chartContext, config) => {
        const mappedLicensesRange = {
          [PROTECTION_TYPES.MALWARE]: {
            0: OPERATOR_FILTERS.MALWARE_PROTECTION_LICENSES_RANGE_MORE_THAN_50,
            1: OPERATOR_FILTERS.MALWARE_PROTECTION_LICENSES_RANGE_BETWEEN_10_AND_50,
            2: OPERATOR_FILTERS.MALWARE_PROTECTION_LICENSES_RANGE_LESS_THAN_10,
          },
          [PROTECTION_TYPES.WEB]: {
            0: OPERATOR_FILTERS.WEB_PROTECTION_LICENSES_RANGE_MORE_THAN_50,
            1: OPERATOR_FILTERS.WEB_PROTECTION_LICENSES_RANGE_BETWEEN_10_AND_50,
            2: OPERATOR_FILTERS.WEB_PROTECTION_LICENSES_RANGE_LESS_THAN_10,
          },
          [PROTECTION_TYPES.EMAIL]: {
            0: OPERATOR_FILTERS.EMAIL_PROTECTION_LICENSES_RANGE_MORE_THAN_50,
            1: OPERATOR_FILTERS.EMAIL_PROTECTION_LICENSES_RANGE_BETWEEN_10_AND_50,
            2: OPERATOR_FILTERS.EMAIL_PROTECTION_LICENSES_RANGE_LESS_THAN_10,
          },
        };
        const OPERATORS = mappedLicensesRange[licensesRangeFilter];

        const auxFilter = {
          field: WIDGET.LICENSES_RANGE,
          operator: OPERATORS[config.dataPointIndex],
        };

        updateFilter(auxFilter);
      },
    }),
  };

  const getFilteredClientsColumns = () => {
    const sortDirections = ['ascend', 'descend', 'ascend'];
    const filterField = fieldFromUrl ?? DEFAULT_FILTER_FIELD;

    const getSortOrder = (columnKey) => {
      return sortedInfo.columnKey === columnKey ? sortedInfo.order : null;
    };

    const getLicensesColumnConfig = () => {
      const isRangeFilter = filterField === WIDGET.LICENSES_RANGE;

      if (
        filterField === WIDGET.MALWARE_PROTECTION ||
        (isRangeFilter && licensesRangeFilter === PROTECTION_TYPES.MALWARE)
      ) {
        return {
          columnKey: PROTECTION_TYPES.MALWARE,
          title: i18n.t('profile.billingHistory.malwareLicenses'),
          sorter: (a, b) => a.malwareProtection - b.malwareProtection,
        };
      }

      if (
        filterField === WIDGET.WEB_PROTECTION ||
        (isRangeFilter && licensesRangeFilter === PROTECTION_TYPES.WEB)
      ) {
        return {
          columnKey: PROTECTION_TYPES.WEB,
          title: i18n.t('profile.billingHistory.webLicenses'),
          sorter: (a, b) => a.webProtection - b.webProtection,
        };
      }

      return {
        columnKey: PROTECTION_TYPES.EMAIL,
        title: i18n.t('profile.billingHistory.mailboxLicenses'),
        sorter: (a, b) => a.emailProtection - b.emailProtection,
      };
    };

    const renderCallback = (text) => (
      <span style={{ color: 'var(--bluish-grey)' }}>{text}</span>
    );

    const filteredClientsColumns = [
      {
        title: i18n.t('partner.dashboard.clientsTable.client'),
        dataIndex: 'name',
        key: 'name',
        sortDirections,
        sorter: ({ nameToSort: aNameToSort }, { nameToSort: bNameToSort }) => {
          if (!aNameToSort) return 1;
          if (!bNameToSort) return -1;

          return aNameToSort.localeCompare(bNameToSort);
        },
        sortOrder: getSortOrder('name'),
      },
    ];

    if (filterField === WIDGET.PROTECTION_LEVEL) {
      filteredClientsColumns.push({
        title: i18n.t('partner.dashboard.clientsTable.score'),
        dataIndex: 'score',
        key: 'score',
        render: renderCallback,
        sortDirections,
        sorter: (a, b) => a.scoreToSort - b.scoreToSort,
        sortOrder: getSortOrder('score'),
      });
    }

    if (
      [
        WIDGET.LICENSES_RANGE,
        WIDGET.MALWARE_PROTECTION,
        WIDGET.WEB_PROTECTION,
        WIDGET.MAILBOX_PROTECTION,
      ].includes(filterField)
    ) {
      const { columnKey, title, sorter } = getLicensesColumnConfig();

      filteredClientsColumns.push({
        title,
        dataIndex: columnKey,
        key: columnKey,
        render: renderCallback,
        sortDirections,
        sorter,
        sortOrder: getSortOrder(columnKey),
      });
    }

    if (filterField === WIDGET.SUBSCRIPTION_STATE) {
      filteredClientsColumns.push({
        title: i18n.t('partner.dashboard.clientsTable.expiration'),
        dataIndex: 'expiredAt',
        key: 'expiredAt',
        render: renderCallback,
        sortDirections,
        sorter: (a, b) => a.expiredAtToSort - b.expiredAtToSort,
        sortOrder: getSortOrder('expiredAt'),
      });
    }

    return filteredClientsColumns;
  };

  const filteredClientsTableData = partnerClientsFiltered?.clients?.map(
    ({
      id,
      companyName,
      scoring: clientScoring,
      nextRenewalAt,
      malwareProtection: nMalwareProtection,
      webProtection: nWebProtection,
      emailProtection: nEmailProtection,
    }) => {
      const nextRenewalAtDate = new Date(nextRenewalAt);
      const isSubscriptionExpired = new Date() > nextRenewalAtDate;

      return {
        id,
        name: companyName || '-',
        nameToSort: companyName,
        score: Math.round(clientScoring * 10) / 10 || '-',
        scoreToSort: clientScoring || 0,
        malwareProtection: nMalwareProtection,
        webProtection: nWebProtection,
        emailProtection: nEmailProtection,
        expiredAt: !isSubscriptionExpired
          ? getDateFormat(nextRenewalAtDate)
          : '-',
        expiredAtToSort: !isSubscriptionExpired ? nextRenewalAtDate : 0,
      };
    }
  );

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const resetFilter = () => {
    setSearchParams((prev) => {
      prev.delete('field');
      prev.delete('operator');

      return prev;
    });
  };

  const handleCardHeaderFilter = (field) => () => {
    // Evitamos que estando filtrando por el gráfico, al hacer click en el header de la card, se elimine el filtro
    if (fieldFromUrl !== field) {
      setSearchParams((prev) => {
        prev.set('field', field);
        prev.delete('operator');
        return prev;
      });
    }
  };

  const handleMailboxProtectionFilter = (operator) => {
    updateFilter({
      field: WIDGET.MAILBOX_PROTECTION,
      operator,
    });
  };

  const getFilterTranslationKey = () => {
    const mappedFilters = {
      [OPERATOR_FILTERS.MALWARE_PROTECTION_LICENSES_RANGE_MORE_THAN_50]:
        'LICENSES_RANGE_MORE_THAN_50',
      [OPERATOR_FILTERS.WEB_PROTECTION_LICENSES_RANGE_MORE_THAN_50]:
        'LICENSES_RANGE_MORE_THAN_50',
      [OPERATOR_FILTERS.EMAIL_PROTECTION_LICENSES_RANGE_MORE_THAN_50]:
        'LICENSES_RANGE_MORE_THAN_50',
      [OPERATOR_FILTERS.MALWARE_PROTECTION_LICENSES_RANGE_BETWEEN_10_AND_50]:
        'LICENSES_RANGE_BETWEEN_10_AND_50',
      [OPERATOR_FILTERS.WEB_PROTECTION_LICENSES_RANGE_BETWEEN_10_AND_50]:
        'LICENSES_RANGE_BETWEEN_10_AND_50',
      [OPERATOR_FILTERS.EMAIL_PROTECTION_LICENSES_RANGE_BETWEEN_10_AND_50]:
        'LICENSES_RANGE_BETWEEN_10_AND_50',
      [OPERATOR_FILTERS.MALWARE_PROTECTION_LICENSES_RANGE_LESS_THAN_10]:
        'LICENSES_RANGE_LESS_THAN_10',
      [OPERATOR_FILTERS.WEB_PROTECTION_LICENSES_RANGE_LESS_THAN_10]:
        'LICENSES_RANGE_LESS_THAN_10',
      [OPERATOR_FILTERS.EMAIL_PROTECTION_LICENSES_RANGE_LESS_THAN_10]:
        'LICENSES_RANGE_LESS_THAN_10',
    };

    return mappedFilters[operatorFromUrl] ?? operatorFromUrl;
  };

  return (
    <SCPartnerDashboard>
      <div className="filter-container">
        <div className="filter-info">
          {operatorFromUrl ? (
            <>
              <FontAwesomeIcon
                icon={faBarsFilter}
                fontSize="16px"
                color="var(--bluish-grey)"
              />
              <span className="filtering-by">
                {i18n.t('partner.dashboard.filter.filteringBy')}
              </span>
              <span className="filter-operator-tag">
                {i18n.t(
                  `partner.dashboard.filter.values.${getFilterTranslationKey()}`
                )}
                <button
                  className="close-button"
                  type="button"
                  aria-label="Reset filter button"
                  onClick={resetFilter}>
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={faXmark} size="xs" />
                  </div>
                </button>
              </span>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faCircleInfo}
                fontSize="16px"
                color="var(--bluish-grey)"
              />
              <p>{i18n.t('partner.dashboard.filter.emptyMessage')}</p>
            </>
          )}
        </div>

        {isParentPartner && (
          <SelectPartnerCollaborator
            value={collaboratorIdFromUrl}
            onChange={(selectedCollaboratorId) => {
              if (!selectedCollaboratorId) {
                setSearchParams((prev) => {
                  prev.delete('collaboratorId');
                  return prev;
                });
              } else {
                setSearchParams((prev) => {
                  prev.set('collaboratorId', selectedCollaboratorId);
                  return prev;
                });
              }
            }}
          />
        )}
      </div>

      <div className="charts-table-container">
        <div className="charts-table-kpis">
          {isParentPartner && !collaboratorIdFromUrl && (
            <KPICard
              value={partnerClientsFiltered?.totalChildren}
              title={i18n.t('partner.collaborators.title')}
              icon={faBriefcase}
              iconBackgroundColor="var(--partner-primary-light)"
              loading={loading}
            />
          )}

          <KPICard
            value={nClients}
            title={i18n.t('common.clients')}
            icon={faUserGroup}
            iconBackgroundColor="var(--partner-primary-light)"
            loading={loading}
          />

          {canSeeScore && (
            <KPICard
              value={Math.round(partnerClientsFiltered?.scoringAvg * 10) / 10}
              title={i18n.t('partner.dashboard.averageScore')}
              icon={faGauge}
              iconBackgroundColor="var(--partner-primary-light)"
              loading={loading}
            />
          )}
        </div>

        {/* Protección de dispositivos */}
        <SectionCard
          headerTitle={i18n.t(
            'controlPanel.protectionLicenses.deviceProtectionTitle'
          )}
          headerIcon={faLaptopMobile}
          headerButtonOnClick={handleCardHeaderFilter(
            WIDGET.MALWARE_PROTECTION
          )}
          isHeaderSelected={fieldFromUrl === WIDGET.MALWARE_PROTECTION}>
          {!loading ? (
            <ReactApexChart
              options={CHART_OPTIONS_MALWARE_PROTECTION.options}
              series={CHART_OPTIONS_MALWARE_PROTECTION.series}
              type="donut"
              height={175}
            />
          ) : (
            <div className="donut-chart-skeleton">
              <ChartSkeleton />
            </div>
          )}
        </SectionCard>

        {/* Protección de navegación */}
        <SectionCard
          headerTitle={i18n.t(
            'controlPanel.protectionLicenses.webProtectionTitle'
          )}
          headerIcon={faCompass}
          headerButtonOnClick={handleCardHeaderFilter(WIDGET.WEB_PROTECTION)}
          isHeaderSelected={fieldFromUrl === WIDGET.WEB_PROTECTION}>
          {!loading ? (
            <ReactApexChart
              options={CHART_OPTIONS_WEB_PROTECTION.options}
              series={CHART_OPTIONS_WEB_PROTECTION.series}
              type="donut"
              height={175}
            />
          ) : (
            <div className="donut-chart-skeleton">
              <ChartSkeleton />
            </div>
          )}
        </SectionCard>

        {/* Protección de buzones */}
        <SectionCard
          headerIcon={faEnvelopesBulk}
          headerTitle={i18n.t('controlPanel.mailboxProtection.title')}
          headerButtonOnClick={handleCardHeaderFilter(
            WIDGET.MAILBOX_PROTECTION
          )}
          isHeaderSelected={fieldFromUrl === WIDGET.MAILBOX_PROTECTION}>
          <div className="mailbox-protection-kpi-container">
            <button
              type="button"
              aria-label="filter by active mailbox protection"
              className="kpi-filter-button"
              onClick={() => {
                handleMailboxProtectionFilter(
                  OPERATOR_FILTERS.MAILBOX_PROTECTION_ACTIVE
                );
              }}>
              <KPI
                title={i18n.t(
                  'partner.dashboard.mailboxProtection.clientsMailboxActive'
                )}
                icon={faEnvelopeCircleCheck}
                value={emailProtection?.active}
                total={totalClientsMailboxProtection}
                loading={loading}
              />
            </button>

            <button
              type="button"
              aria-label="filter by mailbox licenses"
              className="kpi-filter-button"
              onClick={() => {
                handleMailboxProtectionFilter(
                  OPERATOR_FILTERS.MAILBOX_PROTECTION_PROTECTED
                );
              }}>
              <KPI
                title={i18n.t(
                  'partner.dashboard.mailboxProtection.protectedMailboxes'
                )}
                icon={faMailboxFlagUp}
                value={emailProtection?.protectedMailboxes}
                total={emailProtection?.licenses}
                loading={loading}
              />
            </button>

            {emailProtection?.clientsWithExcessProtectedMailboxes > 0 && (
              <button
                type="button"
                aria-label="filtrar por clientes protegiendo más buzones que licencias tienen contratadas"
                className="excess-licenses-button"
                onClick={() => {
                  handleMailboxProtectionFilter(
                    OPERATOR_FILTERS.MAILBOX_PROTECTION_EXCESS
                  );
                }}>
                {i18n.t('partner.dashboard.mailboxProtection.excessLicenses')}
              </button>
            )}
          </div>
        </SectionCard>

        {canSeeScore && (
          <SectionCard
            headerTitle={i18n.t('partner.dashboard.protectionLevelChart.title')}
            headerIcon={faGauge}
            headerButtonOnClick={handleCardHeaderFilter(
              WIDGET.PROTECTION_LEVEL
            )}
            isHeaderSelected={fieldFromUrl === WIDGET.PROTECTION_LEVEL}>
            {!loading ? (
              <ReactApexChart
                options={CHART_OPTIONS_PROTECTION_LEVEL.options}
                series={CHART_OPTIONS_PROTECTION_LEVEL.series}
                type="donut"
                height={175}
              />
            ) : (
              <div className="donut-chart-skeleton">
                <ChartSkeleton />
              </div>
            )}
          </SectionCard>
        )}

        <SectionCard
          headerTitle={i18n.t(
            'partner.dashboard.subscriptionExpirationChart.title'
          )}
          headerIcon={faCalendarClock}
          headerButtonOnClick={handleCardHeaderFilter(
            WIDGET.SUBSCRIPTION_STATE
          )}
          isHeaderSelected={fieldFromUrl === WIDGET.SUBSCRIPTION_STATE}>
          {!loading ? (
            <div className="range-chart-container">
              <ReactApexChart
                options={CHART_OPTIONS_SUBSCRIPTION_STATE.options}
                series={CHART_OPTIONS_SUBSCRIPTION_STATE.series}
                type="bar"
                width="100%"
                height="200px"
              />
            </div>
          ) : (
            <div className="bar-chart-skeleton">
              <ChartSkeleton />
            </div>
          )}
        </SectionCard>

        <SectionCard
          headerTitle={i18n.t(
            'partner.dashboard.purchasedLicensesRangeChart.title'
          )}
          headerIcon={faChartSimple}
          headerButtonOnClick={handleCardHeaderFilter(WIDGET.LICENSES_RANGE)}
          isHeaderSelected={fieldFromUrl === WIDGET.LICENSES_RANGE}>
          {!loading ? (
            <div className="range-chart-container">
              <Select
                value={licensesRangeFilter}
                style={{ width: 150 }}
                onChange={setLicensesRangeFilter}
                options={[
                  {
                    value: PROTECTION_TYPES.MALWARE,
                    label: i18n.t(
                      'partner.dashboard.purchasedLicensesRangeChart.licensesRangeFilter.malware'
                    ),
                  },
                  {
                    value: PROTECTION_TYPES.WEB,
                    label: i18n.t(
                      'partner.dashboard.purchasedLicensesRangeChart.licensesRangeFilter.web'
                    ),
                  },
                  {
                    value: PROTECTION_TYPES.EMAIL,
                    label: i18n.t(
                      'partner.dashboard.purchasedLicensesRangeChart.licensesRangeFilter.email'
                    ),
                  },
                ]}
              />

              <ReactApexChart
                options={CHART_OPTIONS_LICENSES_RANGE.options}
                series={CHART_OPTIONS_LICENSES_RANGE.series}
                type="bar"
                width="100%"
                height="200px"
              />
            </div>
          ) : (
            <div className="bar-chart-skeleton">
              <ChartSkeleton />
            </div>
          )}
        </SectionCard>

        <SectionCard className="clients-table" hasPadding={false}>
          <Table
            columns={getFilteredClientsColumns()}
            dataSource={filteredClientsTableData}
            pagination={false}
            scroll={{ y: 1090 }}
            rowKey={(record) => record.id}
            {...(canSeeScore && {
              onRow: (record) => {
                return {
                  onClick: () =>
                    navigate(`/partner/clients/control-panel/${record.id}`),
                };
              },
              rowClassName: () => 'cursor-pointer',
            })}
            onChange={handleChange}
            locale={{
              ...(loading && {
                emptyText: <Skeleton active />,
              }),
            }}
          />
        </SectionCard>
      </div>
    </SCPartnerDashboard>
  );
};

export default PartnerDashboard;
