import isValidCif from './companyIdValidation/cifValidation';
import isValidDNI from './personIDValidation/dniValidation';

export const isValidCompanyID = (companyID, domain) => {
  const companyIDValidatorsByCountry = {
    es: isValidCif,
  };

  return companyIDValidatorsByCountry[domain](companyID) || false;
};

export const isValidPersonID = (nif, locale) => {
  const NIFValidatorsByCountry = {
    es: isValidDNI,
  };

  return NIFValidatorsByCountry[locale]?.(nif) || false;
};
