// Los códigos de país siguen el ISO 3166-1 alfa-2
// https://www.mjusticia.gob.es/es/Ciudadano/Registros/Documents/1292428778575-CODIGOS_ISO_3166_1.PDF
export const EUROPEAN_COUNTRIES = {
  ES: {
    code: 'ES',
    phonePrefix: '34',
    flag: '🇪🇸',
    phoneLength: [9],
    phoneExample: '612345678',
    startWith: /^[6789]\d*/,
  },
  GB: {
    code: 'GB',
    phonePrefix: '44',
    flag: '🇬🇧',
    phoneLength: [10],
  },
  PT: {
    code: 'PT',
    phonePrefix: '351',
    flag: '🇵🇹',
    phoneLength: [9],
  },
  PL: {
    code: 'PL',
    phonePrefix: '48',
    flag: '🇵🇱',
    phoneLength: [9],
  },
  DE: {
    code: 'DE',
    phonePrefix: '49',
    flag: '🇩🇪',
    phoneLength: [10, 11, 12, 13, 14],
  },
};
