import ReactGA from 'react-ga';
import { auth, googleProvider } from '../../firebase';
import { AUTH_USER, SIGN_OUT } from './types';
import { getUser } from './user.actions';
import { SESSION_STORAGE_KEYS } from '../../utils/constants/browserStorage';

export const authUser = () => (dispatch) => {
  const wasLogged = auth.currentUser && true;
  auth.onAuthStateChanged((user) => {
    if (user) {
      localStorage.removeItem('sessionTimeoutLogout');

      dispatch(getUser(user.uid));
      ReactGA.set({ userId: user.uid });
      dispatch({
        type: AUTH_USER,
        payload: auth,
      });
    } else {
      dispatch({
        type: AUTH_USER,
        payload: auth,
      });
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.CLIENT_ID);
      // he logged-out, move him to sign-in
      if (wasLogged) {
        window.location.href = `/sign-in?redirect=${window.location.href}`;
      }
    }
  });
};

export const signOut = () => (dispatch) => {
  auth.signOut().then(() => {
    dispatch({
      type: SIGN_OUT,
      payload: auth,
    });
    window.location.href = '/sign-in';
  });
};

export const signInWithGoogle = () => {
  return auth.signInWithPopup(googleProvider);
};
