import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { authUser } from '../../redux/actions/auth.actions';
import { initiateFirebase } from '../../redux/actions/firebase.actions';
import Spinner from '../../components/Misc/Spinner';
import { ErrorKey } from '../../track';
import SCTwoFactorAuthentication from './TwoFactorAuthentication.style';
import TwoFactorAuthenticationSetup from '../../components/organisms/TwoFactorAuthenticationSetup/TwoFactorAuthenticationSetup';
import { getFullPhoneNumber } from '../../utils/internationalizationModules/telephones/telephonePrefix';

const TwoFactorAuthentication = () => {
  const user = useSelector((state) => state.user);
  const firebase = useSelector((state) => state.firebase);
  const [isFetching, setIsFetching] = useState();
  const [isEnable, setIsEnable] = useState(false);
  const navigate = useNavigate();

  const [recaptchaReady, setRecaptchaReady] = useState();
  const [shouldVerifyCode, setShouldVerifyCode] = useState();
  const [recaptcha, setRecaptcha] = useState();

  const sendRecaptchaToken = () => {
    axios
      .post('/2fa/send-sms-code/', {
        recaptchaToken: recaptcha,
      })
      .then((res) => {
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
        localStorage.setItem('bedeid', res.data);
        setIsFetching(false);
        setShouldVerifyCode(true);
      })
      .catch((e) => {
        if (
          e &&
          e.response &&
          e.response.data === 'TOO_MANY_ATTEMPTS_TRY_LATER'
        ) {
          ErrorKey('authentication.tooManyAttemps');
        } else {
          ErrorKey('profile.tfa.error');
        }
        window.recaptchaVerifier.reset();
        setIsFetching(false);
      });
  };

  const addRecaptcha = () => {
    try {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'sendCode',
        {
          size: 'invisible',
          callback(recaptchaToken) {
            try {
              setRecaptcha(recaptchaToken);
              // a REST call to your backend
            } catch (e) {
              window.recaptchaVerifier.reset();
              setIsFetching(false);
              // window.recaptchaVerifier.clear();
            }
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    addRecaptcha();
    if (window.recaptchaVerifier) {
      try {
        window.recaptchaVerifier
          .render()
          .then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
            setRecaptchaReady(true);
          })
          .catch((e) => console.log(1, e));
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  useEffect(() => {
    if (recaptcha) {
      sendRecaptchaToken();
      if (window.recaptchaVerifier) {
        try {
          window.recaptchaVerifier.reset();
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [recaptcha]);

  const sendCodeButton = React.createRef();

  const clickSendCodeButton = () => {
    if (sendCodeButton && sendCodeButton.current) {
      setIsFetching(true);
      sendCodeButton.current.click();
    }
  };

  const verifyCode = (code) => {
    const bedeid = localStorage.getItem('bedeid');
    setIsFetching(true);
    axios
      .post('/2fa/verify-sms-code/', {
        SMSCode: code,
        bedeid,
      })
      .then(async (res) => {
        setIsFetching(false);
        window.recaptchaVerifier.clear();
        await firebase.auth().signInWithCustomToken(res.data);
        navigate('/');
        setShouldVerifyCode(false);
        setIsEnable(!isEnable);
      })
      .catch((e) => {
        if (
          e &&
          e.response &&
          e.response.data === 'TOO_MANY_ATTEMPTS_TRY_LATER'
        ) {
          ErrorKey('authentication.tooManyAttemps');
        } else {
          ErrorKey('profile.tfa.error');
        }
        setIsFetching(false);
        setShouldVerifyCode(false);
        console.log(e);
      });
  };

  return (
    <SCTwoFactorAuthentication>
      <Spinner loading={!recaptchaReady} />
      <TwoFactorAuthenticationSetup
        phoneNumber={getFullPhoneNumber(
          user.phone?.countryCode,
          user.phone?.number
        )}
        recaptchaReady={recaptchaReady}
        clickSendCodeButton={clickSendCodeButton}
        isFetching={isFetching}
        shouldVerifyCode={shouldVerifyCode}
        verifyCode={verifyCode}
      />
      <button
        type="button"
        id="sendCode"
        ref={sendCodeButton}
        style={{ visibility: 'hidden' }}
      />
    </SCTwoFactorAuthentication>
  );
};

export function mapStateToProps(state) {
  return {
    auth: state.auth,
    firebase: state.firebase,
  };
}

export default connect(mapStateToProps, {
  authUser,
  initiateFirebase,
})(TwoFactorAuthentication);
