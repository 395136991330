import React, { useState } from 'react';
import { faCircle2 } from '@fortawesome/pro-solid-svg-icons';
import { Upload } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import SCPhishingUploadLogo from './PhishingUploadLogo.style';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import clientsService from '../../../api/services/clients.service';
import { showPopUp } from '../../../redux/actions/popUp.actions';

const ACCEPTED_TYPES = ['image/png', 'image/jpeg'];

const PhishingUploadLogo = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const client = useSelector((redux) => redux.client);

  const defaultFileList = client.company.logoUrl
    ? [
        {
          uid: '-1',
          name: 'logo.png',
          status: 'done',
          url: client.company.logoUrl,
        },
      ]
    : [];

  const [fileList, setFileList] = useState(defaultFileList);

  const beforeUpload = ({ type, size }) => {
    const isAcceptedType = ACCEPTED_TYPES.includes(type);

    if (!isAcceptedType) {
      dispatch(
        showPopUp('notification', {
          notificationType: 'error',
          title: i18n.t('common.error'),
          text: i18n.t('errors.fileFormatError'),
        })
      );
    }

    const isSmaller10M = size / 1024 / 1024 < 10;
    if (!isSmaller10M) {
      dispatch(
        showPopUp('notification', {
          notificationType: 'error',
          title: i18n.t('common.error'),
          text: i18n.t('errors.fileSizeError', {
            fileSize: '10MB',
          }),
        })
      );
    }

    return (isAcceptedType && isSmaller10M) || Upload.LIST_IGNORE;
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList.length > 0 ? [newFileList.at(-1)] : []);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  return (
    <SCPhishingUploadLogo previewImg={Boolean(fileList.length > 0)}>
      <SectionCard
        headerIcon={faCircle2}
        headerTitle={i18n.t('phishingSimulator.campaigns.create.uploadLogo')}>
        <>
          <Upload
            accept={ACCEPTED_TYPES.toString()}
            beforeUpload={beforeUpload}
            listType="picture-card"
            showUploadList={{ showPreviewIcon: false }}
            customRequest={async (options) => {
              try {
                const base64File = await toBase64(options.file);
                const { data } = await clientsService.addCompanyLogo(
                  base64File.split(',')[1]
                );
                options.onSuccess(data, options.file);
              } catch (error) {
                options.onError(error);
                dispatch(
                  showPopUp('notification', {
                    notificationType: 'error',
                    title: i18n.t('common.error'),
                    text: i18n.t('errors.not_found'),
                  })
                );
              }
            }}
            onRemove={clientsService.deleteCompanyLogo}
            onChange={handleChange}
            fileList={fileList}>
            <button className="upload-button" type="button">
              <FontAwesomeIcon
                icon={faFileArrowUp}
                color="var(--bluish-grey)"
                size="2x"
              />
              <p>
                {i18n.t('phishingSimulator.campaigns.create.uploadLogoText')}
              </p>
            </button>
          </Upload>
        </>
      </SectionCard>
    </SCPhishingUploadLogo>
  );
};

export default PhishingUploadLogo;
