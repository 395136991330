import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { faKeySkeleton } from '@fortawesome/pro-regular-svg-icons';
import { TrackButton } from '../../track';
import { auth } from '../../firebase';
import SCForgotPassword from './ForgotPassword.style';
import Button from '../../components/atoms/Button/Button';
import Input from '../../components/atoms/Input/Input';
import Icon from '../../components/atoms/Icon/Icon';
import { useTranslation } from '../../i18n';

const ForgotPassword = () => {
  const [hasBeenSent, setHasBeenSent] = useState(false);
  const [email, setEmail] = useState(undefined);
  const licensee = useSelector((redux) => redux.licensee);
  const i18n = useTranslation();

  // Submit email to begin Passwordless transaction
  const sendMagicLink = () => {
    if (licensee && licensee.market === 'de') auth.languageCode = 'de';
    if (licensee && licensee.market === 'es') auth.languageCode = 'es';
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setHasBeenSent(true);
      })
      .catch((e) => {
        setHasBeenSent(true);
      });
  };

  return (
    <SCForgotPassword>
      <div className="icon-container">
        <Icon iconName={faKeySkeleton} size="extraLarge" color="var(--red)" />
      </div>

      <h1>{i18n.t('forgotPassword.passwordReset')}</h1>

      <div className="ForgotPassword_Actions">
        {!hasBeenSent && (
          <>
            <p className="ForgotPassword_Desc">
              {i18n.t('forgotPassword.enterEmail')}
            </p>
            <Input
              className="fs-exclude"
              size="large"
              center
              inputType="text"
              inputPlaceholder={i18n.t('forgotPassword.businessEmail')}
              defaultValue={email || ''}
              onChangeValue={(val) => setEmail(val.trim())}
            />
            <Button
              text={i18n.t('forgotPassword.resetPassword')}
              onClick={() => {
                sendMagicLink();
                TrackButton('forgotPassword.resetPassword');
              }}
              size="full"
              color="red"
              disabled={!email || email === ''}
            />
          </>
        )}

        {hasBeenSent && (
          <p className="email-sent">{i18n.t('forgotPassword.text')}</p>
        )}
      </div>

      <Link to="/sign-in">{i18n.t('forgotPassword.backToSignIn')}</Link>
    </SCForgotPassword>
  );
};

export default ForgotPassword;
