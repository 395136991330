import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { faEnvelope, faKey, faUser } from '@fortawesome/pro-regular-svg-icons';
import { usePopper } from 'react-popper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import SCSetUpAccount, { PopperContainer } from './SetUpAccount.style';
import { useTranslation } from '../../i18n';
import RegionBanner from '../../components/molecules/RegionBanner/RegionBanner';
import { isSantanderEnvironment } from '../../utils/locale';
import Button from '../../components/atoms/Button/Button';
import { googleIcon } from '../../components/Misc/Icons';
import Input from '../../components/atoms/Input/Input';
import getLegalDocumentsUrls from '../../utils/internationalizationModules/legalDocuments';
import authService from '../../api/services/auth.service';
import useRecaptcha from '../../hooks/recaptcha/useRecaptcha';
import { ErrorKey } from '../../track';
import { auth } from '../../firebase';
import SpinnerText from '../../components/atoms/SpinnerText/SpinnerText';
import { REGULAR_EXPRESSIONS } from '../../utils/constants/regularExpressions';
import useSignUpWithGoogle from '../../hooks/useSignUpWithGoogle/useSignUpWithGoogle';
import { ERRORS, handleErrorPopUp } from '../../utils/constants/errors';

const SetUpAccount = () => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const { code } = useParams();
  const { getRecaptchaToken } = useRecaptcha();
  const { handleSignUpWithGoogle } = useSignUpWithGoogle();

  const [password, setPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);

  const [referenceElement, setReferenceElement] = useState(null);
  const [showPasswordValidator, setShowPasswordValidator] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const { styles } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      { name: 'arrow', options: { element: null } },
      { name: 'offset', options: { offset: [0, 10] } },
    ],
    hide: {
      enabled: false,
    },
    preventOverflow: {
      enabled: false,
    },
  });

  const regLength = new RegExp('^(?=.{8,})');
  const regNumber = new RegExp('^(?=.*[0-9])');
  const regCapital = new RegExp('^(?=.*[A-Z])');

  const hideRegionBanner = code === 'wealthmanagement';

  const { privacyPolicy, generalConditions } = getLegalDocumentsUrls(
    i18n.getLanguage()
  );

  useEffect(() => {
    if (auth?.currentUser) {
      window.location.href = '/';
    }

    if (code) {
      authService
        .checkPromoCode(code)
        .then((resp) => {
          const isValidPromoCode = resp.data.data;

          if (!isValidPromoCode) {
            handleErrorPopUp(ERRORS.INVALID_PROMO_CODE);
            navigate('/setup-account');
          }
        })
        .catch(() => {
          handleErrorPopUp();
        });
    }
  }, []);

  const handleSignUpWithEmailAndPassword = async () => {
    if (!REGULAR_EXPRESSIONS.NAME.test(firstName)) {
      ErrorKey('errors.invalidFirstName');
      return;
    }

    if (!REGULAR_EXPRESSIONS.NAME.test(lastName)) {
      ErrorKey('errors.invalidLastName');
      return;
    }

    if (!REGULAR_EXPRESSIONS.EMAIL.test(email)) {
      ErrorKey('errors.wrongEmailFormatShort');
      return;
    }

    if ([regLength, regNumber, regCapital].some((reg) => !reg.test(password))) {
      ErrorKey('errors.wrongPasswordFormat');
      return;
    }

    setLoading(true);

    try {
      const captcha = await getRecaptchaToken();
      const user = {
        email,
        firstName,
        lastName,
        password,
        language: i18n.lang,
        promoCode: code,
      };

      await authService.signUpWithEmailAndPassword(user, captcha);
      await auth.signInWithEmailAndPassword(email, password);
      navigate('/account-created');
    } catch (error) {
      const errorCode = error.response?.data?.error;
      handleErrorPopUp(errorCode);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SCSetUpAccount>
      <h1>{i18n.t('setup.welcome')}</h1>

      {!hideRegionBanner && <RegionBanner />}

      {!isSantanderEnvironment && (
        <Button
          className="google-button"
          text={
            <>
              {googleIcon}
              {i18n.t('setup.account.signUpWithGoogle')}
            </>
          }
          onClick={handleSignUpWithGoogle}
          size="full"
          color="white"
        />
      )}

      <div className="inputs-container">
        <div className="name-input fs-exclude">
          <div
            style={{
              opacity: firstName ? 1 : 0.4,
            }}>
            <Input
              name="firstName"
              id="firstName"
              inputType="text"
              size="large"
              icon={faUser}
              inputPlaceholder={i18n.t('setup.account.firstNameEg')}
              defaultValue={firstName || ''}
              onChangeValue={setFirstName}
            />
          </div>

          <div
            style={{
              opacity: lastName ? 1 : 0.4,
            }}>
            <Input
              name="lastName"
              id="lastName"
              inputType="text"
              size="large"
              icon={faUser}
              inputPlaceholder={i18n.t('setup.account.lastNameEg')}
              defaultValue={lastName || ''}
              onChangeValue={setLastName}
            />
          </div>
        </div>

        <div
          className="fs-exclude"
          style={{
            opacity: email ? 1 : 0.4,
          }}>
          <Input
            name="email"
            id="email"
            inputType="text"
            size="large"
            icon={faEnvelope}
            inputPlaceholder={i18n.t('setup.account.emailEg')}
            defaultValue={email || ''}
            onChangeValue={setEmail}
          />
        </div>

        <div className="fs-exclude">
          <div
            style={{
              opacity: password ? 1 : 0.4,
            }}>
            <Input
              name="password"
              id="password"
              inputType="password"
              size="large"
              icon={faKey}
              inputPlaceholder={i18n.t('setup.account.passwordEg')}
              defaultValue={password || ''}
              onChangeValue={(val) => {
                setPassword(val.trim());
              }}
              Internalref={setReferenceElement}
              onFocus={() => setShowPasswordValidator(true)}
              onBlur={() => setShowPasswordValidator(false)}
            />
          </div>

          {showPasswordValidator && (
            <PopperContainer ref={setPopperElement} style={styles.popper}>
              <h2>{i18n.t('setup.getAccess.passwordPolicy')}</h2>

              {regLength.test(password) && password && password.length > 0 ? (
                <div className="success-regex">
                  <FontAwesomeIcon icon={faCheck} />{' '}
                  {i18n.t('setup.getAccess.passwordLength')}
                </div>
              ) : (
                <div>{i18n.t('setup.getAccess.passwordLength')}</div>
              )}

              {regNumber.test(password) ? (
                <div className="success-regex">
                  <FontAwesomeIcon icon={faCheck} />{' '}
                  {i18n.t('setup.getAccess.includeNumber')}
                </div>
              ) : (
                <div>{i18n.t('setup.getAccess.includeNumber')}</div>
              )}

              {regCapital.test(password) ? (
                <div className="success-regex">
                  <FontAwesomeIcon icon={faCheck} />{' '}
                  {i18n.t('setup.getAccess.includeCapital')}
                </div>
              ) : (
                <div>{i18n.t('setup.getAccess.includeCapital')}</div>
              )}
            </PopperContainer>
          )}
        </div>
      </div>

      <div className="terms-and-conditions-container">
        <p
          dangerouslySetInnerHTML={{
            __html: i18n.t('setup.account.acceptTerms1', {
              generalTermsAndConditions: `<a href=${generalConditions} target="_blank" rel="noopener noreferrer">${i18n.t(
                'setup.account.generalTermsAndConditions'
              )}</a>`,
              privacyPolicy: `<a href=${privacyPolicy} target="_blank" rel="noopener noreferrer">${i18n.t(
                'setup.account.privacyPolicy'
              )}</a>`,
            }),
          }}
        />
        <p>{i18n.t('setup.account.acceptTerms2')}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n.t('setup.account.acceptTerms3', {
              privacyPolicy: `<a href=${privacyPolicy} target="_blank" rel="noopener noreferrer">${i18n.t(
                'setup.account.here'
              )}</a>`,
            }),
          }}
        />
      </div>

      <div className="NewSignUp_RegisterButton">
        <Button
          id="registerButton"
          text={
            loading ? (
              <SpinnerText text={i18n.t('setup.account.createAccount')} />
            ) : (
              i18n.t('setup.account.createAccount')
            )
          }
          onClick={handleSignUpWithEmailAndPassword}
          size="full"
          color="red"
          disabled={!firstName || !lastName || !email || !password || loading}
        />

        <Link to="/sign-in">{i18n.t('setup.account.iHaveAccount')}</Link>
      </div>
    </SCSetUpAccount>
  );
};

export default SetUpAccount;
