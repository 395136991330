import { faUser } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCBillingInformationCard from './BillingInformationCard.style';
import BillingInformationForm from '../../molecules/BillingInformationForm/BillingInformationForm';

const BillingInformationCard = () => {
  const i18n = useTranslation();

  return (
    <SCBillingInformationCard>
      <SectionCard
        headerIcon={faUser}
        headerTitle={i18n.t('profile.yourBillingDetails')}>
        <BillingInformationForm />
      </SectionCard>
    </SCBillingInformationCard>
  );
};
export default BillingInformationCard;
