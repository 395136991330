import api from '../config';
import { AUTH_API } from '../paths';

const updateUser = (data) => {
  return api.put(`${AUTH_API}/user`, data);
};

const signUpWithEmailAndPassword = (data, captcha) => {
  return api.post(`${AUTH_API}/signup`, data, {
    headers: { captcha },
  });
};

const signUpWithGoogle = (data, captcha) => {
  return api.post(`${AUTH_API}/signup/google`, data, {
    headers: { captcha },
  });
};

const checkPromoCode = (promoCode) => {
  return api.get(`${AUTH_API}/check-promocode`, {
    params: {
      promoCode,
    },
  });
};

const signOut = () => {
  return api.post(`${AUTH_API}/sign-out`);
};

export default {
  updateUser,
  signUpWithEmailAndPassword,
  signUpWithGoogle,
  checkPromoCode,
  signOut,
};
