import React, { useState } from 'react';
import { faCircle1 } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import SCNewPhisingCampaignType from './NewPhisingCampaignTypeCard.style';
import MiniCard from '../../atoms/MiniCard/MiniCard';
import { ErrorKey, TrackButton } from '../../../track';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { getPhishingCampaigns } from '../../../utils/constants/phishingCampaigns';
import campaignsService from '../../../api/services/campaigns.service';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';

const NewPhisingCampaignTypeCard = ({ selectedCampaignType, onChange }) => {
  const user = useSelector((redux) => redux.user);

  const i18n = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const PHISHING_CAMPAINGS = getPhishingCampaigns(i18n.lang);

  const handleCheckReception = async () => {
    setLoading(true);

    try {
      const campaignData = {
        campaign_type: selectedCampaignType,
        campaign_name: i18n.t(
          `phishingSimulator.campaigns.campaingTypes.${PHISHING_CAMPAINGS[selectedCampaignType].name}`
        ),
        destinations: [user.hashedEmail],
      };

      await campaignsService.createTestCampaign(campaignData);

      dispatch(showPopUp('checkPhisingReceptionEmail'));
    } catch (error) {
      ErrorKey('errors.not_found');
    } finally {
      setLoading(false);
    }
  };

  return (
    <SCNewPhisingCampaignType>
      <SectionCard
        headerIcon={faCircle1}
        headerTitle={i18n.t(
          'phishingSimulator.campaigns.create.selectTypeOfPhising'
        )}>
        <div className="NewPhisingCampaignType_TypesContainer">
          {Object.values(PHISHING_CAMPAINGS).map((campaign) => (
            <MiniCard
              key={campaign.type}
              className={`${
                selectedCampaignType === campaign.type
                  ? 'NewPhisingCampaignType_TypeSelected'
                  : ''
              }`}
              onClick={() => {
                TrackButton(
                  `phishingSimulator.campaigns.create.${1}.campaign.type`,
                  campaign.type
                );
                onChange(campaign.type);
              }}>
              {campaign.img}
              <div>
                {i18n.t(
                  `phishingSimulator.campaigns.campaingTypes.${campaign.name}`
                )}
              </div>
            </MiniCard>
          ))}
        </div>
        {selectedCampaignType && (
          <div className="NewPhisingCampaignType_ActionsContainer">
            <Button
              text={
                loading ? (
                  <SpinnerText
                    text={i18n.t(
                      'phishingSimulator.campaigns.create.checkEmailReception'
                    )}
                  />
                ) : (
                  i18n.t(
                    'phishingSimulator.campaigns.create.checkEmailReception'
                  )
                )
              }
              onClick={handleCheckReception}
              color="white"
              size="mid"
              disabled={loading}
            />
          </div>
        )}
      </SectionCard>
    </SCNewPhisingCampaignType>
  );
};

export default NewPhisingCampaignTypeCard;
