import { useTranslation } from '../../i18n';

function useApp() {
  const i18n = useTranslation();

  const possibleLanguages = {
    es: i18n.t('profile.interface.languagesName.es'),
    en: i18n.t('profile.interface.languagesName.en'),
    'pt-BR': i18n.t('profile.interface.languagesName.pt-BR'),
  };

  const possibleRegions = {
    eu: i18n.t('regions.europe'),
    am: i18n.t('regions.america'),
  };

  const getLanguageKey = (requestedLang) => {
    return Object.keys(possibleLanguages).find(
      (key) => possibleLanguages[key] === requestedLang
    );
  };

  const getRegionKey = (requestedRegion) => {
    return Object.keys(possibleRegions).find(
      (key) => possibleRegions[key] === requestedRegion
    );
  };

  const getLanguagename = (requestedLang) => {
    return possibleLanguages[requestedLang];
  };

  const getRegionName = (requestRegion) => {
    return possibleRegions[requestRegion];
  };

  return {
    getLanguageKey,
    getRegionKey,
    possibleLanguageNames: Object.values(possibleLanguages),
    possibleRegionNames: Object.values(possibleRegions),
    getLanguagename,
    getRegionName,
  };
}

export default useApp;
