import moment from 'moment';
import { i18n } from '../../i18n';
import getCurrencyByLocale from '../internationalizationModules/currencies/currencies';
import { CURRENT_LOCALE } from '../locale';

type firestoreTimestamp = { toDate(): Date };

export function getCurrencyFormat(
  data: number,
  customOptions: Intl.NumberFormatOptions,
  locale: string
) {
  const currentLocale = locale ?? CURRENT_LOCALE;

  const CURRENCY_LOCALES = {
    eu: 'de-DE',
  };

  const options = {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: getCurrencyByLocale(currentLocale),
    ...customOptions,
  };

  return new Intl.NumberFormat(
    CURRENCY_LOCALES[currentLocale] ?? CURRENCY_LOCALES.eu,
    options
  ).format(data);
}

/**
 * Descripción de los locales soportados
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locales_argument
 */
export const getNumberFormat = (number: number) => {
  const currentLanguage = i18n.getLanguage();

  const NUMBER_FORMAT = {
    es: 'de-DE',
  };

  return number.toLocaleString(
    NUMBER_FORMAT[currentLanguage] ?? currentLanguage
  );
};

export const getReduceNumber = (number: number) => {
  const currentLanguage = i18n.getLanguage();

  const DECIMALS = 1;

  const THOUSAND = 1000;
  const MILLION = 1000000;

  let suffix: string = '';
  let formattedNumber: number = number;

  if (number >= MILLION) {
    suffix = 'M';
    formattedNumber = parseFloat((number / MILLION).toFixed(DECIMALS));
  } else if (number >= THOUSAND) {
    suffix = 'K';
    formattedNumber = parseFloat((number / THOUSAND).toFixed(DECIMALS));
  }

  const NUMBER_FORMAT = {
    es: 'de-DE', // Español
    en: 'en-US', // Inglés
  };

  const formattedString = formattedNumber.toLocaleString(
    NUMBER_FORMAT[currentLanguage] ?? currentLanguage,
    {
      maximumFractionDigits: 1,
    }
  );

  return `${formattedString}${suffix}`;
};

export const getDateFormat = (
  date: Date,
  customOptions: any = undefined
): string | undefined => {
  const currentLanguage = i18n.getLanguage();
  const defaultOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  if (!date || isNaN(Number(date))) return undefined;
  if (date < new Date('1970-01-01')) return undefined;
  return date.toLocaleDateString(currentLanguage, {
    ...defaultOptions,
    ...customOptions,
  });
};

export const getDateInMs = (date: Date): number | undefined => {
  if (!date || date < new Date('1970-01-01')) return 0;
  return date.getTime();
};

export const getFormattedDateStringInMs = (
  formattedDtringifyDate: string
): number => {
  const momentDate = moment(
    formattedDtringifyDate,
    i18n.getLanguage() === 'es' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
  );
  // momentDate.locale(i18n.getLanguage());
  return momentDate.valueOf();
};

export const parseDate = (date: firestoreTimestamp | string): Date =>
  typeof date === 'string' ? new Date(date) : date?.toDate();
