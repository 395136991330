import React from 'react';
import {
  faArrowUpRightFromSquare,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { faGear } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'antd';
import SectionHeader from '../SectionHeader/SectionHeader';
import { useTranslation } from '../../../i18n';
import SCPartnerClientControlPanelHeader from './PartnerClientControlPanelHeader.style';
import Button from '../../atoms/Button/Button';
import { PARTNER_TYPES } from '../../../utils/constants/partners';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import usePartnerConfig from '../../../hooks/usePartnerConfig/usePartnerConfig';

const PartnerClientControlPanelHeader = ({
  partnerClient,
  fetchPartnerClientsData,
  loading,
  hasPadding,
}) => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const client = useSelector((redux) => redux.client);

  const { isPartnerType, isParentPartner } = usePartnerConfig();

  const getHref = () => {
    const partnerClientId = partnerClient?.id;
    const { id: clientId } = client;
    const baseHref = '/client';

    return partnerClientId === clientId
      ? baseHref
      : `${baseHref}?id=${partnerClientId}`;
  };

  const canAccessConsole = isPartnerType(PARTNER_TYPES.MSSP);
  const canManageSubscription =
    !isParentPartner &&
    isPartnerType([PARTNER_TYPES.MSSP, PARTNER_TYPES.RESELLER]);

  const { type: partnerType, name: partnerName } =
    partnerClient?.partnerInfo ?? {};

  return (
    <SCPartnerClientControlPanelHeader hasPadding={hasPadding}>
      <SectionHeader
        icon={faUser}
        subtitle={partnerClient?.company?.name}
        tag={
          partnerType && partnerName ? `${partnerType}: ${partnerName}` : null
        }
        loading={loading}
      />
      <div className="actions-buttons-container">
        {canManageSubscription && (
          <Tooltip
            title={i18n.t(
              'partner.manageSubscriptionPopUp.manageSubscription'
            )}>
            <Button
              size="fit"
              color="white"
              icon={faGear}
              onClick={() =>
                dispatch(
                  showPopUp('manageSubscription', {
                    client: partnerClient,
                    fetchPartnerClientsData,
                  })
                )
              }
              disabled={loading}
            />
          </Tooltip>
        )}

        {canAccessConsole && (
          <Tooltip title={i18n.t('partner.consoleAccess')}>
            <a
              href={getHref()}
              target="_blank"
              aria-label="Link to client console"
              rel="noreferrer">
              <Button
                size="fit"
                color="white"
                icon={faArrowUpRightFromSquare}
                disabled={loading}
              />
            </a>
          </Tooltip>
        )}
      </div>
    </SCPartnerClientControlPanelHeader>
  );
};

export default PartnerClientControlPanelHeader;
