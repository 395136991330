import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import { ErrorKey } from '../../../track';
import campaignsService from '../../../api/services/campaigns.service';

const SCRemoveCampaign = styled.div`
  h1 {
    text-align: center;
    font-family: var(--font1);
    font-size: 25px;
    font-weight: 600;
  }
`;

const RemoveCampaign = ({ campaignId }) => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDeleting, setIsDeleting] = useState(false);

  const closeModal = () => {
    dispatch(showPopUp(null));
  };

  const removeCampaign = async () => {
    setIsDeleting(true);

    try {
      await campaignsService.deleteCampaign(campaignId);
      closeModal();
      navigate('/client/phishingsimulator');
    } catch (e) {
      ErrorKey('errors.not_found');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <ModalLayout>
      <SCRemoveCampaign>
        <h1>{i18n.t('phishingSimulator.campaigns.delete.question')}</h1>

        <div className="modal-card-buttons">
          <Button
            text={
              isDeleting ? (
                <SpinnerText text={i18n.t('common.loading')} />
              ) : (
                i18n.t('phishingSimulator.campaigns.delete.yes')
              )
            }
            onClick={removeCampaign}
            size="full"
            color="bluishGrey"
            disabled={isDeleting}
          />
          <Button
            text={i18n.t('phishingSimulator.campaigns.delete.no')}
            onClick={closeModal}
            size="full"
            color="white"
          />
        </div>
      </SCRemoveCampaign>
    </ModalLayout>
  );
};

export default RemoveCampaign;
