import React from 'react';
import { Navigate } from 'react-router-dom';
import usePartnerConfig from '../../hooks/usePartnerConfig/usePartnerConfig';
import { PARTNER_TYPES } from '../../utils/constants/partners';

const PartnerCollaboratorsGuard = ({ children }) => {
  const { isPartnerType, isParentPartner } = usePartnerConfig();

  const hasAccessToCollaborators =
    isParentPartner &&
    isPartnerType([PARTNER_TYPES.MSSP, PARTNER_TYPES.RESELLER]);

  return hasAccessToCollaborators ? children : <Navigate to="/partner" />;
};

export default PartnerCollaboratorsGuard;
