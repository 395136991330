import {
  faEnvelopesBulk,
  faMailboxFlagUp,
} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { useTranslation } from '../../../i18n';

import SCMailboxProtectionCard from './MailboxProtectionCard.style';
import Icon from '../../atoms/Icon/Icon';
import LastUpdateText from '../../atoms/LastUpdateText/LastUpdateText';
import MailboxExtendLicensesWarning from '../../molecules/MailboxExtendLicensesWarning/MailboxExtendLicensesWarning';
import EmptyCardContent from '../../molecules/EmptyCardContent/EmptyCardContent';

const MailboxProtectionCard = ({ data = {}, isHeaderClickable, loading }) => {
  const navigate = useNavigate();
  const i18n = useTranslation();

  const {
    isProtected,
    protectedMailboxes = 0,
    totalMailboxes = 0,
    lastUpdateAt,
    atEmail,
  } = data;

  const handleHeaderButtonOnClick = isHeaderClickable
    ? () => {
        navigate('/client/mailbox-security');
      }
    : undefined;

  return (
    <SCMailboxProtectionCard>
      <SectionCard
        headerIcon={faEnvelopesBulk}
        headerTitle={`${i18n.t(
          'controlPanel.mailboxProtection.title'
        )} @${atEmail}`}
        headerButtonOnClick={handleHeaderButtonOnClick}>
        {!loading ? (
          <div className="mailbox-protection-container">
            {totalMailboxes > 0 ? (
              <>
                <div className="mailbox-data-container">
                  <div className="mailbox-status-container">
                    <Icon
                      iconName={faEnvelopesBulk}
                      color="var(--bluish-grey)"
                      size="navIcon"
                    />
                    <h3
                      style={{
                        color: isProtected ? 'var(--green)' : 'var(--red)',
                      }}>
                      {isProtected
                        ? i18n.t('common.active').toUpperCase()
                        : i18n.t('common.inactive').toUpperCase()}
                    </h3>
                  </div>

                  <div className="mailbox-protected-container">
                    <Icon
                      iconName={faMailboxFlagUp}
                      color="var(--bluish-grey)"
                      size="navIcon"
                    />
                    <div className="mailbox-protected-kpi">
                      <h3>
                        {protectedMailboxes}
                        <span> / {totalMailboxes}</span>
                      </h3>
                      <p>
                        {i18n.t(
                          'controlPanel.mailboxProtection.protectedMailboxes'
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                {protectedMailboxes > totalMailboxes && (
                  <MailboxExtendLicensesWarning />
                )}

                {lastUpdateAt && <LastUpdateText date={lastUpdateAt} />}
              </>
            ) : (
              <EmptyCardContent
                text={i18n.t(
                  'controlPanel.protectionLicenses.notPurchasedProtection'
                )}
              />
            )}
          </div>
        ) : (
          <Skeleton active paragraph={{ rows: 4 }} />
        )}
      </SectionCard>
    </SCMailboxProtectionCard>
  );
};

export default MailboxProtectionCard;
