import React from 'react';
import { Checkbox, Form, Input } from 'antd';
import SCCustomCard from './CustomCard.style';

const CompanyCard = ({ isSantanderEvent }) => {
  const formItems = [
    {
      label: 'Nombre',
      name: ['company', 'name'],
      type: 'input',
      disabled: true,
    },
    {
      label: 'Razón social',
      name: ['company', 'legalName'],
      type: 'input',
      disabled: true,
    },
    {
      label: 'CIF',
      name: ['company', 'cif'],
      type: 'input',
      disabled: true,
    },
    {
      label: 'Nº empleados',
      name: ['company', 'numberEmployees'],
      type: 'input',
    },
    {
      label: 'Dominio web',
      name: ['company', 'website'],
      type: 'input',
      disabled: true,
    },
    {
      label: 'Dominio correo',
      name: ['atEmail'],
      type: 'input',
      disabled: true,
    },
    ...(isSantanderEvent
      ? [
          {
            label: 'Email gestor',
            name: ['emailManager'],
            type: 'input',
          },
        ]
      : []),
    {
      label: 'Posible partner',
      name: ['possiblePartner'],
      type: 'checkbox',
      disabled: true,
      valuePropName: 'checked',
    },
  ];

  return (
    <SCCustomCard title="Empresa" size="small" bordered>
      <div className="content-grid">
        {formItems.map(({ label, name, type, disabled, valuePropName }) => (
          <Form.Item
            label={
              <span>
                <strong>{label}</strong>
              </span>
            }
            name={name}
            valuePropName={valuePropName}>
            {type === 'input' && <Input disabled={disabled} size="small" />}
            {type === 'checkbox' && <Checkbox />}
          </Form.Item>
        ))}
      </div>
    </SCCustomCard>
  );
};

export default CompanyCard;
