import { Select, Space } from 'antd';
import React from 'react';
import { getPhonePrefixOptions } from '../../../utils/internationalizationModules/telephones/telephonePrefix';

const PhonePrefixSelect = ({ ...props }) => {
  return (
    <Select
      options={getPhonePrefixOptions()}
      popupMatchSelectWidth={false}
      optionFilterProp="country"
      filterSort={(optionA, optionB) =>
        (optionA?.country ?? '')
          .toLowerCase()
          .localeCompare((optionB?.country ?? '').toLowerCase())
      }
      optionRender={({ data }) => (
        <Space>
          <span role="img" aria-label={data.label}>
            {data.flag}
          </span>
          {`${data.country} (${data.label})`}
        </Space>
      )}
      {...props}
    />
  );
};

export default PhonePrefixSelect;
