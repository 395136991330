import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import * as teamMembersService from '../../services/teamMembers/teamMembers.service';

const useTeamMembers = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();

  const [loading, setLoading] = useState(false);

  const addMultipleTeamMembers = (teamMembersArray) => {
    const MAX_LIMIT = 100;

    if (!teamMembersArray) return;
    setLoading(true);
    const deferred = teamMembersArray.length > MAX_LIMIT;
    teamMembersService
      .addMultipleTeamMembers(teamMembersArray, deferred)
      .then((response) => {
        if (response.status === 200) {
          if (deferred) {
            dispatch(
              showPopUp('notification', {
                notificationType: 'success',
                title: i18n.t('common.processingRequest'),
                text: i18n.t('manageEmailList.processingRequestText'),
              })
            );
          } else {
            dispatch(
              showPopUp('notification', {
                notificationType: 'success',
                title: i18n.t('common.processedRequest'),
                text: i18n.t('manageEmailList.processedRequestText'),
              })
            );
          }
        }
      })
      .catch((e) => {
        const hasInvalidEmail = (errorObject) => {
          return Object.values(errorObject).some(
            (value) => value === 'invalid_email'
          );
        };

        const hasMaxTeamMembers = (errorObject) => {
          return Object.values(errorObject).some(
            (value) => value === 'max_team_members'
          );
        };

        if (hasInvalidEmail(e.response.data)) {
          dispatch(
            showPopUp('notification', {
              notificationType: 'error',
              title: i18n.t('common.error'),
              text: i18n.t('teamMembers.invalidEmail'),
            })
          );
        }

        if (hasMaxTeamMembers(e.response.data)) {
          dispatch(showPopUp('maxUsersInEmailList'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { loading, addMultipleTeamMembers };
};

export default useTeamMembers;
