import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import SCStripeSaveCardForm from './StripeSaveCardForm.style';
import Button from '../../atoms/Button/Button';
import { ErrorKeyCustomized } from '../../../track';
import {
  isValidCompanyID,
  isValidPersonID,
} from '../../../utils/internationalizationModules/internationalizationHelper';
import { startTrialSubscription } from '../../../services/subscriptions/subscription.service';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import getCompanyIDExample from '../../../utils/internationalizationModules/companyIdLabels/companyIdLabels';
import { CURRENT_LOCALE } from '../../../utils/locale';

const StripeSaveCardForm = ({ onConfirm }) => {
  const client = useSelector((redux) => redux.client);
  const user = useSelector((redux) => redux.user);
  const i18n = useTranslation();
  const MAX_COMPANY_ID_TRIES = 3;

  const [companyID, setCompanyID] = useState(client.company.cif ?? '');
  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);

  const PAYMENT_ELEMENT_OPTIONS = {
    terms: {
      card: 'never',
    },
    fields: {
      billingDetails: {
        address: {
          country: 'never',
        },
      },
    },
  };

  const cifAlreadyExists = async (cif) => {
    let companyIDExists = false;

    await axios
      .get(`/company-data/${cif}`)
      .then((res) => {
        if (res.data.code === 'error') {
          companyIDExists = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return companyIDExists;
  };

  const updateCIFTries = async () => {
    const updatedTries = (client?.cifPopupTries || 0) + 1;
    let result = updatedTries;
    try {
      await axios.put(`/company-data/${client.id}/${updatedTries}`);
    } catch (error) {
      result = MAX_COMPANY_ID_TRIES;
    }
    return result;
  };

  const isValid = async () => {
    // Comprobar formato del companyID.
    if (
      !isValidCompanyID(companyID, CURRENT_LOCALE) &&
      !isValidPersonID(companyID, CURRENT_LOCALE)
    ) {
      ErrorKeyCustomized({
        i18nKey: `setup.company.errorCompanyID.${CURRENT_LOCALE}`,
      });
      return false;
    }
    // Comprobar si ya existe ese companyID en la BD y que no existe en el propio cliente.
    if (!client.company.cif && (await cifAlreadyExists(companyID))) {
      const newTries = await updateCIFTries();
      const allowExitModal = newTries < MAX_COMPANY_ID_TRIES;
      ErrorKeyCustomized({
        i18nKey: 'setup.company.genericError',
        swalOptions: {
          allowOutsideClick: allowExitModal,
          allowEscapeKey: allowExitModal,
          confirmButtonText: allowExitModal
            ? i18n.t('common.close')
            : i18n.t('common.signOut'),
        },
        onClickConfirm: allowExitModal ? '' : () => navigate('/sign-out'),
      });
      return false;
    }
    return true;
  };

  const stripeConfirmSetup = async () => {
    const { error } = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000/client/poc-stripe-save-card',
        payment_method_data: {
          billing_details: {
            name: `${name.trim()} ${surname.trim()}`,
            email: user.email,
            address: {
              country: 'ES',
            },
          },
        },
      },
      redirect: 'if_required',
    });

    if (error) {
      setErrorMessage(error.message);
      // setLoading(false);
    } else {
      await saveCifCompanyData();
      await startTrialSubscription();
      onConfirm();
    }
  };

  const saveCifCompanyData = async () => {
    await axios.put(`/company-data/saveCif/${client.id}`, {
      companyCif: companyID,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    // 1. Validar state companyID
    if (name && surname && (await isValid())) {
      await stripeConfirmSetup();
    }

    setLoading(false);
  };

  return (
    <SCStripeSaveCardForm onSubmit={handleSubmit}>
      {!client.company.cif && (
        <div className="label-input-container">
          <label htmlFor="companyCIF">
            {i18n.t('addCreditCard.form.companyID')}
          </label>
          <input
            type="text"
            placeholder={getCompanyIDExample().example}
            id="companyCIF"
            name="companyCIF"
            value={companyID}
            onChange={(e) => setCompanyID(e.target.value.trim())}
          />
        </div>
      )}

      <div className="name-surname-container">
        <div className="label-input-container">
          <label>{i18n.t('addCreditCard.form.cardholder')}</label>
          <div className="label-inputs">
            <input
              type="text"
              placeholder={i18n.t('addCreditCard.form.name')}
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              placeholder={i18n.t('addCreditCard.form.surname')}
              id="surname"
              name="surname"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
        </div>
      </div>
      <PaymentElement options={PAYMENT_ELEMENT_OPTIONS} />
      <p>
        Al enviar tus datos, aceptas las{' '}
        <a
          href="https://static.cyberguardian.tech/es/condiciones-generales-de-contratacion.pdf"
          target="_blank"
          rel="noreferrer">
          condiciones generales de contratación
        </a>{' '}
        y la{' '}
        <a
          href="https://static.cyberguardian.tech/es/politica-de-privacidad.pdf"
          target="_blank"
          rel="noopener noreferrer">
          política de privacidad
        </a>
        .
      </p>
      <div className="submit-button">
        <Button
          type="submit"
          size="landing"
          color="red"
          text={
            loading ? (
              <SpinnerText text={i18n.t('common.loading')} />
            ) : (
              i18n.t('addCreditCard.form.addCompanyCard')
            )
          }
          disabled={!stripe || !companyID || !name || !surname || loading}
        />
      </div>

      {errorMessage && <div>{errorMessage}</div>}
    </SCStripeSaveCardForm>
  );
};

export default StripeSaveCardForm;
